import React, { useState } from 'react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import { MdAppShortcut } from "react-icons/md";

var bnr1 = require('./../../images/aboutus/bnr1.png');
var bnr2 = require('./../../images/aboutus/bnr2.png');
var call = require('./../../images/aboutus/call.png');
var tele = require('./../../images/aboutus/tele.png');
var whatsapp = require('./../../images/aboutus/whatsapp.png');
var icon1 = require('./../../images/aboutus/icon1.png');
var mission = require('./../../images/aboutus/mission.png');
var vission = require('./../../images/aboutus/vission.png');
var callus = require('./../../images/contacts/callus.png');


function AboutUs() {

	return (
		<div className="page-wraper bg-white">
			<Header />
			<div className="page-content1">
				<div className="content-block1">
					<div className="container mt-5">
						<h2>Our Profile</h2>
						<div className="row align-items-center m-b50">
							<div className="col-lg-4 col-md-12 text-center">
								<img src={"https://www.sreeragu.com/survey/File/images/front-page/ragu.jpg"} alt="" className='img-fluid' />
							</div>
							<div className="col-lg-6 col-md-6 col-sm-10 pt-3 profile-details">
								<div className="row"><h4>Ragunath T</h4> </div>
								<div className="row"><p>Managing Director</p> </div>
								<div className="row">
									<div className="col d-flex">
										<img src={call} alt="" className='img-telephone' />
										<p>+91 8925798627 & +91 6382592189</p>
									</div>
								</div>
								<div className="row">
									<div className="col d-flex">
										<img src={tele} alt="" className='img-telephone' />
										<p>mypropertyqr@gmail.com & mypropertyragu@gmail.com</p>
									</div>
								</div>
								<div className="row">
									<div className="col d-flex">
										<img src={whatsapp} alt="" className='img-telephone' />
										<p>+91 8925798627</p>
									</div>
								</div>
							</div>
						</div>
						<div className='text-justify'>
							<h3>About My Property QR</h3>
							<p>We are committed to transforming the real estate market with our innovative platform. We make it easy for property owners to list their plots, houses, apartments, and lands, ensuring maximum visibility and quick connections with potential buyers. Our user-friendly interface, detailed property listings, and high-quality images help buyers find their ideal properties effortlessly.</p>
							<p>
								We prioritize transparency, efficiency, and customer satisfaction in every transaction. Whether you are buying or selling, our dedicated support team is here to guide you through every step of the process. Experience the future of real estate transactions with My Property QR.
							</p>
						</div>
					</div>
					<div className="content-block2 container mt-5">
						<div className="row">
							<div className="col-lg-5 text-center">
								<img src={bnr2} alt="" width={350} className='img-fluid mb-5 ' />
							</div>
							<div className="col-lg-6 text-justify">
								<div className="row">
									<div className="col-1">
										<img src={icon1} alt="" />
									</div>
									<div className="col">
										<p>Know More About Us</p>
									</div>
								</div>
								<h3>Are You Looking Best Property Near You? Contact Us</h3>
								<p>We are dedicated to revolutionizing the real estate market with our comprehensive platform. We empower property owners to effortlessly list their plots, houses, apartments, and lands, ensuring they reach a wide audience of potential buyers. Our intuitive website offers detailed property listings, high-quality images, and advanced search features to help buyers find their perfect property with ease.

								</p>
								<p>Committed to transparency and efficiency, we strive to make every transaction smooth and straightforward. Our expert support team is always on hand to assist you through every step of the buying or selling process. Experience a new level of convenience and reliability in real estate with My Property QR</p>
								<div className="row call-us-bx mb-5 mx-auto">
									<div className="col-3 my-3">
										<img src={callus} alt="" className='img-fluid call-us-image' />
										{/* <MdAppShortcut /> */}
									</div>
									<div className="col-8 text-white my-2">
										<p>Call Us now <br />
											<span style={{ fontSize: '18px', fontWeight: 'bolder' }}>+91 8925798627</span></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container mb-5">
						<div className="row small-box-row">
							<div className="col-lg-5 col-md-5 col-sm-8 small-box mb-5 ">
								<div className="row mb-3">
									<div className="col-md-4 col-lg-2">
										<img src={mission} alt="" className='img-fluid mission-vission-icon' width={50} />
									</div>
									<div className="col-md-8 col-lg-10 pt-2">
										Our Mission
									</div>
								</div>
								<div className="row">
									<div className="col">
										<p>It is a long established fact that a reader will be too distracted by the readable content of a page when one looking at its layout.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-5 col-md-5 col-sm-8 small-box mb-5">
								<div className="row mb-3">
									<div className="col-md-4 col-lg-2">
										<img src={vission} alt="" className='img-fluid mission-vission-icon' width={50} />
									</div>
									<div className="col-md-8 col-lg-10 pt-2">
										Our Vission
									</div>
								</div>
								<div className="row">
									<div className="col">
										<p>It is a long established fact that a reader will be too distracted by the readable content of a page when one looking at its layout.</p>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<Footer />
		</div >
	)

}
export default AboutUs;