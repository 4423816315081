import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdUpload } from "react-icons/md";
import axios from 'axios';
import { Url } from "../../../../utils/Common";
import Loader1 from '../../../../utils/Loader1'

export default function UploadFile({ fileOnSet, children, name, error, editData }) {

    //console.log(`editData Upload File = ${editData}`)

    const [selectedFile, setSelectedFile] = useState(editData == "null" || editData == null ? null : editData);
    const [selectedFileType, setSelectedFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onSelectFile = async (event) => {
        const pickedFile = event.target.files[0];
        const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/webp"];

        if (pickedFile && allowedTypes.includes(pickedFile.type)) {
            setIsLoading(true);

            let formdata = new FormData();
            formdata.append('files', pickedFile);

            try {
                const response = await axios.post(`${Url}fileupload`, formdata, {
                    headers: {
                        Accept: '*/*',
                        'Content-type': 'multipart/form-data',
                    },
                });
                await fileOnSet(name, response.data.data[0]);
                setSelectedFile(pickedFile);
                setFileName(pickedFile.name);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setSelectedFile(null);
            setSelectedFileType("");
            setFileName("");
        }
    };


    function deleteHandler() {
        setSelectedFile(null);
        setSelectedFileType("");
        setFileName("");
        document.getElementById("fileInput").value = "";
    }

    return (
        <div className="uploadimage uploadfile">
            <section className="uploadimage-container">
                <label>
                    {isLoading ? <Loader1 /> : <MdUpload color="#3786FB" size="5rem" />}
                    <span className="li-addlistings-details">{children}</span>
                    <span className="li-addlistings-notes">PDF and image files are allowed</span>
                    <input id="fileInput" type="file" name="file" onChange={onSelectFile} accept=".pdf, .jpeg, .png, .webp" />
                    {error != "" ? <p style={{ color: 'red' }}>{error}</p> : ''}
                </label>
                {selectedFile && (
                    <div className="image">
                        <AiFillCloseCircle color="red" onClick={deleteHandler} className="close-icon" />
                        {selectedFileType === "application/pdf" ? (
                            <p className="selectedfileName">{fileName}</p>
                        ) : (
                            <img src={selectedFile} height="100" width="100" alt="upload" />
                        )}
                    </div>
                )}
            </section>
        </div>
    );
}
