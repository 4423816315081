import { BUILDING_APPROVAL_SUCCESS, BUILDING_APPROVAL_FAIL, BUILDING_APPROVAL_VIEW, BUILDING_APPROVAL_INSERT, BUILDING_APPROVAL_UPDATE, BUILDING_APPROVAL_DELETE, BUILDING_APPROVAL_FILE_DOWNLOAD_SUCCESS, SET_LOADING } from "../type/types";

const initialState = {
    loading: true,
    viewList: [],
    insertData: [],
    downloadFileUrl: [],
    updateStatus: [],
    error: {}
}

export const BuildingApprovalReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case BUILDING_APPROVAL_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case BUILDING_APPROVAL_INSERT:
            return {
                ...state,
                loading: false,
                insertData: payload
            }
        case BUILDING_APPROVAL_FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadFileUrl: payload
            }
        case BUILDING_APPROVAL_UPDATE:
            return {
                ...state,
                loading: false,
                updateStatus: payload
            }
        case BUILDING_APPROVAL_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}