import React from 'react';
import ConsultingFeesListTable from './ConsultingFeesListTable'

const ConsultingFees = () => {
    return (
        <>
            <p className='listings-heading'>Consulting Charges</p>
            <ConsultingFeesListTable />
        </>
    )
}

export default ConsultingFees