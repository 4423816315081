import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { LuFileEdit } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CreatePlot2List from './CreatePlot2List';
import swal from 'sweetalert';

function CreatePlot2({ siteData }) {

    const history = useHistory()
    const [selection, setSelection] = useState(1);
    const selector = useSelector(state => state?.LayoutReducer);
    const [layoutData, setLayoutData] = useState([]);

    //console.log(`layoutData - ${JSON.stringify(layoutData)}`)

    useEffect(() => {
        setLayoutData(siteData);
    }, [siteData]);

    useEffect(() => {
        //console.log(`editData - ${JSON.stringify(selector?.editData?.data)}`)
        if (selector?.editData?.data?.alert === "Real Estate Layout Details") {
            setLayoutData(selector?.editData?.data?.data)
        }
    }, [selector?.editData?.data])

    const saveAndNext = async () => {
        swal("success", 'Property is posted successfully', "success");
        history.push('/Property');
    }

    return (
        <>
            {
                layoutData?.length > 0 && layoutData.map((data, index) => {
                    return (<CreatePlot2List item={data} key={index} />)
                })
            }
            <div className='align-self-end d-flex justify-content-center mt-3 mb-5'>
                <Button className='btn btn-success' style={{ height: 45, width: '30%', alignItems: 'flex-end' }} onClick={saveAndNext} >  <LuFileEdit size="18px" style={{ marginBottom: 5, marginRight: 5 }} /> Save And Next </Button>
            </div>
        </>
    )
}

export default CreatePlot2