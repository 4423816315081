import { BOOKINGS_FAIL, BOOKINGS_VIEW, BOOKINGS_SUCCESS, SET_LOADING } from "../type/types";

const initialState = {
    loading: true,
    viewList: [],
    error: {}
}


export const BookingsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case BOOKINGS_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case BOOKINGS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}