import CarouselDealer from '../Element/CarouselDealer';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import PlotFinderHome from '../Element/Admin/PlotFinder/PlotFinderHome';
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
//Images
import bnr6 from '../../images/Buyers/bnr6.png';
import profilepic from '../../images/icon.png';


function Buyers() {

	const dispatch = useDispatch();

	const [errors, setErrors] = useState({});
	const [bodyData, setBodyData] = useState({
		"name": "",
		"mailId": "",
		"mobileNo": "",
		"remarks": '',

	})

	const { name, mailId, mobileNo, remarks } = bodyData;

	const validate = (e) => {
		e.preventDefault();
		let isValid = true;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!bodyData.name || bodyData.name.length < 3) {
			handleError('Name must be min 3 characters long', 'name');
			isValid = false;
		}

		if (!bodyData.mailId || bodyData.mailId.length < 3) {
			handleError('Mail must be min 3 characters long', 'mailId');
			isValid = false;
		}

		if (!emailRegex.test(bodyData.mailId)) {
			handleError('Please enter a valid email address', 'mailId');
			isValid = false;
		}

		if (
			!bodyData.mobileNo ||
			isNaN(bodyData.mobileNo) ||
			(bodyData.mobileNo.toString().length !== 10 ||
				bodyData.mobileNo.toString().includes('.'))
		) {
			handleError('Enter valid number', 'mobileNo');
			isValid = false;
		}

		if (!bodyData.remarks || bodyData.remarks.length < 3) {
			handleError('Remarks is needed', 'remarks');
			isValid = false;
		}

		if (isValid) {
			mailHandler();
		}
	}

	const onChange = e => {
		setBodyData({ ...bodyData, [e.target.name]: e.target.value });
	}

	const handleError = (error, input) => {
		setErrors(prevState => ({ ...prevState, [input]: error }));
	};

	const mailHandler = async () => {
		swal("Great!", "You have sent a message, we will contact you soon!", "success");
		setBodyData({
			"name": "",
			"mailId": "",
			"mobileNo": "",
			"remarks": '',
		})

	}


	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full p-b50">
						<div className="container">
							<p className='heading-Buyers'>Recent Listing</p>
							<h4>Premium Plots in center of Salem with panoramic views</h4>
							<div className="row">
								<div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
									<div className="container">
										<img src={bnr6} alt="Site" className='img-fluid' />
									</div>
									<CarouselDealer />
									<p className='center-content-text'>
										My Property QR offers an exclusive FOR SALE plot of land in the Salem, TamilNadu.

										Thanks to its prime location, the plot offers breathtaking views and easy access to essential amenities. Spanning an area of 2900 m2, this plot provides ample space for building your dream home or investment property. The plot is situated at an elevated position, ensuring privacy and a panoramic view of the surrounding landscape.
										<br /><br />
										The plot offers exceptional potential for development, with easy access to utilities and infrastructure. Its unique location provides tranquility and a picturesque environment, making it an ideal choice for those seeking a blend of natural beauty and convenience.
										<br /><br />
										The overall appeal of the plot is enhanced by its proximity to the nearby Attractions, ensuring a high quality of life and great investment value.
										<br /><br />
										<h4>Basic characteristics:</h4>
										<ul className='Basic-char-ul'>
											<li>=&gt; Number of Plots: 26</li>
											<li>=&gt; Land area: 2900 <span> &#13217; </span></li>

										</ul> <br />

										<h4>Layout solution:</h4>
										The plot offers a unique opportunity to design and build your ideal home, with ample space to accommodate a variety of layouts. The suggested layout includes a spacious kitchen, a generous living room, a study, 4 bedrooms, 2 bathrooms, a wardrobe, and a fireplace. There is also provision for two garage parking spaces in the underground garage.
										<br /><br />
										The plot is divided into distinct zones, providing a seamless flow between the living and private areas. The living area can be designed to feature a large open-plan living room, connected to the kitchen and dining space. This area can also include a study, subtly separated from the main living space by elegant design elements such as glass walls and wooden beams.
										<br /><br />
										The transition to the private zone can be designed to include two rooms, a wardrobe, a shared bathroom, and a master bedroom with a separate en-suite bathroom.
										<br /><br />
										<h4>Design and Furnishing Recommendations:</h4>
										The plot allows for intelligent home control via a mobile application. Premium natural materials such as wood, stone tiles, and cast concrete can be incorporated throughout the living spaces to enhance the aesthetic appeal and durability.
										<br /><br />
										The kitchen can be custom-designed, with high-end appliances from renowned brands like SIEMENS. Bathrooms and toilets can be equipped with premium sanitary ware from manufacturers such as VILLEROY BOCH and HANSGROHE. For the master bathroom, consider the design edition of the AXOR MASSAUD brand, and for the master bedroom, a luxurious bed from the RUF BETTEN collection. The living room can be furnished with high-quality products from the ROLF BENZ brand.<br /><br />

										<h4>Location:</h4>
										The plot is situated near to Salem, offering excellent transport connections. The nearby housing estate provides complete civic amenities, including shops, cafes, restaurants, schools, kindergartens, and many other benefits.<br /><br />

										<h4>Fedors group opinion:</h4>
										This exceptional plot offers an incredible opportunity to build your dream home. Its prime location provides breathtaking views and a serene environment, ideal for a luxurious living experience. The elevated position gives the impression of a secluded retreat while being conveniently close to all necessary amenities. From our perspective, this plot stands out in the current market for its unique location and potential. It is guaranteed to provide its future owners with a new and luxurious standard of living.
									</p>
									<div >
										<p className='heading-Buyers'>Map Location of our Property</p>
										<PlotFinderHome />
									</div>
								</div>

								<div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
									<div className="sticky-top">
										<div className="custom-form-container mb-4">
											<h4 className='mb-4'>Contact Us</h4>
											<form onSubmit={validate}>
												<div className="form-group">
													{errors.name ?
														<label htmlFor="name" className="error-text">{errors.name}</label> :
														<label htmlFor="name" className="text-black">Full Name<span className="required">*</span> </label>
													}
													<input
														type="text"
														className="form-control form-input-holder"
														id="name" name='name'
														value={name}
														onChange={e => onChange(e)}
														placeholder="Your full name"
														noValidate
														onFocus={() => handleError(null, 'name')}
													/>
												</div>
												<div className="form-group">
													{errors.mobileNo ?
														<label htmlFor="mobileNo" className="error-text">{errors.mobileNo}</label> :
														<label htmlFor="mobileNo" className="text-black">Mobile Number<span className="required">*</span> </label>
													}
													<input
														type="text"
														className="form-control form-input-holder"
														id="mobileNo" name='mobileNo'
														value={mobileNo}
														maxLength={10}
														onChange={e => onChange(e)}
														placeholder="Enter Mobile Number"
														noValidate
														onFocus={() => handleError(null, 'mobileNo')}
													/>
												</div>
												<div className="form-group">
													{errors.mailId ?
														<label htmlFor="mailId" className="error-text">{errors.mailId}</label> :
														<label htmlFor="mailId" className="text-black">Mail Id<span className="required">*</span> </label>
													}
													<input
														type="text"
														className="form-control form-input-holder"
														placeholder="Type your email address"
														id="mailId" name="mailId"
														value={bodyData.mailId}
														onChange={e => onChange(e)}
														onFocus={() => handleError(null, 'mailId')}
													/>
												</div>
												<div className="form-group">
													{errors.remarks ?
														<label htmlFor="remarks" className="error-text">{errors.remarks}</label> :
														<label htmlFor="remarks" className="text-black">Remarks<span className="required">*</span> </label>
													}
													<textarea
														type="text"
														className="form-control form-input-holder"
														placeholder="Remarks"
														id="remarks" name="remarks"
														value={bodyData.remarks}
														onChange={e => onChange(e)}
														onFocus={() => handleError(null, 'remarks')}
														rows={7}
													/>
												</div>
												<div className="form-group">
													<div className="form-check">
														<input className="form-check-input" type="checkbox" id="gridCheck" />
														<label className="form-check-label" for="gridCheck">
															I agree to the processing of <span style={{ textDecoration: 'underline' }} >personal data.</span>
														</label>
													</div>
												</div>
												<div className=''>
													<button type="submit" className="btn btn-primary btn-Dealer-send-message">Send Message</button>
												</div>
											</form>
										</div>
										<div className="candidates-are-sys">
											<div className="candidates-bx">
												<div className="testimonial-pic radius"><img src={profilepic} alt="" width="100" height="100" /></div>
												<div className='testimonial-head'>My Property Qr</div>
												<div className="testimonial-text">
													<p>+91 8925798627 <br />
														mypropertyqr@gmail.com</p>
													{/* <p className='view-broker-profile'>Contact </p> */}
												</div>

											</div>
										</div>

										{/* <div className="quote-bx">
											<div className="quote-info">
												<p className='char-heading'>Brief characteristics</p>
												<p className='char-content'> <b>City: </b>Barcelona I. </p>
												<p className='char-content'> <b>Street: </b>Vincent ala Carne </p>
												<p className='char-content'> <b>Garages: </b> 2 cars </p>
												<p className='char-content'> <b>Type: </b>5 rooms </p>
												<p className='char-content'> <b>Number of rooms: </b>5</p>
												<p className='char-content'> <b>Usable area: </b>224 <span> &#13217; </span></p>
												<p className='char-content'> <b>Total area: </b>307 <span> &#13217; </span></p>
												<p className='char-content'> <b>Insulated object: </b>Yes</p>
												<p className='char-content'> <b>Balcony: </b>Yes</p>
												<p className='char-content'> <b>Terrace: </b>Yes</p>
												<p className='char-content'> <b>Number of bathrooms: </b>1</p>

											</div>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Buyers;