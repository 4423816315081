import React from 'react'

function VastuPlanDetailPage({ item }) {

    // console.log(`VastuPlanDetailPage item = ${JSON.stringify(item)}`)

    return (

        <div className='DetailPage-container'>
            <div className='mb-1'>
                <span className='PropertyName-heading'>User Name:  </span><span className='PropertyName-details'>{item?.userName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Mobile No:  </span><span className='PropertyName-details'>{item?.mobileNo}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Land Name:  </span><span className='PropertyName-details'>{item?.name}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Address:  </span><span className='PropertyName-details'>{item?.address}, {item?.pincode}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Village:  </span><span className='PropertyName-details'>{item?.villageName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Taluk:  </span><span className='PropertyName-details'>{item?.talukName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>District:  </span><span className='PropertyName-details'>{item?.districtName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Current Status:  </span><span className='PropertyName-details'>{item?.currentStatusName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Posted Date: </span><span className='PropertyName-details'>{item.insertedDateTime}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Visible / Active Status:  </span><span className='PropertyName-details'>{item?.visibleStatus == 1 ? 'Visible' : 'Hidden'}</span>
            </div>
        </div>

    )
}

export default VastuPlanDetailPage