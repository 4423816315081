import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { FMB_PATTA_DELETE, FMB_PATTA_FAIL, FMB_PATTA_FILE_DOWNLOAD_SUCCESS, FMB_PATTA_INSERT, FMB_PATTA_SURVEY_NO, FMB_PATTA_SUCCESS, FMB_PATTA_UPDATE, FMB_PATTA_VIEW } from "../type/types";
import { setLoading } from './LoadingAction';

// View FMB & PATTA
export const getFmbPattaList = (userRfId = "", rfId = 0, limit = 0) => async dispatch => {
    dispatch(setLoading(true));
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "rfId": rfId == 0 ? "" : rfId,
        "userRfId": userRfId > 0 && userRfId != undefined ? userRfId : "",
        "order": "desc",
        "fromDate": "",
        "toDate": "",
        "search": "",
        "limit": limit == 0 ? "" : limit,
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}survey-download`, formData, { headers }).then((response) => {
        //console.log(`getFmbPattaList response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch(setLoading(false));
            dispatch({
                payload: response,
                type: FMB_PATTA_VIEW
            })
        }
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch({
            payload: err.response,
            type: FMB_PATTA_FAIL
        })
    })
}

export const addFmbPattaList = (formData, history) => async dispatch => {

    //console.log(`addLegalOpinion formData = ${JSON.stringify(formData)}`)

    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    axios.post(`${Url}survey-download`, formData, { headers }).then((response) => {
        //console.log(`addLegalOpinion response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message === "Success") {
            swal("Success", `File is successfully sent to Client!`, "success");
            // history.goBack();
            dispatch({
                payload: response,
                type: FMB_PATTA_INSERT
            })
            getFmbPattaList();
        } else {
            // history.goBack();
            swal("Fail", `There is an error while sending the file to client, Please try again`, "error");
            dispatch({
                payload: response,
                type: FMB_PATTA_FAIL
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: FMB_PATTA_FAIL
        })
    })
}


export const deleteFmbPatta = (rfId, userRfId) => async dispatch => {

    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    const formData = {
        "action": "Delete",
        "rfId": rfId,
        "userRfId": userRfId,
        "loginUserId": loginUserId
    };
    axios.post(`${Url}survey-download`, formData, { headers }).then((response) => {
        // console.log(`deleteLegalOpinion response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message == 'Success') {
            swal("success", `FMB & Patta request is deleted successfully!`, "success");
            dispatch({
                payload: response,
                type: FMB_PATTA_DELETE
            })
        } else {
            swal("Fail", `FMB & Patta request is not deleted, Please try again`, "error");
            dispatch({
                payload: response,
                type: FMB_PATTA_FAIL
            });
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: FMB_PATTA_FAIL
        })
    })
}

// Fmb Patta Show / Hide Status update
export const showHideFmbPattaStatus = (rfId, userRfId, visibleStatus) => async dispatch => {

    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Show",
        "rfId": rfId,
        "visibleStatus": visibleStatus == 1 ? 0 : 1,
        "userRfId": userRfId,
        "loginUserId": loginUserId
    }

    await axios.post(`${Url}survey-download`, formData).then((response) => {
        //  console.log(`site data response - ${JSON.stringify(response)}`)
        if (response?.data?.message == "Success") {
            swal("Status Changed!", "The selected FMB & Patta status is changed successfully!", "success");
        } else {
            swal("Fail", `The selected FMB & Patta is status is not updated!, Please try again`, "error");
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: FMB_PATTA_FAIL
        })
    })
}

// Donwload FMB Patta
export const downloadFmbPatta = (rfId, userRfId, surveyNumber) => async dispatch => {

    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Download",
        "rfId": rfId,
        "userRfId": userRfId,
        "loginUserId": loginUserId,
        "surveyNumber": surveyNumber
    }
    console.log(`downloadFmbPatta- ${JSON.stringify(formData)}`)

    await axios.post(`${Url}survey-download`, formData).then((response) => {
        console.log(`site data response - ${JSON.stringify(response)}`)
        if (response?.data?.message == "Success") {
            swal("Downloaded", "The selected FMB & Patta is received from Server. Please check", "success");
            // dispatch(setLoading(false));
        } else {
            swal("Fail", `The selected FMB & Patta is status is not updated!, Please try again`, "error");
            // dispatch(setLoading(false));
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: FMB_PATTA_FAIL
        })
        // dispatch(setLoading(false));
    })
}

// View FMB & PATTA
export const getSurveyNumber = (latitude, longitude, setProcessing) => async dispatch => {
    setProcessing(true);
    const token = getTokenLocalStorage();
    const formData = {
        "action": "Survey Number",
        "latitude": latitude,
        "longitude": longitude
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}survey-download`, formData, { headers }).then((response) => {
        console.log(`getSurveyNumber response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message == 'Success') {
            setProcessing(false);
            dispatch({
                payload: response,
                type: FMB_PATTA_SURVEY_NO
            })
        } else {
            swal("Fail!", "Error to get the Survey No!, Please try again", "error");
            setProcessing(false);
        }
    }).catch((err) => {
        setProcessing(false);
        dispatch({
            payload: err.response,
            type: FMB_PATTA_FAIL
        })
    })
}
