import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import sqft from '../../images/sqft.png';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GetLandUrl } from "../../utils/Common";
import { DtSentenceCase } from "../../utils/Common";

export default function RecentListing() {

	const dispatch = useDispatch();
	const selector = useSelector(state => state?.LayoutReducer);
	const [layoutData, setLayoutData] = useState([]);

	useEffect(() => {
		if (selector?.payload?.data?.message === "Success") {
			// console.log(`response -${JSON.stringify(selector?.payload?.data?.data)}`)
			setLayoutData(selector?.payload?.data?.data)
		}
	}, [selector?.payload?.data])

	// console.log(`layoutData = ${JSON.stringify(layoutData)}`)

	return (
		<div className="section-full bg-white content-inner-2">
			<div className="container">
				<div className="row mb-4 text-left">
					<div className="col-12">
						<h3>Recent Listing</h3>
						<h6> Recently Added Properties</h6>
					</div>
				</div>
				<div className="row post-villa-bx">
					<div className="col-lg-12 col-sm-12">
						<ul className="">
							{layoutData.map((item, index) => (
								<li key={index} className=''>
									<div className="row post-villa mb-5">
										<div className="col-lg-2 col-md-4 col-sm-12 post-villa-col">
											<img
												src={item?.siteMap == "null" || item?.siteMap == "" || item?.siteMap == null ? GetLandUrl(item?.layoutType, item?.landType) : JSON.parse(item?.siteMap)[0]}
												alt="" className='post-villa-image' />
										</div>
										<div className="col-lg-7 col-md-5 col-sm-12">
											<div className="mt-4">
												<h5>{item?.layoutName} <span style={{ fontSize: 14, fontWeight: "normal" }}>({item.landTypeName})</span></h5>
												<ul>
													<li className="mb-2">
														<FontAwesomeIcon icon={faLocationDot} className='pr-3 ml-1 location-icon' />{DtSentenceCase(item.districtName)}
													</li>
													<li>
														<img src={sqft} alt="" width={25} height={25} className='pr-2 mb-1 sqft-icon' /> {item?.totalLandArea} {item.landAreaTypeName}
													</li>
													<li> <p className="site-price mt-2">₹ {item.approximateRate} / {item.landAreaTypeName}</p></li>
												</ul>
											</div>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-12 text-center align-self-center">
											<Link to={'/Login-page'}>
												<button type="button" className="btn btn-primary">View</button>
											</Link>
										</div>
									</div>
								</li>
							))}

						</ul>
					</div>
					<div className="col-md-12 text-center">
						<Link to={'/Login-page'}>
							<button type="button" className="btn btn-primary">Load More</button>
						</Link>
					</div>
				</div>
			</div>
		</div >
	)
}
