import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { GLOBAL_SEARCH_SUCCESS, GLOBAL_SEARCH_FAIL } from "../type/types";
import { setGlobalLoading } from './LoadingAction';

export const getGlobalSearchData = (rfId = 0, mobileNo) => async dispatch => {
    // console.log(`getGlobalSearchData rfId = ${rfId}`)
    // console.log(`getGlobalSearchData mobileNo = ${mobileNo}`)
    dispatch(setGlobalLoading(true));
    const token = getTokenLocalStorage();
    let formData = {
        "action": "Dashboard",
        "userRfId": rfId == 0 ? "" : rfId,
        "limit": 1,
        "order": "desc",
        "type": "Search"
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}member`, formData, { headers }).then((response) => {
        //  console.log(`getGlobalSearchData response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch(setGlobalLoading(false));
            dispatch({
                payload: response,
                type: GLOBAL_SEARCH_SUCCESS,
                userRfId: rfId,
                userMobileNo: mobileNo
            })
        } else {
            dispatch(setGlobalLoading(false));
            swal("Fail", `The Selected LandOwner didn't post anything yet..`, "error");
            dispatch({
                payload: response,
                type: GLOBAL_SEARCH_FAIL,
                userRfId: "",
                userMobileNo: ""
            })
        }
    }).catch((err) => {
        dispatch(setGlobalLoading(false));
        dispatch({
            payload: err.response,
            type: GLOBAL_SEARCH_FAIL,
            userRfId: "",
            userMobileNo: ""
        })
    })
}

export const emptyGlobalSearchData = () => async (dispatch) => {
    dispatch(setGlobalLoading(true)); // Start loading

    setTimeout(() => {
        // Dispatch the empty data after 2000 ms
        dispatch({
            payload: "",
            type: GLOBAL_SEARCH_SUCCESS,
            userRfId: "",
            userMobileNo: ""
        });

        dispatch(setGlobalLoading(false)); // Stop loading after timeout
    }, 10000);
};