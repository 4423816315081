import SearchIcon from '@mui/icons-material/Search';
import { default as Box } from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { default as Typography } from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { IoNavigate } from "react-icons/io5";
import Loader1 from '../../../../utils/Loader1';
import { getNeedProperty, showHideINeedPropertyStatus } from '../../../../store/action/NeedPropertyAction';
import { Modal } from 'react-bootstrap';
import CroEntryNeedProperty from './CroEntryNeedProperty';
import { permissionCheck } from '../../../../utils/Common';
import { getTokenLocalUserStorage } from '../../../../utils/Common';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Sl.no',
        numeric: false,
        label: 'Sl.No',
    },
    {
        id: 'userName',
        numeric: false,
        label: 'Name',
    },
    {
        id: 'mobileNo',
        numeric: true,
        label: 'Mobile No',
    },
    {
        id: 'insertedDateTime',
        numeric: false,
        label: 'Posted Date & Time',
    },
    {
        id: 'maximumAmount',
        numeric: false,
        label: 'Max Amount',
    },
    {
        id: 'propertyTypeName',
        numeric: false,
        label: 'Property Type',
    },
    {
        id: 'navigation',
        numeric: false,
        label: 'Location',
    },
    {
        id: 'action',
        numeric: true,
        label: 'Action',
    },



];


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid lightgrey',
        borderRadius: '5px',
        height: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


function ListingDataTableHead(props) {

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ListingDataTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function ListingDataTableToolbar({ search, setSearch, isAddEnabled }) {

    const history = useHistory();
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const addClickHandler = () => {
        history.push({
            pathname: '/AddNeedProperty',
            state: { editData: "" }
        });
    };

    return (
        <div className='d-flex justify-content-between mx-5'>
            <Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </Search>
            </Typography>
            <Button
                variant="contained"
                startIcon={<IoMdAddCircle />}
                onClick={() => addClickHandler()}
                size="medium"
                disabled={!isAddEnabled}
                style={{ textTransform: 'none', color: "white", backgroundColor: isAddEnabled ? '#3786FB' : 'lightGrey' }}
            >
                Add Need Property
            </Button>
        </div>
    );
}

ListingDataTableToolbar.propTypes = {
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
};

export default function NeedPropertyListTable() {

    const dispatch = useDispatch();
    const history = useHistory();
    const selector = useSelector(state => state?.NeedPropertyReducer);
    const searchSelector = useSelector(state => state?.GlobalSearchReducer);

    const [croModalOpen, setCroModalOpen] = useState(false);
    const [needPropertyData, setNeedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modelData, setModelData] = useState({})
    const [search, setSearch] = useState('');
    const [permission, setPermission] = useState({
        canInsert: [],
        canEdit: [],
        canHide: [],
        canCro: [],
    })

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        const fetchPermissionData = async () => {
            let Permission = await getTokenLocalUserStorage("permission");
            let registerType = await getTokenLocalUserStorage("registerType");

            let canInsert = await permissionCheck(Permission, 1016);
            let canEdit = await permissionCheck(Permission, 1017);
            let canHide = await permissionCheck(Permission, 1018);
            let canCro = await permissionCheck(Permission, 1019);

            setPermission({
                ...permission,
                canInsert: registerType === 1 ? ["0000"] : canInsert,
                canEdit: registerType === 1 ? ["0000"] : canEdit,
                canHide: registerType === 1 ? ["0000"] : canHide,
                canCro: registerType === 1 ? ["0000"] : canCro
            })
        };
        fetchPermissionData();
    }, [])

    const isAddEnabled = permission?.canInsert?.length > 0 ? true : false;
    const isEditEnabled = permission?.canEdit?.length > 0 ? true : false;
    const isHideEnabled = permission?.canHide?.length > 0 ? true : false;
    const isCroEnabled = permission?.canCro?.length > 0 ? true : false;

    const fetchData = async () => {
        setNeedPropertyData([]);
        setPage(0);
        const lastRfId = needPropertyData[needPropertyData.length - 1]?.rfId;

        await dispatch(getNeedProperty(searchSelector?.userRfId !== "" ? searchSelector?.userRfId : "", lastRfId, 26));
    }

    useEffect(() => {
        if (selector?.viewList?.data?.message === "Success") {
            const newData = selector?.viewList?.data?.data || [];
            setNeedPropertyData(prevData => [...prevData, ...newData]);
        }
    }, [selector?.viewList?.data]);

    const endScrollFunction = async () => {
        const lastRfId = needPropertyData[needPropertyData.length - 1]?.rfId;
        await dispatch(getNeedProperty(searchSelector?.userRfId !== "" ? searchSelector?.userRfId : "", lastRfId, 26));
    }


    const croClickHandler = (data) => {
        setModelData(data)
        setCroModalOpen(true);
    }

    const croCloseHandler = () => setCroModalOpen(false);


    //Table Related States
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sqft');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        endScrollFunction();
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = needPropertyData.filter((item) => {
        return (
            (item?.rfId || '').toString().includes(search.toLowerCase()) ||
            (item.userName || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item.mobileNo || '').toString().includes(search.toLowerCase()) ||
            (item.insertedDateTime || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item.maximumAmount || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item.propertyTypeName || '').toString().toLowerCase().includes(search.toLowerCase()) ||
            (item.propertyRadiusTypeName || '').toString().includes(search.toLowerCase())
        );
    });

    const editClickHandler = (item) => {
        history.push({
            pathname: '/AddNeedProperty',
            state: { editData: item }
        });
    };
    //Delete Button Click
    const deleteButtonClick = async (rfId, userRfId) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete this Land Owner?",
            icon: "warning",
            showCancelButton: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                //await dispatch(staffRegistrationDelete(rfId, 2, history));
                swal("Deleted!", "Your needed property has been deleted!", "success");
            }
        })
    }

    //Show Button Click
    const hideClickHandler = async (item) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to Hide / Show this Property?",
            icon: "warning",
            showCancelButton: true,
            dangerMode: true,
        }).then(async (willUpdate) => {
            if (willUpdate) {
                await dispatch(showHideINeedPropertyStatus(item?.rfId, item?.userRfId, item?.visibleStatus));
                fetchData();
            }
        })
    }

    const showInMapClicked = (latitude, longitude) => {
        window.open("https://maps.google.com?q=" + latitude + "," + longitude);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

    return (
        <>
            <ListingDataTableToolbar search={search} setSearch={setSearch} isAddEnabled={isAddEnabled} />
            {isLoading ?
                <div className='mt-5'><Loader1 /></div> :
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Paper sx={{ width: '95%', mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ width: '100%', marginLeft: 5, justifyContent: 'center' }}
                            >
                                <ListingDataTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={filteredData.length}
                                />
                                <TableBody>
                                    {stableSort(filteredData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={item.rfId}
                                                    className='DataTable-row'
                                                    style={{ height: 70, justifyContent: 'center', opacity: item?.visibleStatus == 0 ? 0.5 : 1 }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ width: 80 }}
                                                    >
                                                        {item.rfId}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {item.userName}
                                                    </TableCell >
                                                    <TableCell sx={{ width: 130 }}
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >{item.mobileNo}
                                                    </TableCell>
                                                    <TableCell sx={{ width: 180 }}
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >{item.insertedDateTime}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ width: 100 }}
                                                    >
                                                        ₹ {(item?.maximumAmount / 100000).toFixed(0)} L
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ width: 200 }}
                                                    >{item.propertyTypeName} with in {item?.propertyRadiusTypeName}</TableCell>
                                                    <TableCell sx={{ width: 50 }}
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Button onClick={() => showInMapClicked(item?.latitude, item?.longitude)} variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#64b5e4' }} >
                                                            <IoNavigate size={18} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ width: 200 }}>

                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            style={{ textTransform: 'none', color: "white", backgroundColor: isEditEnabled ? '#FDB447' : 'lightGrey', marginRight: 10 }}
                                                            disabled={!isEditEnabled}
                                                            onClick={() => editClickHandler(item)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            style={{ textTransform: 'none', color: "white", backgroundColor: isHideEnabled ? '#e777b6' : 'lightGray', marginRight: 10 }}
                                                            disabled={!isHideEnabled}
                                                            onClick={() => hideClickHandler(item)}>
                                                            {item?.visibleStatus == 0 ? 'Show' : 'Hide'}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            style={{ textTransform: 'none', color: "white", backgroundColor: isCroEnabled ? '#81d06f' : 'lightGray' }}
                                                            onClick={() => croClickHandler(item)}
                                                            disabled={!isCroEnabled}
                                                        >CRO
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <Modal
                        show={croModalOpen}
                        onHide={croCloseHandler}
                        backdrop="true"
                        keyboard={false}
                        size='lg'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>CRO Entry Details - I need Property</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CroEntryNeedProperty item={modelData} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={croCloseHandler}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Box>
            }
        </>
    );
}
