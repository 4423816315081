import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../../../utils/Common';
import { getVillageFinder } from '../../../../store/action/VillageFinderAction';
import { useDispatch, useSelector } from "react-redux";
import Loader1 from '../../../../utils/Loader1'

const center = {
    lat: 11.342423,
    lng: 77.728165
};

function PickLocation({ onChangeLocation, districtData }) {

    const dispatch = useDispatch();
    const selector = useSelector(state => state?.VillageFinderReducer);


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: ['places']
    });

    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(center);
    const [autocomplete, setAutocomplete] = useState(null);
    const [address, setAddress] = useState('');
    const [mapResult, setMapResult] = useState('');

    const [villageFinderData, setVillageFinderData] = useState(null); // For village finder response
    const [geocodeResult, setGeocodeResult] = useState(null);         // For geocode response
    const [loading, setLoading] = useState(false);


    const mapRef = useRef(null);
    const geocoder = useRef(null);

    useEffect(() => {
        if (selector?.payload?.data?.message === "Success") {
            // console.log(`AutoComplete Data = ${JSON.stringify(selector?.payload?.data?.data)}`)
            setVillageFinderData(selector?.payload?.data?.data?.data);
            //commonFunction(selector?.payload?.data?.data?.data, mapResult);
        }
    }, [selector?.payload?.data])

    useEffect(() => {
        if (villageFinderData && geocodeResult) {
            commonFunction(villageFinderData, geocodeResult);
        }
    }, [villageFinderData, geocodeResult]);

    //console.log(`villageFinderData after useEffect = ${JSON.stringify(villageFinderData)}`)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
        mapRef.current = map;
        geocoder.current = new window.google.maps.Geocoder();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    setMarkerPosition(currentPosition);
                    mapRef.current.panTo(currentPosition);
                    geocodeLatLng(currentPosition);
                },
                (error) => {
                    console.error("Error getting current position:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);


    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const onMapClick = React.useCallback((e) => {
        const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setMarkerPosition(position);
        geocodeLatLng(position);
    }, []);

    const onLoadAutocomplete = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = async () => {
        setVillageFinderData(null);
        setGeocodeResult(null);
        setAddress("");

        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                setLoading(true);
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setMarkerPosition(location);
                mapRef.current.panTo(location);
                setGeocodeResult(place);

                const villageFinderResponse = await dispatch(getVillageFinder(location.lat, location.lng));
                if (villageFinderResponse?.payload?.data?.message === "Success") {
                    console.log(`VillageFinder response: ${JSON.stringify(villageFinderResponse.payload.data.data.data)}`);
                    setVillageFinderData(villageFinderResponse.payload.data.data.data);  // Set VillageFinder data
                }

                setAddress(place.formatted_address);
                setLoading(false);
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const geocodeLatLng = (position) => {
        setVillageFinderData(null);
        setGeocodeResult(null);
        setAddress("");

        geocoder.current.geocode({ location: position }, async (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    setLoading(true);
                    setGeocodeResult(results[0]);

                    const villageFinderResponse = await dispatch(getVillageFinder(position.lat, position.lng));
                    if (villageFinderResponse?.payload?.data?.message === "Success") {
                        setVillageFinderData(villageFinderResponse.payload.data.data.data);
                        commonFunction(villageFinderResponse.payload.data.data.data, results[0]);
                    }

                    setAddress(results[0].formatted_address);
                    setLoading(false);
                } else {
                    console.log('No geocode results found');
                    setLoading(false);
                }
            } else {
                console.log('Geocoder failed due to: ' + status);
            }
        });
    };



    const commonFunction = async (villageFinderData, results) => {
        // console.log(`commonFunction result = ${JSON.stringify(results)}`);

        let addressData = {
            address: "",
            district: "",
            state: "",
            country: "",
            pinCode: "",
            districtId: "",
            location: "",
            talukName: "",
            villageName: "",
        };

        let addressParts = [];

        results?.address_components?.forEach(details => {

            if (details?.types.includes("plus_code")) {
                addressParts.push(details?.long_name);
            }

            if (details?.types.includes("locality")) {
                addressData.location = details?.long_name;
                addressParts.push(details?.long_name);
            }

            if (details?.types.includes("postal_code")) {
                addressData.pinCode = details?.long_name;
            }

            if (details?.types.includes("country")) {
                addressData.country = details?.long_name;
            }

            if (details?.types.includes("administrative_area_level_1")) {
                addressData.state = details?.long_name;
            }

            if (details?.types.includes("administrative_area_level_3")) {
                addressData.district = details?.long_name;
            }
        });

        addressData.address = addressParts.join(" , ");

        if (districtData && districtData.length > 0) {
            let districtRfId = districtData.filter((filterData) => {
                if (filterData.districtName === addressData.district?.toUpperCase()) {
                    return filterData.rfId;
                }
            });
            if (districtRfId?.length > 0) {
                addressData.districtId = districtRfId[0]?.rfId;
            }
        }

        if (onChangeLocation) {
            await onChangeLocation(villageFinderData, addressData?.address, addressData?.location, addressData?.pinCode, addressData?.districtId);
        }
    };


    return isLoaded ? (
        <div className='model-content-container VillageFinder'>
            <GoogleMap
                mapContainerStyle={{ width: '96%', height: '95%' }}
                center={markerPosition}
                zoom={9.2}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={onMapClick}
                options={{
                    streetViewControl: false,
                    fullscreenControl: false,
                }}
            >
                <Autocomplete
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        type="text"
                        placeholder="Enter a location"
                        className="autocomplete-input"
                    />
                </Autocomplete>
                <Marker position={markerPosition} />
            </GoogleMap>
            <div className='SelectedAddress_Con'>
                {loading ?
                    <div className='p-4'>
                        <Loader1 />
                    </div>
                    :
                    <div className='SelectedAddress_Inner_Con'>
                        <p className='AddressTxt1'>{address}</p>

                        {villageFinderData?.revenue_village_name != null &&
                            <p className='AddressTxt1'>Village Name = <span>{villageFinderData?.revenue_village_name}
                            </span>
                            </p>
                        }
                        {villageFinderData?.taluk_name != null &&
                            <p className='AddressTxt1'>Taluk Name = <span>{villageFinderData?.taluk_name}
                            </span>
                            </p>
                        }
                        {villageFinderData?.district_name != null &&
                            <p className='AddressTxt1'>District Name = <span>{villageFinderData?.district_name}
                            </span>
                            </p>
                        }
                    </div>
                }
            </div>
        </div>
    ) : <></>;
}

export default React.memo(PickLocation);

