import React from 'react'
import AddConsultant from './AddConsultant'
import Header from '../../../Layout/Header'
import Footer from '../../../Layout/Footer'

function ExpertiseAddConsultant() {
    return (
        <div className="page-wraper bg-white">
            <Header />
            <div className='mx-5 mt-4'>
                <div className='mx-5'>
                    <AddConsultant expertise={true} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ExpertiseAddConsultant
