import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage } from '../../utils/Common';
import { SITE_DELETE, SITE_FAIL, SITE_INSERT, SITE_SUCCESS, SITE_UPDATE, SITE_VIEW } from "../type/types";

// Get Site Roll Number
export const getSiteRegistrationData = (layoutRollNo = 0) => async dispatch => {
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "layoutRollNo": layoutRollNo == 0 ? "" : layoutRollNo,
        "order": "asc"
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}site`, formData, { headers }).then((response) => {
        dispatch({
            payload: response,
            type: SITE_VIEW
        })
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: SITE_FAIL
        })
    })
}

// export Site View Data
export const getSiteData = (layoutRollNo) => async dispatch => {
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "layoutRollNo": layoutRollNo,
        "order": "asc"
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}site`, formData, { headers }).then((response) => {
        //console.log(`response data - ${ JSON.stringify(response) }`) 
        dispatch({
            payload: response,
            type: SITE_VIEW
        })
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: SITE_FAIL
        })
    })
}

//Site Edit
export const updateSiteData = (data, saleStatus = 0) => async dispatch => {
    //console.log(`site data - ${JSON.stringify(data)}`)
    let formData = data;
    formData.action = "Insert";
    formData.verificationStatus = 1;
    saleStatus != 0 && (formData.saleStatus = saleStatus)


    await axios.post(`${Url}site`, formData).then((response) => {

        console.log(`response - ${JSON.stringify(response)}`)
        return true;
        // console.log(`json daya - ${JSON.stringify(response?.data)}`);
    }).catch((err) => {
        //  console.log(`err- ${err}`)
        return false;
    })
}

