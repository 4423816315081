import React, { useState } from 'react';
import Footer from '../../../Layout/Footer';
import Header from '../../../Layout/Header';
import CreateHouse from './CreateHouse';
import CreateLand from './CreateLand';
import CreatePlot1 from './CreatePlot1';
import CreatePlot2 from './CreatePlot2';
import { Button } from '@mui/material';
import { TiArrowBack } from 'react-icons/ti';
import { useHistory, useLocation } from 'react-router-dom';

const CreateProperty = (props) => {

    const item = props?.location?.state?.layoutData


    // console.log(`CreateProperty item = ${JSON.stringify(item)}`)


    let history = useHistory();

    const [activeTab, setActiveTab] = useState(item?.layoutType != undefined ? item?.layoutType : 1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const renderCreateProperty = () => {
        switch (activeTab) {
            case 1:
                return <CreatePlot1 data={item?.layoutType != undefined ? item : ""} />;
            case 2:
                return <CreateHouse data={item?.layoutType != undefined ? item : ""} />;
            case 3:
                return <CreateLand data={item?.layoutType != undefined ? item : ""} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="container post-property-heading">
                <div className='d-flex mb-4'>
                    <Button className='p-0'>
                        <TiArrowBack onClick={() => history.goBack({ item })} size={35} />
                    </Button>
                    <p className='listings-headingWithBack'>{item?.layoutType != undefined ? "Update" : "Post"} your Property</p>
                </div>
                {item?.layoutType == undefined && <ul className="nav nav-tabs nav-pills">
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 1 ? 'active' : ''} px-5`}
                            onClick={() => handleTabClick(1)}
                        >
                            Plots
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 3 ? 'active' : ''} px-5`}
                            onClick={() => handleTabClick(3)}
                        >
                            Lands
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 2 ? 'active' : ''} px-5`}
                            onClick={() => handleTabClick(2)}
                        >
                            Houses
                        </button>
                    </li>
                </ul>
                }
                <div className="mt-4">
                    {activeTab === 1 && renderCreateProperty()}
                    {activeTab === 2 && renderCreateProperty()}
                    {activeTab === 3 && renderCreateProperty()}
                </div>
            </div>

            {/* <Footer /> */}
        </>
    )
}

export default CreateProperty;
