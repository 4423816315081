import React from 'react';
import BookingsListTable from './BookingsListTable'

const BookingsHome = () => {
    return (
        <>
            <p className='listings-heading'>Bookings</p>
            <BookingsListTable />
        </>
    )
}

export default BookingsHome