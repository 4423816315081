import React from 'react';
import AddAgent from './AddAgent';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';

function ExpertiseAddAgent() {

    return (
        <>
            <div className="page-wraper bg-white">
                {/* <Header /> */}
                <div className='mx-5'>
                    <AddAgent expertise={true} />
                </div>
                {/* <Footer /> */}
            </div>
        </>
    )
}

export default ExpertiseAddAgent