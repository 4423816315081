import { AUTH_ERROR, USER_LOADED, REGISTER_SUCCESS, LOGIN_SUCCESS, LOGIN_FAIL, CLEAR_PROFILE, LOGOUT, OTP_VERIFY_FAIL, OTP_VERIFY } from "../type/types";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthendicated: null,
    loading: true,
    payload: []
}

export const LoginReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthendicated: true,
                loading: false,
                payload: payload
            }
        case OTP_VERIFY:
            return {
                ...state,
                isAuthendicated: false,
                loading: false,
                payload: payload
            }
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isAuthendicated: true,
                loading: false
            }
        case LOGIN_FAIL:
        case LOGOUT:
        case OTP_VERIFY_FAIL:
            return {
                ...state,
                token: null,
                isAuthendicated: false,
                loading: false,
                payload: payload
            }
        default:
            return state;

    }

}