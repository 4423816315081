import { NEED_PROPERTY_SUCCESS, NEED_PROPERTY_FAIL, NEED_PROPERTY_VIEW, NEED_PROPERTY_INSERT, NEED_PROPERTY_DELETE, NEED_PROPERTY_UPDATE, NEED_PROPERTY_MORE_INPUTS_FAIL, NEED_PROPERTY_MORE_INPUTS_INSERT, NEED_PROPERTY_MORE_INPUTS_SUCCESS, NEED_PROPERTY_MORE_INPUTS_UPDATE, NEED_PROPERTY_MORE_INPUTS_VIEW } from "../type/types";

const initialState = {
    loading: true,
    viewList: [],
    insertData: [],
    insertMoreInputs: [],
    viewMoreInputs: [],
    error: {}
}

export const NeedPropertyReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case NEED_PROPERTY_VIEW:
            return {
                ...state,
                loading: false,
                viewList: payload
            }
        case NEED_PROPERTY_INSERT:
            return {
                ...state,
                loading: false,
                insertData: payload
            }
        case NEED_PROPERTY_MORE_INPUTS_VIEW:
            return {
                ...state,
                loading: false,
                viewMoreInputs: payload
            }
        case NEED_PROPERTY_MORE_INPUTS_INSERT:
            return {
                ...state,
                loading: false,
                insertMoreInputs: payload
            }

        case NEED_PROPERTY_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}