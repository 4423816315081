import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { LAYOUT_DELETE, LAYOUT_FAIL, LAYOUT_INSERT, LAYOUT_SUCCESS, LAYOUT_UPDATE, LAYOUT_VIEW } from "../type/types";
import { setLoading } from './LoadingAction';

// Get Layout Roll Number
export const getLayoutRegistrationData = (layoutRollNo = 0) => async dispatch => {
    //console.log(`layoutRollNo = ${layoutRollNo}`)
    dispatch(setLoading(true));
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "layoutRollNo": layoutRollNo == 0 ? "" : layoutRollNo,
        "order": "asc"
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}site`, formData, { headers }).then((response) => {
        // console.log(`getLayoutRegistrationData = ${JSON.stringify(response?.data?.data)}`)
        dispatch(setLoading(false));
        dispatch({
            payload: response,
            type: LAYOUT_VIEW
        })
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch({
            payload: err.response,
            type: LAYOUT_FAIL
        })
    })
}

// Get Layout Insert
export const layoutRegistrationData = (formData, history, setSending) => async dispatch => {

    setSending(true);
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    //console.log(`formData - ${JSON.stringify(formData)}`)

    axios.post(`${Url}layout/layout`, formData, { headers }).then((response) => {
        //console.log(`response - ${JSON.stringify(response)}`)
        if (response?.data?.message == "Success") {
            dispatch({
                payload: response,
                type: LAYOUT_INSERT
            });
            setSending(false);
            history.push("/CreatePlot2");

        } else {
            swal("Fail", response?.data?.alert, "error");
            dispatch({
                payload: [],
                type: LAYOUT_FAIL
            });
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: LAYOUT_FAIL
        });
        setSending(false);
    })
}

// export layout View Data
export const getLayoutData = (userRfId = "", districtId = 0, layoutType = "All", landType = 0, rfId = 0, limit = 0, loginUserId = 0, layoutStatus = 1, minimum = 0, maximum = 0, search = 0, fromDate = 0, toDate = 0) => async dispatch => {

    // console.log(`getLayoutData userRfId= ${userRfId}`)
    // console.log(`getLayoutData rfId= ${rfId}`)
    // console.log(`getLayoutData limit= ${limit}`)
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "rfId": rfId == 0 ? "" : rfId,
        "contactNumber": "",
        "fromDate": "",
        "toDate": "",
        "districtId": districtId == 0 ? "" : `${districtId}`,
        "blockId": "",
        "stateId": "",
        "layoutType": layoutType == "All" ? "" : layoutType,
        // "urlStatus": urlStatus == 1 ? "" : urlStatus,
        "landType": landType == 0 ? "" : landType,
        "limit": limit == 0 ? "" : limit,
        "order": "",
        "userRfId": userRfId > 0 && userRfId != undefined ? userRfId : "",
        "loginUserId": loginUserId == 0 ? "" : loginUserId,
        "layoutStatus": layoutStatus == 0 ? "" : 1,
        "fromDate": fromDate == 0 ? "" : fromDate,
        "toDate": toDate == 0 ? "" : toDate,
        "districtId": districtId == 0 ? "" : `${districtId}`,
        "search": search == 0 ? "" : search,
        "minimum": minimum == 0 && maximum == 0 ? "" : minimum,
        "maximum": maximum == 0 ? "" : maximum
    }
    const headers = { "x-auth-header": `${token}` }
    //console.log(`getLayoutData formData= ${JSON.stringify(formData)} `)
    axios.post(`${Url}layout/layout`, formData, { headers }).then((response) => {
        // console.log(`getLayoutData response= ${JSON.stringify(response)} `)
        if (response?.data?.message == "Success") {
            dispatch({
                payload: response,
                type: LAYOUT_VIEW
            })
        } else {
            dispatch({
                payload: response,
                type: LAYOUT_FAIL
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: LAYOUT_FAIL
        })
    })
}

//Layout Delete
export const deleteLayoutDeleteData = (rfId, userRfId) => async dispatch => {
    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;
    let formData = {
        "action": "Delete",
        "userRfId": userRfId,
        "rfId": rfId,
        "loginUserId": loginUserId
    }
    await axios.post(`${Url}layout/layout`, formData).then((response) => {
        if (response?.data?.message == "Success") {
            swal("Deleted!", "The selected Property is deleted successfully!", "success");
            dispatch({
                payload: [],
                type: LAYOUT_DELETE
            })
        } else {
            swal("Fail", `The selected Property is not deleted!, Please try again`, "fail");
            dispatch({
                payload: response,
                type: LAYOUT_FAIL
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: LAYOUT_FAIL
        })
    })
}

//Layout Edit
export const updateSiteData = (data, saleStatus = 0) => async dispatch => {
    //console.log(`site data - ${JSON.stringify(data)}`)
    let formData = data;
    formData.action = "Insert";
    formData.verificationStatus = 1;
    saleStatus != 0 && (formData.saleStatus = saleStatus)

    await axios.post(`${Url}site`, formData).then((response) => {
        console.log(`response - ${JSON.stringify(response)}`)
        return true;
        // console.log(`json daya - ${JSON.stringify(response?.data)}`);
    }).catch((err) => {
        //  console.log(`err- ${err}`)
        return false;
    })
}

// Layout Show / Hide Status update

export const showHidePropertyStatus = (rfId, userRfId, layoutStatus) => async dispatch => {

    //console.log(`site data - ${JSON.stringify(data)}`)
    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Show",
        "userRfId": userRfId,
        "rfId": rfId,
        "status": layoutStatus == 1 ? 0 : 1,
        "loginUserId": loginUserId
    }

    console.log(`site data formData - ${JSON.stringify(formData)}`)

    await axios.post(`${Url}layout/layout`, formData).then((response) => {

        console.log(`site data response - ${JSON.stringify(response)}`)
        if (response?.data?.message == "Success") {
            swal("Status Changed!", "The selected Property status is changed successfully!", "success");
            // dispatch({
            //     payload: [],
            //     type: LAYOUT_UPDATE
            // })
        } else {
            swal("Fail", `The selected Property is status is not updated!, Please try again`, "fail");
            // dispatch({
            //     payload: response,
            //     type: LAYOUT_FAIL
            // })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: LAYOUT_FAIL
        })
    })
}