import { MEMBER_INSERT, MEMBER_VIEW, MEMBER_UPDATE, MEMBER_DELETE, MEMBER_SUCCESS, MEMBER_FAIL, SET_LOADING } from "../type/types";

const initialState = {
    loading: true,
    payload: [],
    error: {}
}

export const MemberReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case MEMBER_VIEW:
            return {
                ...state,
                loading: false,
                payload: payload
            }
        case MEMBER_INSERT:
            return {
                ...state,
                loading: false,
                payload: payload
            }
        case MEMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }

}