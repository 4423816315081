import React from 'react';
import NeedPropertyListTable from './NeedPropertyListTable'

const NeedProperty = () => {
    return (
        <>
            <p className='listings-heading'>I Need Property</p>
            <NeedPropertyListTable />
        </>
    )
}

export default NeedProperty