import React from 'react'
import AddWriter from './AddWriter'
import Header from '../../../Layout/Header'
import Footer from '../../../Layout/Footer'

function ExpertiseAddWriter() {
    return (
        <div className="page-wraper bg-white">
            <Header />
            <div className='mx-5 mt-4'>
                <div className='mx-5'>
                    <AddWriter expertise={true} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ExpertiseAddWriter
