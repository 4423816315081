import React from 'react';
import { Link } from 'react-router-dom';

import foto1 from '../../images/Buyers/foto1.png';
import foto2 from '../../images/Buyers/foto2.png';
import foto3 from '../../images/Buyers/foto3.png';

import flat from '../../images/Buyers/flat.png';
import meter from '../../images/Buyers/meter.png';
import pin from '../../images/Buyers/pin.png';

import left from '../../images/Buyers/left.png';
import right from '../../images/Buyers/right.png';



export default function CarouselDealer() {
	return (
		<>
			<div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
				<div className="row">
					<div className="col-md-1 col-sm-12">
						<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"> <span ><img className='carousel-control-prev-custom' src={left} alt="" /></span>
						</button>
					</div>
					<div className="col-md-10 col-sm-12">
						<div className="carousel-inner">
							<div className="carousel-item active">
								<div className="card-wrapper container-sm d-flex  justify-content-around">
									<img src={foto1} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto2} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto3} className="img-fluid custom-img-fluid" alt="..." />
								</div>
							</div>
							<div className="carousel-item">
								<div className="card-wrapper container-sm d-flex  justify-content-around">
									<img src={foto1} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto2} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto3} className="img-fluid custom-img-fluid" alt="..." />
								</div>
							</div>
							<div className="carousel-item">
								<div className="card-wrapper container-sm d-flex  justify-content-around">
									<img src={foto1} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto2} className="img-fluid custom-img-fluid" alt="..." />
									<img src={foto3} className="img-fluid custom-img-fluid" alt="..." />
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-1 col-sm-12">
						<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next"> <span ><img className='carousel-control-next-custom' src={right} alt="" /></span>
						</button>
					</div>
				</div>
			</div>

			<div className="row px-lg-5 py-5">
				<div className="col-lg-4 col-md-12 col-sm-12 d-flex">
					<img src={flat} alt="" className='img-fluid m-3' />
					<p className='carasoul-heading'>Plots with Houses</p>
				</div>
				<div className="col-lg-4 col-md-12 col-sm-12 d-flex">
					<img src={meter} alt="" className='img-fluid m-3' />
					<p className='carasoul-heading'>2900 <span> &#13217; </span></p>
				</div>
				<div className="col-lg-4 col-md-12 col-sm-12 d-flex">
					<img src={pin} alt="" className='img-fluid m-3' />
					<p className='carasoul-heading'>Salem</p>
				</div>
			</div>

			<div className="row card-mortgage mb-5">
				<div className="col-md-6 col-sm-12 pt-3">
					<h6>Price:</h6>
					<h4>4500 Sq / ft</h4>
				</div>
				<div className="col-md-6 col-sm-6 text-center pt-4">
					<Link to={'/Login-page'}>
						<button className='btn btn-primary btn-mortgage'>Book Now</button>
					</Link>
				</div>
			</div>
		</>
	)
}
