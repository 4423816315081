import { SET_LOADING, SET_GLOBAL_LOADING } from '../type/types';

export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading
});


export const setGlobalLoading = (isGlobalLoading) => ({
    type: SET_GLOBAL_LOADING,
    payload: isGlobalLoading
});
