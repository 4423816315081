import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { TiArrowBack } from 'react-icons/ti';
import { IoMdAddCircle } from 'react-icons/io';
import { getDistrictData, getBindMemberSingleData, getBindLandTypeData, getBindAreaUnitData } from '../../../../store/action/BindAction';
import UploadFile from '../Others/UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import AddOwner from '../LandOwners/AddOwner';
import PickLocation from '../Others/PickLocation';
import { useLocation } from 'react-router-dom';
import { addLandSurvey } from '../../../../store/action/LandSurveyAction';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';

function AddLandSurvey2(props, { data }) {

    let history = useHistory();
    const dispatch = useDispatch();
    const selectedRfId = props?.location?.state?.selectedRfId

    const selector = useSelector(state => state?.BindReducer);

    const [sending, setSending] = useState(false);
    const [memberData, setMemberData] = useState([]);
    const [errors, setErrors] = useState({});
    const [districtData, setDistrictData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showAddLandOwnerModel, setShowAddLandOwnerModel] = useState(false);
    const [areaUnitData, setAreaUnitData] = useState([]);
    const [showMapModel, setShowMapModel] = useState(false);
    const [landTypeData, setLandTypeData] = useState([]);
    const [bodyData, setBodyData] = useState({
        "action": "Insert",
        "rfId": data?.rfId || "",
        "userRfId": data?.userRfId || "",
        "name": data?.name || "",
        "typeOfland": 1,
        'surveyArea': "",
        'surveyType': selectedRfId,
        "surveyAreaType": 1,
        "surveyNumber": "",
        "fmb": data?.fmb || "",
        "downloadDocument": "",
        "stateId": "",
        "districtId": "",
        "districtName": "",
        "talukId": "",
        "talukName": "",
        "villageId": "",
        "villageName": "",
        "address": "",
        "location": "",
        "pincode": "",
        "latitude": "",
        "longitude": "",
        "mailId": "",
        "inputSource": "Android",
        "loginUserId": "1",
        "remarks": ""
    })

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getDistrictData(1));
            await dispatch(getBindMemberSingleData());
            await dispatch(getBindLandTypeData())
            await dispatch(getBindAreaUnitData())
        }
        fetchData()
    }, []);

    useEffect(() => {
        if (selector?.districtData?.data?.alert == "District Details") {
            setDistrictData(selector?.districtData?.data?.data)
        }
        if (selector?.memberData?.data?.alert === "Real Estate Member Details") {
            let a = {}
            // setMemberData(selector?.memberData?.data?.data)
            setMemberData(selector?.memberData?.data?.data?.map((res, index) => {
                if (res.rfId == userRfId) {
                    a.label = res.combines;
                    a.value = res.rfId;
                    a.mobileNo = res.mobileNo;
                    a.rfId = res.rfId;
                }
                return {
                    label: res.combines,
                    value: res.rfId,
                    mobileNo: res.mobileNo,
                    rfId: res.rfId
                }
            }))
            data != "" && setSelectedOption(a)
        }
        if (selector?.landTypeData?.data?.alert === "Land Survey Type") {
            setLandTypeData(selector?.landTypeData?.data?.data)
        }
        if (selector?.areaUnitData?.data?.alert === "Land Area Type") {
            setAreaUnitData(selector?.areaUnitData?.data?.data)
        }

    }, [selector?.districtData?.data, selector?.memberData?.data, selector?.landTypeData?.data, selector?.areaUnitData?.data])

    const onChangeLocation = (villageFinderData, address, location, pincode, districtId) => {
        // console.log(`villageFinderData = ${JSON.stringify(villageFinderData)}`)
        setBodyData({
            ...bodyData,
            latitude: villageFinderData?.latitude,
            longitude: villageFinderData?.longitude,
            address: address,
            location: location,
            pincode: pincode,
            districtName: villageFinderData?.district_name,
            districtId: districtId,
            villageName: villageFinderData?.revenue_village_name,
            villageId: villageFinderData?.village_code,
            talukName: villageFinderData?.taluk_name,
            talukId: villageFinderData?.taluk_code,
            surveyNumber: villageFinderData?.survey_number?.processed_number
        });
    }

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const { rfId, userRfId, name, address, location, districtId, pincode, latitude, longitude, districtName, villageName, villageId, talukName, talukId, surveyArea, landAreaType, surveyNumber, surveyAreaType } = bodyData;

    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData.userRfId) {
            handleError('Select Landowner Name', 'userRfId');
            isValid = false;
        }

        if (!bodyData.name || bodyData.name.length < 3) {
            handleError('Name must be min 3 character long', 'name');
            isValid = false;
        }
        if (!bodyData.address) {
            handleError('Enter address', 'address');
            isValid = false;
        }

        if (!bodyData.location) {
            handleError('Enter location', 'location');
            isValid = false;
        }

        if (!bodyData.districtId) {
            setErrors(prevState => ({ ...prevState, districtId: 'Select district' }));
            isValid = false;
        } else {
            setErrors(prevState => ({ ...prevState, districtId: '' }));
        }

        if (
            !bodyData.pincode ||
            isNaN(bodyData.pincode) ||
            (bodyData.pincode.toString().length !== 6 ||
                bodyData.pincode.toString().includes('.'))
        ) {
            handleError('Enter Pincode', 'pincode');
            isValid = false;
        }

        if (!bodyData.surveyArea) {
            handleError('Enter Survey Area', 'surveyArea');
            isValid = false;
        }
        if (!bodyData.surveyAreaType) {
            handleError('Select Area Unit', 'surveyAreaType');
            isValid = false;
        }
        if (!bodyData.surveyNumber) {
            handleError('Select Survey Number', 'surveyNumber');
            isValid = false;
        }
        if (!bodyData.fmb) {
            handleError('Required', 'fmb');
            isValid = false;
        }

        if (isValid) {
            //  console.log(`addLandSurvey formData IS VALID =${JSON.stringify(bodyData)}`)
            handleAddFormSubmit();
        }
    }

    const handleAddFormSubmit = async () => {
        await dispatch(addLandSurvey(bodyData, history, setSending))
    }

    const onChangeDistrict = async (districtId) => {
        setBodyData({ ...bodyData, districtId: districtId });
    };

    const onChangeUpload = async (name, value) => {
        await setBodyData((prevState) => ({ ...prevState, [name]: value }))
        value.length > 0 && handleError("", name);
    }

    //console.log(`Boady Data = ${JSON.stringify(bodyData)}`)

    return (
        <>
            <div className='d-flex mt-2'>
                <Button className='p-0'><TiArrowBack onClick={() => history.goBack()} size={35} /></Button>
                <p className='listings-headingWithBack'>{bodyData?.rfId !== "" ? "Update Land Survey" : "Add Land Survey"}</p>
            </div>

            <form className="row g-3 property-form ml-2 mr-2 mt-3"
                noValidate
            >
                <div className="col-md-5">
                    {errors.userRfId ?
                        <label htmlFor="userRfId" className="error-text required">{errors.userRfId}</label> :
                        <label htmlFor="userRfId" className="text-black">Land Owner Name<span className="required">*</span> </label>
                    }
                    <Select
                        defaultValue={data?.userRfId == "" ? selectedOption : data?.userRfId}
                        onChange={(selectedOption) => setBodyData({ ...bodyData, userRfId: selectedOption.value })}
                        options={memberData}
                        onFocus={() => handleError(null, 'userRfId')}
                        className='Landowner-select-container'
                    />
                </div>

                <div className="col-md-1 btn-box" >
                    <Button className='btn btn-add' onClick={() => setShowAddLandOwnerModel(!showAddLandOwnerModel)}>
                        <IoMdAddCircle size={23} color='white' />
                    </Button>
                </div>
                <div className="col-md-6">
                    {errors.name ?
                        <label htmlFor="name" className="error-text required">{errors.name}</label> :
                        <label htmlFor="name" className="text-black">Land Name<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="name" name='name'
                        value={name}
                        onChange={e => onChange(e)}
                        placeholder="Enter Land Name"
                        noValidate
                        onFocus={() => handleError(null, 'name')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.address ?
                        <label htmlFor="address" className="error-text">{errors.address}</label> :
                        <label htmlFor="address" className="text-black">Address<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="address" name='address'
                        value={address}
                        onChange={e => onChange(e)}
                        placeholder="Enter Address"
                        noValidate
                        onFocus={() => handleError(null, 'address')}
                    />
                </div>
                <div className="col-md-2 btn-box">
                    <button type="button" className="btn btn-info btn-pickLocation" onClick={() => setShowMapModel(!showMapModel)}>
                        Pick Location
                    </button>
                </div>

                <div className="col-md-6">
                    {errors.location ?
                        <label htmlFor="location" className="error-text">{errors.location}</label> :
                        <label htmlFor="location" className="text-black">Location<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="location" name='location'
                        value={location}
                        onChange={e => onChange(e)}
                        placeholder="Location"
                        noValidate
                        onFocus={() => handleError(null, 'location')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.districtId ?
                        <label htmlFor="districtId" className="error-text required">{errors.districtId}</label> :
                        <label htmlFor="districtId" className="text-black">District<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="districtId" style={{ fontSize: '14px' }}
                        value={bodyData?.districtId}
                        onChange={e => onChangeDistrict(e.target.value)}
                        onFocus={() => handleError(null, 'districtId')}>
                        <option value=""> Select District </option>
                        {
                            districtData.length > 0 && districtData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.districtName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-2">
                    {errors.pincode ?
                        <label htmlFor="pincode" className="error-text">{errors.pincode}</label> :
                        <label htmlFor="pincode" className="text-black">Pincode<span className="required">*</span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="pincode" name='pincode'
                        value={bodyData?.pincode}
                        onInput={(e) => {
                            if (e.target.value.length > 6) {
                                e.target.value = e.target.value.slice(0, 6);
                            }
                        }}
                        onChange={e => onChange(e)}
                        placeholder="Enter Pincode"
                        noValidate
                        onFocus={() => handleError(null, 'pincode')}
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="typeOfland" className="text-black">Type of Property<span className="required">*</span> </label>
                    <select className="form-select" name="typeOfland"
                        value={bodyData?.typeOfland}
                        style={{ fontSize: 14 }}
                        onChange={e => onChange(e)}
                        onFocus={() => handleError(null, 'typeOfland')}>
                        <option value="">Type of Property</option>
                        {
                            landTypeData.length > 0 && landTypeData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.dataName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-4">
                    {errors.surveyArea ?
                        <label htmlFor="surveyArea" className="error-text">{errors.surveyArea}</label> :
                        <label htmlFor="surveyArea" className="text-black">Survey Area<span className="required">*</span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="surveyArea" name='surveyArea'
                        value={surveyArea}
                        onChange={e => onChange(e)}
                        placeholder="Enter Total Sales Area"
                        noValidate
                        onFocus={() => handleError(null, 'surveyArea')}
                    />
                </div>
                <div className="col-md-2">
                    {errors.surveyAreaType ?
                        <label htmlFor="surveyAreaType" className="error-text required">{errors.surveyAreaType}</label> :
                        <label htmlFor="surveyAreaType" className="text-black">Unit<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="surveyAreaType"
                        value={bodyData?.surveyAreaType}
                        onChange={e => onChange(e)}
                        onFocus={() => handleError(null, 'surveyAreaType')}>
                        <option value=""> Select Unit</option>
                        {
                            areaUnitData.length > 0 && areaUnitData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.dataName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-6">
                    {errors.surveyNumber ?
                        <label htmlFor="surveyNumber" className="error-text required">{errors.surveyNumber}</label> :
                        <label htmlFor="surveyNumber" className="text-black">Survey Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="surveyNumber" name='surveyNumber'
                        value={surveyNumber}
                        onChange={e => onChange(e)}
                        onInput={(e) => {
                            if (e.target.value.length > 4) {
                                e.target.value = e.target.value.slice(0, 4);
                            }
                        }}
                        placeholder="Enter Survey Number"
                        noValidate
                        onFocus={() => handleError(null, 'surveyNumber')}
                    />
                </div>
                <div className='upload-conatiner-postProperty'>
                    <UploadFile
                        fileOnSet={onChangeUpload}
                        name="fmb"
                        error={errors?.fmb}
                    >
                        Upload FMB
                    </UploadFile>
                </div>
                <Modal
                    show={showMapModel}
                    onHide={() => setShowMapModel(!showMapModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Google Map</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PickLocation onChangeLocation={onChangeLocation} districtData={districtData} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowMapModel(!showMapModel)}>
                            Confirm Location
                        </Button>
                        <Button variant="secondary" onClick={() => setShowMapModel(!showMapModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAddLandOwnerModel}
                    onHide={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Add Landowner</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddOwner setShowAddLandOwnerModel={setShowAddLandOwnerModel} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="col-md-12 text-center my-5">
                    <LoadingButton
                        loading={sending}
                        onClick={validate}
                        variant="contained"
                        sx={{ px: 6 }}
                        style={{ backgroundColor: '#3786FB', height: '45px', textTransform: 'none' }}
                        loadingIndicator={<CircularProgress size={15} sx={{ color: 'white' }} />}
                    >
                        {bodyData?.rfId !== "" ? "Update Land Survey" : "Add Land Survey"}
                    </LoadingButton>
                </div>
            </form>
        </>
    )
}

export default AddLandSurvey2