import { PAYMENT_REQUEST_SUCCESS, PAYMENT_REQUEST_FAIL } from "../type/types";

const initialState = {
    loading: true,
    paymentResponse: [],
    error: {}
}

export const PaymentReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentResponse: payload
            }
        case PAYMENT_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;

    }
}