import React from 'react';
import { useHistory } from 'react-router-dom';
import PropertyListTable from './PropertyListTable';

export default function Property() {

    return (
        <>
            <p className='listings-heading'>List of Property</p>
            <PropertyListTable />
        </>
    )
}

