import React from 'react';

const Terms = () => {

    return (<>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="jumbotron">
                        <h1> Terms and Conditions </h1>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Property QR serves as a platform medium to exchange information on plots, lands, houses,
                            buildings between various users depending on their needs.</p>
                        <h2>Definitions</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the purpose of these Terms, wherever the context so requires:
                            'Application' means the software program (My Property QR app) provided by the Company downloaded
                            by You on any electronic device.
                            'User' or 'you' or “your”, shall mean the person who visits, uses or registers on the Platform, including
                            the plot owners, plot buyers, land owners, land buyers, house owners, house buyers, building owners
                            and building buyers through the App. “Company” (referred to as either 'the Company', '', 'We', 'Us' or
                            'Our' in this Agreement) refers to My Property QR app.</p>
                        <h2>Acknowledgement</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your access to and use of the Service is conditioned on Your acceptance of and compliance
                            with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others
                            who access or use the Service. You represent that you are over the age of 18.
                            We may verify the information provided by you. By providing us with your information, you consent
                            to the verification of your information and sharing the same with a third-party service provider. You
                            are providing your consent to receive communications such as announcements, administrative messages
                            and advertisements from My Property QR/ or any of its partners, licensors or associates.</p>
                        <h2>Usage of My Property QR App</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Through the application, any person with a verified account can post the details of plots, lands,
                            houses and buildings, access and participate in the services provided by My Property QR. The My
                            Property QR App enables connections between clients and customers to the Seekers based on
                            information voluntarily provided by them.
                            We will identify the opportunities that, in our opinion, match with your credentials and we will present
                            such opportunities to you for your interest. Please note that, this shall in no matter be considered as an
                            offer or confirmation towards land/plot/house booking nor considered as blocking of the choices.</p>
                        <h2>User's Code of Conduct</h2>
                        <h2>Your Right to Post Content</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our Service allows us to post content. You are responsible for the Content that You post to the
                            Service, including its legality, reliability, and appropriateness.
                            While registering on the app or providing the information to us, you agree that: <br />
                            ➤ The information submitted by you should be true, accurate, current and complete information at the
                            time of registration and at all other times. <br />
                            ➤ You shall not print, download, duplicate or otherwise copy, delete, vary or amend or use any data or
                            personal information posted by any User except such data and information which are posted by yourself. <br />
                            ➤ My Property QR reserves the right to remove any or all such contents without further notice. <br />
                            ➤ You shall not use the Service for any unlawful purpose, including without limitation criminal
                            purposes. <br />
                            ➤ Your data including the contact information will be shared with the employer or its representative;</p>
                        <h2>Content Restrictions</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Company is not responsible for the content of the app's users. You expressly understand
                            and agree that You are solely responsible for the Content and for all activity that occurs under your
                            account, whether done so by You or any third person using Your account.
                            You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust,
                            threatening, libellous, defamatory, obscene or otherwise objectionable. Read our 'Content and
                            community guidelines' for detailed information.</p>
                        <h2>Intellectual Property Rights</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service and its original content (excluding Content provided by You or other users),
                            features and functionality are and will remain the exclusive property of the Company and its licensors.
                            The compilation of all content on the application is our exclusive property, and shall not be reproduced
                            or used without express written permission from us.</p>
                        <h2>Links to Other Websites</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our application may provide users, advertisers, affiliates, and sponsors with links to other Web
                            sites as well as access to third-party Content, products, and services. The Company is not responsible
                            for any third-party Content supplied on or via the application, as well as any changes or updates to such
                            third-party sites, and you accept all risks associated with accessing and using such Web sites and thirdparty Content, products, and services.</p>
                        <h2>Dispute and Resolution</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If any dispute between a User and My Property QR is found arising out of use of the app,
                            concerning the validity, interpretation, implementation or alleged breach of any provision of these
                            Terms, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third
                            party identified by My Property QR. The Decision of the arbitrator shall be final and binding on both
                            the parties to the dispute.</p>
                        <h2>Termination</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may terminate or suspend Your Account immediately, without prior notice or liability, for
                            any reason whatsoever, including without limitation if you breach these Terms and Conditions.
                            Upon termination, your right to use the Service will cease immediately. If You wish to terminate Your
                            Account, you may simply discontinue using the Service.</p>
                        <h2>Limitation of Liability</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Property QR disclaims all liability for any technical malfunction of the app, including but
                            not limited to failure of any service, servers or providers, mobile phone equipment, software, failure of
                            email delivery (including attachments), account login, as a result of technical problems or traffic
                            congestion on the internet or any third party website or combination thereof, including injury or damage
                            to your or to any other person’s mobile device or other hardware or software, related to or resulting
                            from using or downloading any information in connection with the Platform.</p>
                        <h2>Changes to These Terms and Conditions</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. By
                            continuing to access or use Our Service after those revisions become effective, You agree to be bound
                            by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the
                            website and the Service.</p>
                    </div>

                </div>
            </div>
        </div>
    </>)

}

export default Terms;