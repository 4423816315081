import React from 'react';
import StaffListTable from './StaffListTable';

export default function Staff() {

    return (
        <>
            <p className='listings-heading'>List of Staff</p>
            <StaffListTable />
        </>
    )
}

