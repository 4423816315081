import * as React from 'react';
import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuIcon from '@mui/icons-material/Menu';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SearchIcon from '@mui/icons-material/Search';
import { default as Box } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { green } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import { default as IconButton } from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { default as Typography } from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';

function createData(place, invoiceid, date, time, order, amount, status) {
    return {
        place,
        invoiceid,
        date,
        time,
        order,
        amount,
        status,
    };
}

const rows = [
    createData('Plot 23', 'Inv: MGL524874', '14 Apr 2022', 'at 8:00 PM', '20', '₹420.80', 'Pending'),
    createData('Plot 203', 'Inv: MGL524250', '12 Apr 2022', 'at 8:00 PM', '01', '₹244.80', 'Paid'),
    createData('Plot 423', 'Inv: MGL524874', '12 Apr 2022', 'at 9:00 AM', '02', '₹200.00', 'Unpaid'),
    createData('Plot 10', 'Inv: MGL524140', '24 Mar 2022', 'at 8:00 PM', '01', '₹84.00', 'Paid'),
    createData('Plot 6', 'Inv: MGL524245', '10 Mar 2022', 'at 8:00 PM', 'Withdraw', '₹420.84', 'Pending'),
    createData('Plot 50', 'Inv: MGL524254', '08 Mar 2022', 'at 8:00 PM', 'Technology', '₹400.11', 'Pending'),
    createData('Plot 5', 'Inv: MGL524487', '02 Mar 2022', 'at 7:00 PM', '01', '₹420.84', 'Paid'),
    createData('Plot 236', 'Inv: MGL524598', '01 Mar 2022', 'at 8:00 PM', 'Withdraw', '₹510.10', 'Paid')
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'place',
        numeric: false,
        disablePadding: true,
        label: 'Place',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'order',
        numeric: true,
        disablePadding: false,
        label: 'Order',
    },
    {
        id: 'amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'Viewbtn',
        numeric: true,
        disablePadding: false,
        label: 'Action',
    },
];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid lightgrey',
        borderRadius: '5px',
        height: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function PaymentDataTableHead(props) {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

PaymentDataTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function PaymentDataTableToolbar(props, addListing) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <>
                    <Typography sx={{ flexGrow: 2 }}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase placeholder="Search…" />
                        </Search>
                    </Typography>
                    <Tooltip title="Add Listing">
                        <IconButton onClick={(addListing) => addListing(true, "Add List")}>
                            <PlaylistAddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Toolbar>
    );
}

PaymentDataTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function PaymentDataTable() {
    //Model related States
    const [open, setOpen] = useState(false);

    const handleOpen = (modelLayoutData) => {
        setModelData(modelLayoutData)
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
    const [modelData, setModelData] = useState({})

    //Table Related States
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('order');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.place);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, place) => {
        const selectedIndex = selected.indexOf(place);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, place);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (place) => selected.indexOf(place) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <PaymentDataTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                    >
                        <PaymentDataTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.place);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.place)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.place}
                                            selected={isItemSelected}
                                            className='DataTable-row'

                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>


                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.place} <br /> {row.invoiceid}
                                            </TableCell>
                                            <TableCell align="center">{row.date} <br /> {row.time}</TableCell>
                                            <TableCell align="center">{row.order}</TableCell>
                                            <TableCell align="center">{row.amount}</TableCell>

                                            <TableCell align="center">
                                                <Button className={`land-paymentstatus ${row.status}`}>
                                                    {row.status}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center"> <Button variant="contained" color="error" size="small" style={{ textTransform: 'none' }} onClick={() => handleOpen(row)} >View</Button></TableCell>
                                        </TableRow>
                                    );
                                })}

                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {/* Popup Model starts for Site Details */}

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box className="modelStyle">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Site Details
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>
                            <ul>
                                <li> <b>Site Area:</b>{modelData?.invoiceid}</li>
                                <li> <b>Site Name:</b>{modelData?.place}</li>
                                <li> <b>Square Feet:</b>{modelData?.order}</li>
                                <li> <b>Amount:</b>{modelData?.amount}</li>
                                <li> <b>Date:</b>{modelData?.date}</li>
                                <li> <b>Time:</b>{modelData?.time}</li>
                                <li> <b>Action Detail:</b> {modelData?.status}</li>
                            </ul>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
}
