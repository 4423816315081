import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdUpload } from "react-icons/md";
import axios from 'axios';
import { Url } from "../../../../utils/Common";
import Loader1 from '../../../../utils/Loader1';

export default function UploadImage({ fileOnSet, children, name, error, editData }) {

    const [selectedFiles, setSelectedFiles] = useState(editData == "null" || editData == null ? [] : editData);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [imageArray, setImageArray] = useState([])

    const onSelectFile = async (event) => {
        const pickedFiles = Array.from(event.target.files);
        const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
        const validFiles = pickedFiles.filter(file => allowedTypes.includes(file.type));

        if (validFiles.length > 0) {
            setIsLoading(true);

            try {
                const slicedImagePaths = validFiles.slice(0, 3);

                const uploadedImages = await Promise.all(
                    slicedImagePaths.map(async (imagePath) => {

                        let formData = new FormData();
                        formData.append('files', imagePath);

                        await await axios.post(`${Url}fileupload`, formData, {
                            headers: {
                                Accept: '*/*',
                                'Content-type': 'multipart/form-data',
                            },
                        }).then(async (response) => {
                            if (imageArray?.length >= 3) {
                                imageArray?.length > 0 && imageArray.shift()
                            }
                            imageArray.push(response?.data?.data[0]);
                            return response?.data?.data[0];

                        }).catch((err) => {
                            console.log(err)
                        })
                    })
                );
                await Promise.all(uploadedImages);
                console.log(`imageArray = ${JSON.stringify(imageArray)}`)
                await setSelectedFiles(imageArray)
                await fileOnSet(name, imageArray);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        }
    };

    function deleteHandler(index) {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setUploadedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    }

    return (
        <div className="uploadimage uploadfile">
            <section className="uploadimage-container">
                <label>
                    {isLoading ? <Loader1 /> : <MdUpload color="#3786FB" size="5rem" />}
                    <span className="li-addlistings-details">{children}</span>
                    <span className="li-addlistings-notes">You can upload up to 3 image files</span>
                    <input
                        id="fileInput"
                        type="file"
                        name="file"
                        onChange={onSelectFile}
                        accept=".jpeg,.jpg,.png,.webp"
                        multiple
                        disabled={selectedFiles.length >= 3}
                    />
                </label>
                <div className="d-flex flex-row justify-content-center">
                    {selectedFiles.map((file, index) => (
                        <div key={index} className="image">
                            <AiFillCloseCircle color="red" onClick={() => deleteHandler(index)} className="close-icon" />
                            <img src={file} height="100" width="100" alt={`upload-${index}`} />
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}
