import { combineReducers } from 'redux';
import { LoginReducer } from './LoginReducer';
import { StaffReducer } from './StaffReducer';
import { BindReducer } from './BindReducer';
import { LayoutReducer } from './LayoutReducer';
import { AuthReducer } from './AuthReducer';
import { MemberReducer } from './MemberReducer';
import { SiteReducer } from './SiteReducer';
import { PermissionReducer } from './PermissionReducer';
import { DashboardReducer } from './DashboardReducer';
import { BuildingApprovalReducer } from './BuildingApprovalReducer';
import { NeedPropertyReducer } from './NeedPropertyReducer';
import { LegalOpinionReducer } from './LegalOpinionReducer';
import { LandSurveyReducer } from './LandSurveyReducer';
import { VillageFinderReducer } from './VillageFinderReducer';
import { PaymentReducer } from './PaymentReducer';
import { FmbPattaReducer } from './FmbPattaReducer';
import { LoadingReducer } from './LoadingReducer';
import { BookingsReducer } from './BookingsReducer';
import { CroEntryReducer } from './CroEntryReducer';
import { VastuPlanReducer } from './VastuPlanReducer';
import { GlobalSearchReducer } from './GlobalSearchReducer';

const rootReducers = combineReducers({
	LoginReducer, StaffReducer, BindReducer, LayoutReducer, AuthReducer, MemberReducer, SiteReducer, PermissionReducer, DashboardReducer, BuildingApprovalReducer, NeedPropertyReducer, LegalOpinionReducer, LandSurveyReducer, VillageFinderReducer, PaymentReducer, FmbPattaReducer, LoadingReducer, BookingsReducer, CroEntryReducer, VastuPlanReducer, GlobalSearchReducer
});
export default rootReducers;

