import axios from 'axios';
import swal from "sweetalert";
import { Url, getTokenLocalStorage, getTokenLocalUserStorage } from '../../utils/Common';
import { BUILDING_APPROVAL_SUCCESS, BUILDING_APPROVAL_FAIL, BUILDING_APPROVAL_VIEW, BUILDING_APPROVAL_INSERT, BUILDING_APPROVAL_UPDATE, BUILDING_APPROVAL_DELETE, BUILDING_APPROVAL_FILE_DOWNLOAD_SUCCESS } from "../type/types";
import { setLoading } from './LoadingAction';

// View Building Approval
export const getBuildingApproval = (userRfId = "", rfId = 0, limit = 0) => async dispatch => {
    dispatch(setLoading(true));
    const token = getTokenLocalStorage();
    const formData = {
        "action": "View",
        "rfId": rfId == 0 ? "" : rfId,
        "userRfId": userRfId > 0 && userRfId != undefined ? userRfId : "",
        "order": "desc",
        "fromDate": "",
        "toDate": "",
        "search": "",
        "limit": limit == 0 ? "" : limit,
    };
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}property`, formData, { headers }).then((response) => {
        //  console.log(`getBuildingApproval response = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch(setLoading(false));
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_VIEW
            })
        }
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch({
            payload: err.response,
            type: BUILDING_APPROVAL_FAIL
        })
    })
}

export const addBuildingApproval = (formData, history, setSending) => async dispatch => {

    //console.log(`addBuildingApproval formData = ${JSON.stringify(formData)}`)
    setSending(true);
    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }

    axios.post(`${Url}property`, formData, { headers }).then((response) => {
        // console.log(`saveMarketingStaff = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message === "Success") {
            swal("Success", `Building Approval is added successfully!`, "success");
            history.goBack();
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_INSERT
            });
            setSending(false);
        } else {
            history.goBack();
            swal("Fail", `Building Approval is not added, Please try again`, "error");
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_FAIL
            });
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BUILDING_APPROVAL_FAIL
        });
        setSending(false);
    })
}

export const deleteBuildingApproval = (rfId, userRfId) => async dispatch => {

    //console.log(`addBuildingApproval formData = ${JSON.stringify(formData)}`)

    const token = getTokenLocalStorage();
    const headers = { "x-auth-header": `${token}` }
    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    const formData = {
        "action": "Delete",
        "rfId": rfId,
        "userRfId": userRfId,
        "loginUserId": loginUserId
    };
    axios.post(`${Url}property`, formData, { headers }).then((response) => {
        //console.log(`deleteBuildingApproval response = ${JSON.stringify(response?.data)}`)
        if (response?.data?.message == 'Success') {
            swal("Success", `Building Approval is deleted successfully!`, "success");
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_DELETE
            })
        } else {
            swal("Fail", `Building Approval is not deleted, Please try again`, "error");
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_FAIL
            });
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BUILDING_APPROVAL_FAIL
        })
    })
}

// Download File Url
export const getBuildingApprovalZip = (rfId) => async dispatch => {
    const token = getTokenLocalStorage();
    let formData = {
        "action": "Download",
        "rfId": rfId
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}property`, formData, { headers }).then((response) => {
        // console.log(`getBuildingApprovalZip response = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            swal("Success", `File is downloaded successfully...!`, "success");
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_FILE_DOWNLOAD_SUCCESS
            });
            dispatch(getBuildingApproval())
        } else {
            swal("Fail", `Couldn't able to download the file, Please try again`, "error");
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_FAIL
            });
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BUILDING_APPROVAL_FAIL
        })
    })
}

// Status Update
export const updateBuildingApprovalList = (itemRfId, statusRfId, status, approvalType, setSending) => async dispatch => {

    setSending(true);
    const token = getTokenLocalStorage();
    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Update",
        "propertyRfId": itemRfId,
        "statusRfId": statusRfId,
        "propertyStatus": status == true ? 2 : 1,
        "approvalType": approvalType,
        "loginUserId": loginUserId
    }
    const headers = { "x-auth-header": `${token}` }
    axios.post(`${Url}property`, formData, { headers }).then((response) => {
        //console.log(`updateBuildingApprovalList response = ${JSON.stringify(response)}`)
        if (response?.data?.message == 'Success') {
            swal("Success", `Status is updated successfully!`, "success");
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_UPDATE
            });
            setSending(false);
            getBuildingApproval();
        } else {
            swal("Fail", `Status is not updated, Please try again`, "error");
            dispatch({
                payload: response,
                type: BUILDING_APPROVAL_FAIL
            });
            setSending(false);
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BUILDING_APPROVAL_FAIL
        });
        setSending(false);
    })
}


// Building Approval Show / Hide Status update
export const showBuildingApprovalStatus = (rfId, userRfId, visibleStatus) => async dispatch => {

    const loginUserData = await getTokenLocalUserStorage("");
    const loginUserId = loginUserData[0].rfId;

    let formData = {
        "action": "Show",
        "rfId": rfId,
        "visibleStatus": visibleStatus == 1 ? 0 : 1,
        "userRfId": userRfId,
        "loginUserId": loginUserId
    }

    await axios.post(`${Url}property`, formData).then((response) => {
        //  console.log(`site data response - ${JSON.stringify(response)}`)
        if (response?.data?.message == "Success") {
            swal("Status Changed!", "The selected Building Approval status is changed successfully!", "success");
        } else {
            swal("Fail", `The selected Building Approval is status is not updated!, Please try again`, "error");
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: BUILDING_APPROVAL_FAIL
        })
    })
}

