import { BIND_SUCCESS, BIND_FAIL, BIND_MEMBER_VIEW, BIND_DISTRICT_SUCCESS, BIND_SUBZONE_SUCCESS, BIND_DTDCDATA_SUCCESS, BIND_LANDTYPE_SUCCESS, BIND_AREAUNIT_SUCCESS, BIND_RADIUS_SUCCESS, BIND_FOCUS_TYPE_SUCCESS, BIND_PURPOSE_TYPE_SUCCESS, BIND_PAYMENT_TYPE_SUCCESS, BIND_MARRIED_STATUS_SUCCESS, BIND_JOB_SECTOR_SUCCESS, BIND_CURRENT_STATUS_SUCCESS, BIND_PROPERTY_AREA_REQUIRED_SUCCESS, BIND_CHILDREN_SUCCESS, BIND_EMI_COMMITMENTS_SUCCESS, BIND_LIVING_SPACE_SUCCESS, BIND_NEAR_BY_SERVICES_SUCCESS, BIND_CONSULTING_FEES_SUCCESS } from "../type/types";

const initialState = {
    loading: true,
    payload: [],
    error: {},
    memberData: [],
    districtData: [],
    subZoneData: [],
    dtdcApprovalData: [],
    landTypeData: [],
    areaUnitData: [],
    propertyRadiusData: [],
    propertyAreaRequired: [],
    focusTypeData: [],
    typeOfPurposeData: [],
    typeOfPaymentData: [],
    marriedStatusData: [],
    jobSectorData: [],
    currentStatusData: [],
    numOfChildren: [],
    emiCommitments: [],
    livingSpace: [],
    nearByServices: [],
    consultingFees: [],


}

export const BindReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case BIND_DISTRICT_SUCCESS:
            return {
                ...state,
                loading: false,
                districtData: payload
            }
        case BIND_SUBZONE_SUCCESS:
            return {
                ...state,
                loading: false,
                subZoneData: payload
            }
        case BIND_DTDCDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                dtdcApprovalData: payload
            }
        case BIND_MEMBER_VIEW:
            return {
                ...state,
                loading: false,
                memberData: payload
            }
        case BIND_LANDTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                landTypeData: payload
            }
        case BIND_AREAUNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                areaUnitData: payload
            }
        case BIND_RADIUS_SUCCESS:
            return {
                ...state,
                loading: false,
                propertyRadiusData: payload
            }
        case BIND_PROPERTY_AREA_REQUIRED_SUCCESS:
            return {
                ...state,
                loading: false,
                propertyAreaRequired: payload
            }
        case BIND_FOCUS_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                focusTypeData: payload
            }
        case BIND_PURPOSE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                typeOfPurposeData: payload
            }
        case BIND_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                typeOfPaymentData: payload
            }
        case BIND_MARRIED_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                marriedStatusData: payload
            }
        case BIND_JOB_SECTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                jobSectorData: payload
            }
        case BIND_CURRENT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                currentStatusData: payload
            }
        case BIND_CHILDREN_SUCCESS:
            return {
                ...state,
                loading: false,
                numOfChildren: payload
            }
        case BIND_EMI_COMMITMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                emiCommitments: payload
            }
        case BIND_LIVING_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                livingSpace: payload
            }
        case BIND_NEAR_BY_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                nearByServices: payload
            }
        case BIND_CONSULTING_FEES_SUCCESS:
            return {
                ...state,
                loading: false,
                consultingFees: payload
            }

        case BIND_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
}