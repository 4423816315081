import { PERMISSION_VIEW, PERMISSION_FAIL, PERMISSION_SUCCESS  } from  "../type/types";

const initialState = {       
    loading : true,
    payload : [],
    error : {}
}
export const PermissionReducer = ( state = initialState , action ) =>{
    const {type , payload} = action;
    switch(type){
        case PERMISSION_SUCCESS :
            return{
                ...state,               
                loading:false,
                payload:payload
            }      
        case PERMISSION_FAIL :
            return{
                ...state,               
                loading:false,
                error:payload
            }      
            default:
                return state;
    }
}