import React from 'react';
import FmbPattaListTable from './FmbPattaListTable'

const Fmb_Patta = () => {
    return (
        <>
            <p className='listings-heading'></p>
            <FmbPattaListTable />
        </>
    )
}

export default Fmb_Patta
