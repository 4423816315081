import React from 'react';
import LegalOpinionListTable from './LegalOpinionListTable'

const LegalOpinion = () => {
    return (
        <>
            <p className='listings-heading'>Legal Opinion</p>
            <LegalOpinionListTable />
        </>
    )
}

export default LegalOpinion