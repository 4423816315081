import React from 'react';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';
import ExpertiseAddAgent from './ExpertiseAddAgent';

const ExpertiseAgent = () => {

    return (
        <>
            <div className="page-wraper bg-white">
                <Header />
                <div className='mx-5 mt-4'>
                    {/* <p className='listings-heading mt-3 mb-4 ml-3'>Agent</p> */}
                    {/* <AgentListTable expertiseAgent={true} /> */}
                    <ExpertiseAddAgent />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ExpertiseAgent