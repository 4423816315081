import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
var bnr7 = require('./../../images/banner/bnr7.png');
var bnr8 = require('./../../images/banner/bnr8.png');
var bnr9 = require('./../../images/banner/bnr9.png');


export default function CustomerReview() {

	return (
		<div className='container-fluid' >
			<div className="section-head text-center">
				<h2 className=''>What People Say About Us</h2>
			</div>
			<Carousel>
				<Carousel.Item>
					<div className="carousel-item active">
						<div className="container">
							<div className="row">
								<div className="col-lg-3" />
								<div className="col-lg-3">
									<div className='image-container-carsoul'>
										<img src={bnr7} className="d-block img-fluid carasoul-bg-2" alt="Banner-1" width={250} />
									</div>
								</div>
								<div className="col-lg-4 col-sm-12 carasoul-text ">
									<h3 className='mt-lg-5'>Rajesh P</h3>
									<p style={{ fontSize: '15px' }}>I found my dream home through [My Property QR] in just a few days. The detailed listings and high-quality images made it easy to narrow down my choices. The buying process was seamless, and I couldn't be happier with my new home!</p>
									<p className='mb-5' style={{ fontSize: '12px' }}>Home Buyer, Coimbatore</p>
								</div>
							</div>
							<div className="col-lg-3"></div>
						</div>
					</div>
				</Carousel.Item  >
				<Carousel.Item>
					<div className="carousel-item active">
						<div className="container">
							<div className="row">
								<div className="col-lg-3"></div>
								<div className="col-lg-3">
									<div className='image-container-carsoul'>
										<img src={bnr8} className="d-block img-fluid carasoul-bg-2" alt="Banner-1" width={250} />
									</div>
								</div>
								<div className="col-lg-4 carasoul-text">
									<h3 className='mt-lg-5'>John</h3>
									<p style={{ fontSize: '15px' }}>As a first-time homebuyer, I was nervous about the whole process, but [My Property Qr] made it incredibly easy. The user-friendly interface and comprehensive property information were invaluable. The customer support team was also very responsive and helpful throughout.</p>
									<p className='mb-5' style={{ fontSize: '12px' }}>Home Buyer, Erode</p>
								</div>
							</div>
							<div className="col-lg-3"></div>
						</div>
					</div>
				</Carousel.Item  >
				<Carousel.Item>
					<div className="carousel-item active">
						<div className="container">
							<div className="row">
								<div className="col-lg-3"></div>
								<div className="col-lg-3">
									<div className='image-container-carsoul'>
										<img src={bnr9} className="d-block img-fluid carasoul-bg-2" alt="Banner-1" width={250} />
									</div>
								</div>
								<div className="col-lg-4 carasoul-text">
									<h3 className='mt-lg-5'>Karthik Raja</h3>
									<p style={{ fontSize: '15px' }}>Listing my property on [My Property Qr] was a breeze. The platform's reach ensured that I received inquiries almost immediately, and I was able to sell my plot much faster than I expected. The visibility and ease of use make this site a top choice for property owners.</p>
									<p className='mb-5' style={{ fontSize: '12px' }}>Home seller, Chennai</p>
								</div>
							</div>
							<div className="col-lg-3"></div>
						</div>
					</div>
				</Carousel.Item  >
			</Carousel>
		</div>
	)
}
