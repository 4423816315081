import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Usericon from '../../images/header/Male_User.png';
import AppLogo from '../../images/website-logo.png';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const Header = () => {

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const toggleSidebar = (e) => {
		e.preventDefault();
		setIsSidebarOpen(!isSidebarOpen);
	};

	useEffect(() => {
		const navUl = Array.from(document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a'));
		const checkLi = (current) => {
			current.parentElement.parentElement.querySelectorAll("li").forEach(el =>
				(current.parentElement !== el) ? el.classList.remove('open') : ''
			);
			setTimeout(() => {
				current.parentElement.classList.toggle('open');
			}, 100);
		};

		navUl.forEach(navItem => {
			navItem.addEventListener('click', function () {
				checkLi(this);
			});
		});

		return () => {
			navUl.forEach(navItem => {
				navItem.removeEventListener('click', function () {
					checkLi(this);
				});
			});
		};
	}, []);

	useEffect(() => {
		const sidebarmenu = document.querySelector('.myNavbar');
		if (isSidebarOpen) {
			sidebarmenu.classList.add('show');
		} else {
			sidebarmenu.classList.remove('show');
		}
	}, [isSidebarOpen]);

	return (
		<>
			<header className="site-header mo-left header fullwidth">
				<div className="sticky-header main-bar-wraper navbar-expand-lg">
					<div className="main-bar clearfix">
						<div className="container clearfix">
							<div className="logo-header mostion">
								<Link to={"/"}>
									<img src={AppLogo} alt="My Property" className='appicon' />
								</Link>
							</div>
							<button className="navbar-toggler collapsed navicon justify-content-end" type="button" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleSidebar}>
								<span></span>
								<span></span>
								<span></span>
							</button>
							<div className={`header-nav navbar-collapse collapse myNavbar justify-content-end ${isSidebarOpen ? 'show' : ''}`} id="navbarNavDropdown">
								<div className="logo-header mostion d-md-block d-lg-none">
									<Link to={'/'} className="dez-page">
										<img src={AppLogo} alt="My Property" className='appicon' />
									</Link>
								</div>
								<div className='navbar-nav-con'>
									<ul className="nav navbar-nav">
										<li className='header-list'>
											<Link to={'/home'}>Home</Link>
										</li>
										<li>
											<Link to={'/Buyers'}>Buy or Sell</Link>
										</li>
										<li>
											<Link to={'/about-us'}>About Us</Link>
										</li>
										<li>
											<Link to={'/contact'}>Contact Us</Link>
										</li>
										<li className="dropdown header-list">
											<Link to="#" className="dropdown-toggle" data-toggle="dropdown">Registration</Link>
											<ul className="dropdown-menu">
												<li><Link to={{ pathname: '/ExpertiseAgent' }}>Agent</Link></li>
												<li><Link >Surveyor</Link></li>
												<li><Link to={{ pathname: '/ExpertiseAddConsultant' }}>DTCP Consultant</Link></li>
												<li><Link to={{ pathname: '/ExpertiseAddWriter' }}>Doc Writer</Link></li>
											</ul>
										</li>
									</ul>
								</div>
								<div>
									<Link to={"/about-us"}>
										<img src={Usericon} alt="user" className='usericon' />
									</Link>
								</div>
								<div className='btn-post-property'>
									<Link to={'/Login-page'}>
										<button className='btn btn-primary px-4'>Login</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
