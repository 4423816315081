import React from 'react';
import BuildingApprovalListTable from './BuildingApprovalListTable'

const BuildingApproval = () => {
    return (
        <>
            <p className='listings-heading'>Building Approval</p>
            <BuildingApprovalListTable />
        </>
    )
}

export default BuildingApproval