import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { FaBars, FaRegWindowClose, FaUserCircle, FaUserTie, FaTh, } from "react-icons/fa";
import { IoIosPeople, IoMdSettings } from 'react-icons/io';
import { MdPayments } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../../store/action/AuthActions";
import { getTokenLocalUserStorage } from '../../../utils/Common';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import AppLogo from '../../../images/website-logo.png';
import BuildingApproval from '../../../images/Services/buildingapproval.png';
import finder1 from '../../../images/Services/finder1.png';
import propertyNeed from '../../../images/Services/propertyNeed.png';
import legalOpinion from '../../../images/Services/notaryClearance.png';
import landSurvey from '../../../images/Services/survey.png';
import Property from '../../../images/Services/Property.png';
import agent from '../../../images/Services/agent.png';
import consultation from '../../../images/Services/consultation.png';
import docWriter from '../../../images/Services/docWriter.png';
import fmbPatta from '../../../images/Services/fmbPatta.png';
import map from '../../../images/Services/map.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdLibraryBooks } from "react-icons/md";
import { getDashboardData } from '../../../store/action/DashboardAction';
import VastuPlan from '../../../images/Services/vastuPlan.png'
import { permissionCheck } from '../../../utils/Common';
import Loader1 from '../../../utils/Loader1';
import { getMemberData } from '../../../store/action/MemberAction';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import AlarmIcon from '@mui/icons-material/Alarm';
import { getGlobalSearchData, emptyGlobalSearchData } from '../../../store/action/GlobalSearchAction';

const AdminHomeScreen = ({ children, location }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.DashboardReducer);
    const searchSelector = useSelector(state => state?.GlobalSearchReducer);
    const memberSelector = useSelector(state => state?.MemberReducer);

    const [searchData, setSearchData] = useState({
        userName: "",
        userMobileNo: ""
    });
    const [globalSearch, setGlobalSearch] = useState(false);
    const [onSearchTxt, setOnSearchTxt] = useState("");
    const [globalSearchData, setGlobalSearchData] = useState([]);
    const [dashBoardData, setDashboardData] = useState([]);
    const [permission, setPermission] = useState({
        propertyView: [],
        staffView: [],
        landOwnerView: [],
        bookingPageView: [],
        iNeedPropertyView: [],
        landSurveyView: [],
        legalOpinionView: [],
        buildingApprovalView: [],
        vastuPlanView: [],
        documentWriterView: [],
        agentView: [],
        consultantView: [],
        fmbPattaView: [],
        consultingChargeView: [],
    })
    const [isOpen, setIsOpen] = useState(false);

    const loading = globalSearch ? searchSelector.loading : false;

    useEffect(() => {
        dispatch(getDashboardData())
    }, [])

    // console.log(`LandOwner Rfid - ${JSON.stringify(searchUserRfId)}`)

    useEffect(() => {
        const fetchPermissionData = async () => {
            let Permission = await getTokenLocalUserStorage("permission");
            let registerType = await getTokenLocalUserStorage("registerType");

            let propertyView = await permissionCheck(Permission, 1006);
            let staffView = await permissionCheck(Permission, 1002);
            let landOwnerView = await permissionCheck(Permission, 1010);
            let bookingPageView = await permissionCheck(Permission, 1013);
            let iNeedPropertyView = await permissionCheck(Permission, 1015);
            let landSurveyView = await permissionCheck(Permission, 1020);
            let legalOpinionView = await permissionCheck(Permission, 1026);
            let buildingApprovalView = await permissionCheck(Permission, 1034);
            let vastuPlanView = await permissionCheck(Permission, 1040);
            let documentWriterView = await permissionCheck(Permission, 1046);
            let agentView = await permissionCheck(Permission, 1050);
            let consultantView = await permissionCheck(Permission, 1054);
            let fmbPattaView = await permissionCheck(Permission, 1060);
            let consultingChargeView = await permissionCheck(Permission, 1058);

            setPermission({
                ...permission,
                propertyView: registerType === 1 ? ["0000"] : propertyView,
                staffView: registerType === 1 ? ["0000"] : staffView,
                landOwnerView: registerType === 1 ? ["0000"] : landOwnerView,
                bookingPageView: registerType === 1 ? ["0000"] : bookingPageView,
                iNeedPropertyView: registerType === 1 ? ["0000"] : iNeedPropertyView,
                landSurveyView: registerType === 1 ? ["0000"] : landSurveyView,
                legalOpinionView: registerType === 1 ? ["0000"] : legalOpinionView,
                buildingApprovalView: registerType === 1 ? ["0000"] : buildingApprovalView,
                vastuPlanView: registerType === 1 ? ["0000"] : vastuPlanView,
                documentWriterView: registerType === 1 ? ["0000"] : documentWriterView,
                agentView: registerType === 1 ? ["0000"] : agentView,
                consultantView: registerType === 1 ? ["0000"] : consultantView,
                fmbPattaView: registerType === 1 ? ["0000"] : fmbPattaView,
                consultingChargeView: registerType === 1 ? ["0000"] : consultingChargeView,
            })
        };
        fetchPermissionData();
    }, [])

    //console.log(`Permission = ${JSON.stringify(permission)}`)

    useEffect(() => {
        if (selector?.payload?.data?.alert === "Real Estate Dashboard Details") {
            setDashboardData(selector?.payload?.data?.data)
        }
        if (searchSelector?.viewSearchData?.data?.alert === "Real Estate Dashboard Details") {
            setGlobalSearchData(searchSelector?.viewSearchData?.data?.data)
        }
        if (memberSelector?.payload?.data?.alert === "Real Estate Member Details") {
            setSearchData((prevState) => ({
                ...prevState,
                userName: memberSelector?.payload?.data?.data[0]?.userName || "",
                userMobileNo: memberSelector?.payload?.data?.data[0]?.mobileNo || ""
            }));
        }
    }, [selector?.payload?.data, searchSelector?.viewSearchData?.data, memberSelector?.payload?.data]);

    const toggle = () => setIsOpen(!isOpen);

    const menuItem1 = [
        {
            path: "/Dashboard",
            name: "Dashboard",
            icon: <FaTh />,
        },
        permission?.bookingPageView?.length > 0 && {
            path: "/BookingsHome",
            name: "Bookings",
            icon: <MdLibraryBooks size={25} />
        },
        permission?.landOwnerView?.length > 0 && {
            path: "/LandOwners",
            name: "LandOwners",
            icon: <FaUserTie size={22} />
        },
        permission?.staffView?.length > 0 && {
            path: "/Staff",
            name: "Staff",
            icon: <IoIosPeople size={27} />
        },
        permission?.consultingChargeView?.length > 0 && {
            path: "/ConsultingFees",
            name: "Consulting Fees",
            icon: <MdPayments size={24} />
        },
        {
            path: "/Settings",
            name: "Settings",
            icon: <IoMdSettings size={25} />
        }
    ].filter(Boolean);

    const menuItem2 = [
        permission?.propertyView?.length > 0 && {
            path: "/Property",
            name: "Property",
            icon: Property,
            count: globalSearch && globalSearchData?.length > 0 ?
                globalSearchData[0]?.plotsCount + globalSearchData[0]?.landCount + globalSearchData[0]?.houseCount :
                dashBoardData[0]?.plotsCount + dashBoardData[0]?.landCount + dashBoardData[0]?.houseCount
        },
        permission?.iNeedPropertyView?.length > 0 && {
            path: "/NeedProperty",
            name: "I Need Property",
            icon: propertyNeed,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.needProperty : dashBoardData[0]?.needProperty
        },
        permission?.landSurveyView?.length > 0 && {
            path: "/LandSurvey",
            name: "Land Survey",
            icon: landSurvey,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.landSurvey : dashBoardData[0]?.landSurvey
        },
        permission?.legalOpinionView?.length > 0 && {
            path: "/LegalOpinion",
            name: "Legal Opinion",
            icon: legalOpinion,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.legalOpinion : dashBoardData[0]?.legalOpinion
        },
        permission?.fmbPattaView?.length > 0 && {
            path: "/Fmb_Patta",
            name: "FMB & Patta",
            icon: fmbPatta,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.pattaFmb : dashBoardData[0]?.pattaFmb
        },
        permission?.buildingApprovalView?.length > 0 && {
            path: "/BuildingApproval",
            name: "Building Approval",
            icon: BuildingApproval,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.buildingApproval : dashBoardData[0]?.buildingApproval
        },
        permission?.vastuPlanView?.length > 0 && {
            path: "/VastuPlan",
            name: "Vastu Plan",
            icon: VastuPlan,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.vasthuApproval : dashBoardData[0]?.vasthuApproval
        },
        permission?.documentWriterView?.length > 0 && {
            path: "/WriterHome",
            name: "Document Writer",
            icon: docWriter,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.documentWritterCount : dashBoardData[0]?.documentWritterCount
        },
        permission?.agentView?.length > 0 && {
            path: "/AgentHome",
            name: "Agent",
            icon: agent,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.agentCount : dashBoardData[0]?.agentCount
        },
        permission?.consultantView?.length > 0 && {
            path: "/ConsultantHome",
            name: "Consultant",
            icon: consultation,
            count: globalSearch && globalSearchData?.length > 0 ? globalSearchData[0]?.consultantCount : dashBoardData[0]?.consultantCount
        },
        {
            path: "/VillageFinder",
            name: "Village Finder",
            icon: finder1,
        },
        {
            path: "/PlotFinderHome",
            name: "Map",
            icon: map,

        }
    ].filter(Boolean);

    useEffect(() => {
        history.push('/Dashboard');
    }, [history]);

    const loginUserData = getTokenLocalUserStorage("");

    const updateWindowSize = () => {
        if (window.innerWidth <= 768) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    const LogoutHandler = () => {
        dispatch(logout(history, 0));
    };


    var settings = {
        dots: true,
        infinite: false,
        slidesToShow: 10,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const searchHandler = async () => {
        setGlobalSearch(true);
        await dispatch(getMemberData(2, onSearchTxt));
    }

    const searchClearHandler = async () => {
        // console.log(`searchClearHandler triggred`)
        setOnSearchTxt('');
        setGlobalSearch(false);
        dispatch(emptyGlobalSearchData());
        window.location.reload();
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value.length <= 10) {
            setOnSearchTxt(value);
            setGlobalSearch(false)
        }
    };

    return (
        <>
            <div className='AdminNavigation'>
                <div className="top_section">
                    <div className='AdminHeader-Container'>
                        <img src={AppLogo} style={{ background: 'white' }} alt="My Property" className='logo' />
                        <FaUserCircle size={35} color='yellow' style={{ marginLeft: 10, marginRight: 10 }} />
                        <div className='userNameContainer'>
                            <p className='userName'>{loginUserData[0].userName}</p>
                            <p className='userName-mobile'>{loginUserData[0].mobileNo}</p>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-end flex-1'>
                        <nav className="navbar">
                            <form className="form-inline">
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <input
                                        type="number"
                                        maxLength={10}
                                        className="form-control form-input-holder"
                                        id="location"
                                        name="location"
                                        onInput={(e) => {
                                            if (e.target.value.length > 10) {
                                                e.target.value = e.target.value.slice(0, 10);
                                            }
                                        }}
                                        value={onSearchTxt}
                                        onChange={handleInputChange}
                                        placeholder="Mobile Search"
                                        noValidate
                                        style={{ paddingRight: '40px' }}
                                    />
                                    <IconButton
                                        style={{
                                            position: 'absolute',
                                            right: '5px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            padding: '5px',
                                            color: onSearchTxt.length !== 10 ? "lightGray" : globalSearch ? '#e77777' : "#3786FB"
                                        }}
                                        onClick={globalSearch ? searchClearHandler : searchHandler}
                                        disabled={onSearchTxt.length !== 10}
                                    >
                                        {globalSearch ? <HighlightOffIcon /> : <SearchIcon color={"blue"} />}
                                    </IconButton>
                                </div>
                            </form>
                        </nav>
                        <div className='AdminHeader-Container'>
                            <div className='userNameContainer'>
                                <p className='userName'>
                                    {globalSearch ?
                                        <>
                                            <div>{searchData?.userName}</div>
                                            <div>{searchData?.userMobileNo}</div>
                                        </> :
                                        "All"
                                    }
                                </p>
                                {/* <p className='userName-mobile'>{loginUserData[0].mobileNo}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ?
                <div className='mt-5'><Loader1 /></div> :
                <div className="AdminNavigation">
                    <div className='AdminNavigation-Container p-0'>
                        <div>
                            <div className="bars">
                                {isOpen ? <FaRegWindowClose onClick={toggle} style={{ width: 28, height: 28 }} /> : <FaBars onClick={toggle} />}
                            </div>
                            <div style={{ width: isOpen ? "250px" : "50px", display: isOpen ? 'block' : 'none' }} className="sidebar">
                                <div className='NavLink-Container'>
                                    {
                                        menuItem1.map((item, index) => (
                                            <NavLink to={item.path} key={index} className="link" activeClassName="active" style={{ borderWidth: 1 }}>
                                                <div className="icon">{item.icon}</div>
                                                <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                                            </NavLink>
                                        ))
                                    }
                                </div>
                                <div className='logoutBtn-Container'>
                                    <Button className='logoutBtn' style={{ textTransform: 'none' }} onClick={LogoutHandler}>Logout</Button>
                                </div>
                            </div>
                        </div>
                        <main style={{ width: isOpen ? '90%' : '100%' }}>
                            <div className="NavLink-Container2">
                                <Slider {...settings}>
                                    {
                                        menuItem2.map((item, index) => {
                                            const isActive = location.pathname.startsWith(item.path);
                                            return (
                                                <NavLink
                                                    to={item.path}
                                                    key={index}
                                                    className={`link2 ${isActive ? 'active' : ''}`}
                                                    activeClassName="active"
                                                >
                                                    <img src={item.icon} style={{ width: 45, height: 45, alignSelf: 'center' }} alt="My Property" className='Services' />
                                                    <div className="link_text2">{item.name}</div>
                                                    <div style={{ color: '#3786FB', fontWeight: 'bold' }}>{item.count}</div>
                                                </NavLink>
                                            );
                                        })
                                    }
                                </Slider>
                            </div>
                            <div style={{ paddingTop: 10, marginRight: 25 }} >
                                {children}
                            </div>
                        </main>
                    </div>
                </div>
            }
        </>
    );
};

export default withRouter(AdminHomeScreen);
