import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTokenLocalUserStorage } from '../../../../utils/Common';
import { addNeedPropertyMoreInputs, getNeedPropertyMoreInputsData } from '../../../../store/action/NeedPropertyAction';
import { addCroEntry, getCroEntry } from '../../../../store/action/CroEntryActions';
import NeedPropertyDetailPage from './NeedPropertyDetailPage';
import { getBindFocusTypeData, getBindPurposeTypeData, getBindPaymentTypeData, getPropertyAreaRequired, getBindMarriedStatusData, getBindJobSectorData, getBindCurrentStatusData, getNoOfChildren, getEmiCommitments, getLivingSpace, getNearByServices } from '../../../../store/action/BindAction';

const CroEntryNeedProperty = React.memo(({ item }) => {

    //console.log(`CroEntryNeedProperty item = ${JSON.stringify(item)}`)

    let history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.NeedPropertyReducer);
    const bindSelector = useSelector(state => state?.BindReducer);
    const CroSelector = useSelector(state => state?.CroEntryReducer);

    const loginUserData = getTokenLocalUserStorage("");
    const userRfId = loginUserData[0].rfId;

    const [loading, setLoading] = useState(false)
    const [moreInput, setMoreInput] = useState([]);
    const [croDataView, setCroDataView] = useState([]);
    const [areaRequired, setAreaRequired] = useState([]);
    const [focusType, setFocusType] = useState([]);
    const [purposeType, setPurposeType] = useState([]);
    const [paymentType, setPaymentType] = useState([]);
    const [marriedStatus, setMarriedStatus] = useState([]);
    const [noOFChildren, setNoOfChildren] = useState([]);
    const [jobSector, setJobSector] = useState([]);
    const [currentStatus, setCurrentStatus] = useState([]);
    const [emiCommitments, setEmiCommitments] = useState([]);
    const [livingSpace, setLivingSpace] = useState([]);
    const [nearByServices, setNearByServices] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        setLoading(true);
        await dispatch(getNeedPropertyMoreInputsData(item?.rfId));
        await dispatch(getCroEntry(2, item?.rfId));
        setLoading(false);
        await dispatch(getPropertyAreaRequired());
        await dispatch(getBindFocusTypeData());
        await dispatch(getBindPurposeTypeData());
        await dispatch(getBindPaymentTypeData());
        await dispatch(getBindMarriedStatusData());
        await dispatch(getBindJobSectorData());
        await dispatch(getBindCurrentStatusData());
        await dispatch(getNoOfChildren());
        await dispatch(getEmiCommitments());
        await dispatch(getLivingSpace());
        await dispatch(getNearByServices());
    }


    const [bodyData, setBodyData] = useState({
        "rfId": item?.rfId || "",
        "userRfId": "",
        "propertNeedRfId": item?.rfId || "",
        "propertyArea": "",
        "typeOfFocus": "",
        "prepardLocation": "",
        "typeOfPurpose": "",
        "typeOfPayment": "",
        "marriedStatus": "",
        "husbandJobSector": "",
        "wifeJobSector": "",
        "BothInCome": "",
        "noOFChildren": "",
        "additionalCommitments": "",
        "currentLivingStatus": "",
        "nearByServices": "",
        "timeLineOfPurchaseProperty": "",
        "CRORemarks": "",
        "AppointmentDate": "",
        "predictionOfCRO": "",
        "staffLivingStatus": "",
        "religion": "",
        "currentStatusType": "",
        "currentStatusDate": "",
        "customerConversation": "",
        "addMatchProperty": "",
        "inputSource": "Web",
        "loginUserId": "12",
        "remarks": "",
        "action": "Insert"
    });
    const [croData, setCroData] = useState({
        "rfId": "",
        "userRfId": "",
        "croType": "2",
        "conversation": "",
        "conversationDateTime": "",
        "propertyRfId": item?.rfId || "",
        "audioUrl": "",
        "inputSource": "Web",
        "loginUserId": "12",
        "remarks": "",
        "action": "Insert"
    });



    useEffect(() => {
        if (selector?.viewMoreInputs?.data?.message === "Success") {
            const data = selector?.viewMoreInputs?.data?.data[0];
            setMoreInput(data);
            setBodyData(prevState => ({
                ...prevState,
                "rfId": data?.rfId || "",
                "propertyArea": data?.propertyArea || "",
                "typeOfFocus": data?.typeOfFocus || "",
                "prepardLocation": data?.prepardLocation || "",
                "typeOfPurpose": data?.typeOfPurpose || "",
                "typeOfPayment": data?.typeOfPayment || "",
                "marriedStatus": data?.marriedStatus || "",
                "husbandJobSector": data?.husbandJobSector || "",
                "wifeJobSector": data?.wifeJobSector || "",
                "BothInCome": data?.BothInCome || "",
                "noOFChildren": data?.noOFChildren || "",
                "additionalCommitments": data?.additionalCommitments || "",
                "currentLivingStatus": data?.currentLivingStatus || "",
                "nearByServices": data?.nearByServices || "",
                "timeLineOfPurchaseProperty": data?.timeLineOfPurchaseProperty || "",
                "CRORemarks": data?.CRORemarks || "",
                "AppointmentDate": data?.AppointmentDate || "",
                "predictionOfCRO": data?.predictionOfCRO || "",
                "staffLivingStatus": data?.staffLivingStatus || "",
                "religion": data?.religion || "",
                "currentStatusType": data?.currentStatusType || "",
                "currentStatusDate": data?.currentStatusDate || "",
                "customerConversation": data?.customerConversation || "",
                "addMatchProperty": data?.addMatchProperty || ""
            }));
        } else {
            const data = "null";
            setMoreInput(data);
            setBodyData(prevState => ({
                ...prevState,
                "propertyArea": "",
                "typeOfFocus": "",
                "prepardLocation": "",
                "typeOfPurpose": "",
                "typeOfPayment": "",
                "marriedStatus": "",
                "husbandJobSector": "",
                "wifeJobSector": "",
                "BothInCome": "",
                "noOFChildren": "",
                "additionalCommitments": "",
                "currentLivingStatus": "",
                "nearByServices": "",
                "timeLineOfPurchaseProperty": "",
                "CRORemarks": "",
                "AppointmentDate": "",
                "predictionOfCRO": "",
                "staffLivingStatus": "",
                "religion": "",
                "currentStatusType": "",
                "currentStatusDate": "",
                "customerConversation": "",
                "addMatchProperty": ""
            }));
        }
    }, [selector?.viewMoreInputs?.data]);

    useEffect(() => {

        if (CroSelector?.viewList?.data?.message === "Success") {
            setCroDataView(CroSelector?.viewList?.data?.data)
        } else if (CroSelector?.viewList?.data?.alert == "No More Data Real Estate Site CRO Details") {
            setCroDataView([croData])
        }
    }, [CroSelector?.viewList?.data]);

    useEffect(() => {
        if (bindSelector?.propertyAreaRequired?.data?.message === "Success") {
            setAreaRequired(bindSelector?.propertyAreaRequired?.data?.data);
        }
        if (bindSelector?.focusTypeData?.data?.message === "Success") {
            setFocusType(bindSelector?.focusTypeData?.data?.data);
        }
        if (bindSelector?.typeOfPurposeData?.data?.message === "Success") {
            setPurposeType(bindSelector?.typeOfPurposeData?.data?.data);
        }
        if (bindSelector?.typeOfPaymentData?.data?.message === "Success") {
            setPaymentType(bindSelector?.typeOfPaymentData?.data?.data);
        }
        if (bindSelector?.marriedStatusData?.data?.message === "Success") {
            setMarriedStatus(bindSelector?.marriedStatusData?.data?.data);
        }
        if (bindSelector?.jobSectorData?.data?.message === "Success") {
            setJobSector(bindSelector?.jobSectorData?.data?.data);
        }
        if (bindSelector?.currentStatusData?.data?.message === "Success") {
            setCurrentStatus(bindSelector?.currentStatusData?.data?.data);
        }
        if (bindSelector?.numOfChildren?.data?.message === "Success") {
            setNoOfChildren(bindSelector?.numOfChildren?.data?.data);
        }
        if (bindSelector?.emiCommitments?.data?.message === "Success") {
            setEmiCommitments(bindSelector?.emiCommitments?.data?.data);
        }
        if (bindSelector?.livingSpace?.data?.message === "Success") {
            setLivingSpace(bindSelector?.livingSpace?.data?.data);
        }
        if (bindSelector?.nearByServices?.data?.message === "Success") {
            setNearByServices(bindSelector?.nearByServices?.data?.data);
        }
    }, [bindSelector?.focusTypeData?.data, bindSelector?.typeOfPurposeData?.data, bindSelector?.typeOfPaymentData?.data, bindSelector?.marriedStatusData?.data, bindSelector?.jobSectorData?.data, bindSelector?.currentStatusData?.data, bindSelector?.propertyAreaRequired?.data, bindSelector?.numOfChildren?.data, bindSelector?.emiCommitments?.data, bindSelector?.livingSpace?.data, bindSelector?.nearByServices?.data]);


    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        const hh = String(today.getHours()).padStart(2, '0');
        const min = String(today.getMinutes()).padStart(2, '0');
        const formattedDateTime = `${yyyy}-${mm}-${dd}T${hh}:${min}`;
        setBodyData((prevState) => ({
            ...prevState,
            userRfId: userRfId,
        }));
        setCroData((prevState) => ({
            ...prevState,
            userRfId: userRfId,
            conversationDateTime: formattedDateTime
        }));
    }, []);

    const addRow = async () => {
        setCroDataView([...croDataView, croData])
        // await dispatch(addCroEntry(croData));

    };

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }
    const onChangeCroData = (e, index) => {
        // setCroData({ ...croData, [e.target.name]: e.target.value });
        const { name, value } = e.target;
        setCroDataView(prevData =>
            prevData.map((item, i) =>
                i === index ? { ...item, [name]: value } : item
            )
        );
    }
    const AddCroHandler = async () => {
        await dispatch(addNeedPropertyMoreInputs(bodyData, history));
        croDataView?.length > 0 &&
            await croDataView?.map(async (data, index) => {
                if (data?.rfId === "") {
                    console.log("data", JSON.stringify(data))
                    await dispatch(addCroEntry(data));
                }
            })
    }

    return (
        <div className='DetailPage-container'>
            <NeedPropertyDetailPage item={item} currentStatus={bodyData?.currentStatusType} />

            <div>

                <div>
                    <p className='PropertyName-heading mt-5' style={{ color: 'red', fontSize: 18 }}>More Inputs</p>
                </div>
                <div className="row">
                    <div className="col-md-4 mt-2">
                        <label htmlFor="propertyArea" className="text-black">Property Area Required</label>
                        <select className="form-select" name="propertyArea" style={{ fontSize: '14px' }}
                            value={bodyData?.propertyArea}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                areaRequired.length > 0 && areaRequired?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-8 mt-2">
                        <label htmlFor="typeOfFocus" className="text-black">Type Of Facing / Type Of Building /Agri Land</label>
                        <select className="form-select" name="typeOfFocus" style={{ fontSize: '14px' }}
                            value={bodyData?.typeOfFocus}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                focusType.length > 0 && focusType?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="prepardLocation" className="text-black">Preferred location or area</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="prepardLocation"
                            name='prepardLocation'
                            value={bodyData?.prepardLocation}
                            onChange={e => onChange(e)}
                            placeholder="Preferred location or area"
                            noValidate
                        />
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="typeOfPurpose" className="text-black">Type Of Purpose ( Investment / Own)</label>
                        <select className="form-select" name="typeOfPurpose" style={{ fontSize: '14px' }}
                            value={bodyData?.typeOfPurpose}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                purposeType.length > 0 && purposeType?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="typeOfPayment" className="text-black">Loan Or Ready To Cash</label>
                        <select className="form-select" name="typeOfPayment" style={{ fontSize: '14px' }}
                            value={bodyData?.typeOfPayment}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                paymentType.length > 0 && paymentType?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="marriedStatus" className="text-black">Married / Unmarried</label>
                        <select className="form-select" name="marriedStatus" style={{ fontSize: '14px' }}
                            value={bodyData?.marriedStatus}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                marriedStatus.length > 0 && marriedStatus?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="husbandJobSector" className="text-black">Job Sectors (Husband)</label>
                        <select className="form-select" name="husbandJobSector" style={{ fontSize: '14px' }}
                            value={bodyData?.husbandJobSector}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                jobSector.length > 0 && jobSector
                                    .filter(data => data.rfId !== 10)
                                    .map((data, index) => {
                                        return <option key={index} value={data.rfId}> {data.dataName} </option>
                                    })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="wifeJobSector" className="text-black">Job Sectors (Wife)</label>
                        <select className="form-select" name="wifeJobSector" style={{ fontSize: '14px' }}
                            value={bodyData?.wifeJobSector}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                jobSector.length > 0 && jobSector?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="BothInCome" className="text-black">Both Incomes</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="BothInCome"
                            name='BothInCome'
                            value={bodyData?.BothInCome}
                            onChange={e => onChange(e)}
                            placeholder="Both Incomes"
                            noValidate
                        />
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="noOFChildren" className="text-black">No of Children</label>
                        <select className="form-select" name="noOFChildren" style={{ fontSize: '14px' }}
                            value={bodyData?.noOFChildren}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                noOFChildren.length > 0 && noOFChildren?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="additionalCommitments" className="text-black">Additional Commitments (Loan or EMI)</label>
                        <select className="form-select" name="additionalCommitments" style={{ fontSize: '14px' }}
                            value={bodyData?.additionalCommitments}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                emiCommitments.length > 0 && emiCommitments?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="currentLivingStatus" className="text-black">Current living Place</label>
                        <select className="form-select" name="currentLivingStatus" style={{ fontSize: '14px' }}
                            value={bodyData?.currentLivingStatus}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                livingSpace.length > 0 && livingSpace?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="nearByServices" className="text-black">Near By Services (Customer)</label>
                        <select className="form-select" name="nearByServices" style={{ fontSize: '14px' }}
                            value={bodyData?.nearByServices}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                nearByServices.length > 0 && nearByServices?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="timeLineOfPurchaseProperty" className="text-black">Timeline Of Purchase Property</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="timeLineOfPurchaseProperty"
                            name='timeLineOfPurchaseProperty'
                            value={bodyData?.timeLineOfPurchaseProperty}
                            onChange={e => onChange(e)}
                            placeholder="Timeline Of Purchase Property"
                            noValidate
                        />
                    </div>
                    <div className="col-md-6 mt-4">
                        <label htmlFor="CRORemarks" className="text-black">CRO Remarks</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="CRORemarks"
                            name='CRORemarks'
                            value={bodyData?.CRORemarks}
                            onChange={e => onChange(e)}
                            placeholder="CRO Remarks"
                            noValidate
                        />
                    </div>
                    <div className="form-group col-md-6 mt-4">
                        <label htmlFor="AppointmentDate">Appointment Date</label>
                        <input
                            type="date"
                            className="form-control border rounded-3"
                            id="AppointmentDate"
                            value={bodyData?.AppointmentDate}
                            onChange={(e) => setBodyData(prevState => ({
                                ...prevState,
                                AppointmentDate: e.target.value
                            }))}
                        />
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="predictionOfCRO" className="text-black">Prediction Of CRO</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="predictionOfCRO"
                            name='predictionOfCRO'
                            value={bodyData?.predictionOfCRO}
                            onChange={e => onChange(e)}
                            placeholder="Prediction Of CRO"
                            noValidate
                        />
                    </div>
                    <div>
                        <p className='PropertyName-heading mt-4' style={{ color: 'red', fontSize: 18 }}>Field Staff Entry</p>
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="staffLivingStatus" className="text-black">Living Status</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="staffLivingStatus"
                            name='staffLivingStatus'
                            value={bodyData?.staffLivingStatus}
                            onChange={e => onChange(e)}
                            placeholder="Living Status"
                            noValidate
                        />
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="religion" className="text-black">Religion</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="religion"
                            name='religion'
                            value={bodyData?.religion}
                            onChange={e => onChange(e)}
                            placeholder="Religion"
                            noValidate
                        />
                    </div>
                    <div>
                        <p className='PropertyName-heading mt-4' style={{ color: 'red', fontSize: 18 }}>Office Verification And Status</p>
                    </div>
                    <div className="col-md-6 mt-2">
                        <label htmlFor="currentStatusType" className="text-black">Current Status</label>
                        <select className="form-select" name="currentStatusType" style={{ fontSize: '14px' }}
                            value={bodyData?.currentStatusType}
                            onChange={e => onChange(e)}>
                            <option value="">Select</option>
                            {
                                currentStatus.length > 0 && currentStatus?.map((data, index) => {
                                    return <option key={index} value={data.rfId}> {data.dataName} </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group col-md-6 mt-2">
                        <label htmlFor="date">Date</label>
                        <input
                            type="date"
                            className="form-control border rounded-3"
                            id="currentStatusDate"
                            value={bodyData?.currentStatusDate}
                            onChange={(e) => setBodyData(prevState => ({
                                ...prevState,
                                currentStatusDate: e.target.value
                            }))}
                        />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="customerConversation" className="text-black">Customer Conversation</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="customerConversation"
                            name='customerConversation'
                            value={bodyData?.customerConversation}
                            onChange={e => onChange(e)}
                            placeholder="Customer Conversation"
                            noValidate
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="addMatchProperty" className="text-black">Add Matching Properties</label>
                        <input
                            type="text"
                            className="form-control form-input-holder"
                            id="addMatchProperty"
                            name='addMatchProperty'
                            value={bodyData?.addMatchProperty}
                            onChange={e => onChange(e)}
                            placeholder="Add Matching Properties"
                            noValidate
                        />
                    </div>
                </div>
            </div>

            <div>
                <p className='PropertyName-heading mt-3' style={{ color: 'red', fontSize: 18, fontWeight: 600 }}>Customer Conversation</p>
            </div>
            {croDataView?.map((item, index) => (
                <div key={item?.rfId} className='row property-form'>
                    <div className="form-group col-md-3">
                        <label htmlFor="conversationDateTime">Date</label>
                        <input
                            type="datetime-local"
                            className="form-control border rounded-3"
                            id="conversationDateTime"
                            value={item?.conversationDateTime}
                            readOnly={item?.rfId !== "" ? true : false}
                        />
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="conversation" className="form-label">Conversation</label>
                        <textarea type="text"
                            className="form-control form-input-holder"
                            id="conversation"
                            name="conversation"
                            value={item?.conversation}
                            onChange={e => onChangeCroData(e, index)}
                            placeholder="Enter Conversation"
                            readOnly={item?.rfId != "" ? true : false}
                            rows="7"
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="remarks" className="form-label">Remarks</label>
                        <textarea type="text"
                            className="form-control form-input-holder"
                            id="remarks"
                            name="remarks"
                            placeholder="Remarks"
                            value={item?.remarks}
                            onChange={e => onChangeCroData(e, index)}
                            readOnly={item?.rfId != "" ? true : false}
                        />
                    </div>
                    <div className="col-md-1 d-flex align-items-center">
                        {/* {item?.rfId != "" &&
                            <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#81d06f', marginRight: '10px' }} >Edit</Button>
                        } */}
                        {index + 1 == croDataView?.length &&
                            <Button variant="contained" type="submit" onClick={addRow}
                                size="small"
                                style={{ textTransform: 'none', color: "white", backgroundColor: '#81d06f', marginRight: '10px' }} >Add</Button>
                        }
                    </div>
                </div>
            ))
            }
            <div>
                <div className="col-md-12 text-center">
                    <button className="btn btn-primary px-5" type="submit" onClick={() => AddCroHandler()}>{moreInput?.rfId ? 'Update' : 'Submit'}</button>
                </div>
            </div>
        </div>
    )
})

export default CroEntryNeedProperty;
