import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Switch from "react-switch";
import UploadFile from '../Others/UploadFile';
import { useDispatch, useSelector } from "react-redux";
import { paymentRequestSendUser } from '../../../../store/action/PaymentAction';
import { getVastuPlanList, updateVastuPlanStatus, addVastuPlan } from '../../../../store/action/VastuPlanAction';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import VastuPlanDetailPage from './VastuPlanDetailPage';

function EditVastuPlan({ item, open, handleClose }) {

    // console.log(`EditVastuPlan item  = ${JSON.stringify(item)}`)

    const dispatch = useDispatch();
    let history = useHistory();

    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState({});
    const [finalDoc, setFinalDoc] = useState(item?.downloadDocument || null);
    const [bodyData, setBodyData] = useState({
        "rfId": "",
        "userRfId": "",
        "propertyRfId": "",
        "payment": 0,
        "statusRfId": 1,
        "loginUserId": "12",
        "remarks": "",
        "action": "Insert"
    });

    const fetchData = async () => {
        await dispatch(getVastuPlanList())
    }

    const onChangeAmount = (name, value, item) => {
        setBodyData({
            ...bodyData,
            rfId: item?.paymentRfId,
            userRfId: item?.userRfId,
            propertyRfId: item?.rfId,
            payment: value
        })
    }

    const handleToggle = async (item, statusRfId, status) => {
        await dispatch(updateVastuPlanStatus(item?.rfId, statusRfId, status, 4, setSending));
        await handleClose();
        await fetchData();
    };

    const onSubmitPaymentRequest = async (item) => {
        await dispatch(paymentRequestSendUser(bodyData, item?.paymentRfId, 4));
        await handleClose();
        await fetchData();
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const onChangeUpload = async (name, value) => {
        await setFinalDoc(value)
        value.length > 0 && handleError("", name);
    }

    const validateUpdateStatus = async (item) => {
        let isValid = true;

        if (!finalDoc) {
            handleError('Required', 'finalDoc');
            isValid = false;
        }
        if (isValid) {
            const formData = item;
            formData.action = "Insert";
            formData.downloadDocument = finalDoc;
            await dispatch(addVastuPlan(formData, history, setSending));
        }
    }

    return (
        <div>
            <Modal
                show={open}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Vastu Plan Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='DetailPage-container'>
                        <VastuPlanDetailPage item={item} />
                        {
                            item?.vasthuDownloadStatus == 2 ?
                                <div className='d-flex flex-column align-items-center'>
                                    {
                                        item?.downloadDocument != "" ?
                                            <div className='d-flex flex-column align-items-center'>
                                                <p className='StatusContainer_text1 mt-5'>The request is completed & the file is already send to Land owner</p>
                                                <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", backgroundColor: '#28d7a0', marginLeft: '10px' }}
                                                    onClick={() => window.open(item?.downloadDocument)}
                                                >
                                                    Download Document
                                                </Button>
                                            </div> :
                                            <div className='d-flex flex-column align-items-center'>
                                                <UploadFile
                                                    fileOnSet={onChangeUpload}
                                                    name="landDocument"
                                                    error={errors?.landDocument}
                                                >
                                                    Upload Land Documents
                                                </UploadFile>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        style={{ textTransform: 'none', color: "white", backgroundColor: '#3786FB' }}
                                                        className="btn btn-info btn-pickLocation"
                                                        disabled={item?.downloadDocument != "" && true}
                                                        onClick={() => validateUpdateStatus(item)}
                                                    >
                                                        Send File
                                                    </Button>
                                                </div>
                                            </div>
                                    }
                                </div> :
                                <>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.uploadSuccess}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 17, newValue)}
                                            checked={item?.uploadSuccessStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                        />
                                        <p className='StatusContainer_text2'>{item?.uploadSuccessDateTime == null ? 'Pending..' : item?.uploadSuccessDateTime}</p>
                                    </div>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.verifiedDocument}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 18, newValue)}
                                            checked={item?.verifiedDocumentStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.uploadSuccessStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.verifiedDocumentStatusDateTime == null ? 'Pending..' : item?.verifiedDocumentStatusDateTime}</p>
                                    </div>
                                    {item?.verifiedDocumentStatus == 2 &&
                                        <div className="Status_Container2">
                                            <input
                                                type="number"
                                                className="form-control form-input-holder"
                                                id="paymentAmount" name='paymentAmount'
                                                style={{ maxWidth: 250 }}
                                                placeholder="Enter Payment Amount"
                                                disabled={item?.paymentAmount != 0 && item?.paymentAmount != null && true}
                                                value={item?.paymentAmount == 0 ? bodyData?.payment?.toString() : item?.paymentAmount?.toString()}
                                                onChange={(e) => { onChangeAmount("payment", e.target.value, item) }}
                                            />
                                            <div className="col-md-2 btn-box">
                                                <Button
                                                    type="button"
                                                    style={{ textTransform: 'none', color: "white", backgroundColor: '#3786FB' }}
                                                    className="btn btn-info btn-pickLocation"
                                                    disabled={item?.paymentAmount != 0 && item?.paymentAmount != null && true}
                                                    onClick={() => onSubmitPaymentRequest(item)}
                                                >
                                                    Request
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.payment}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 19, newValue)}
                                            checked={item?.paymentStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.verifiedDocumentStatusDateTime == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.paymentStatusDateTime == null ? 'Pending..' : item?.paymentStatusDateTime}</p>
                                    </div>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.vasthuCompleted}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 20, newValue)}
                                            checked={item?.vasthuCompletedStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.paymentStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.vasthuCompletedsDateTime == null ? 'Pending..' : item?.vasthuCompletedsDateTime}</p>
                                    </div>
                                    <div className='Status_Container'>
                                        <p className='StatusContainer_text1'>{item?.vasthuDownload}</p>
                                        <Switch
                                            onChange={(newValue) => handleToggle(item, 21, newValue)}
                                            checked={item?.vasthuDownloadStatus == 2}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={'#3786FB'}
                                            disabled={item?.vasthuCompletedStatus == 1}
                                        />
                                        <p className='StatusContainer_text2'>{item?.vasthuDownloadDateTime == null ? 'Pending..' : item?.vasthuDownloadDateTime}</p>
                                    </div>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditVastuPlan