import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { updateConsultingFees } from '../../../../store/action/BindAction';
import { useDispatch, useSelector } from "react-redux";

export default function EditConsultingFees({ item, open, handleClose }) {

    // console.log(`EditConsultingFees item = ${JSON.stringify(item)}`)
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [bodyData, setBodyData] = useState({
        "rfId": "",
        "dataName": "",
        "consultingCharge": ""
    })

    useEffect(() => {
        if (item) {
            setBodyData({
                "rfId": item.rfId,
                "dataName": item.dataName,
                "consultingCharge": item.consultingCharge
            });
        }
    }, [item]);

    const onChange = (e) => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    };

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };


    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData.consultingCharge) {
            handleError('Consulting Charges is needed', 'consultingCharge');
            isValid = false;
        }

        if (isValid) {
            //console.log(`EditConsultingFees bodyData = ${JSON.stringify(bodyData)}`)
            handleAddFormSubmit();
        }
    }

    const handleAddFormSubmit = async () => {
        await dispatch(updateConsultingFees(bodyData?.rfId, bodyData?.dataName, bodyData?.consultingCharge));
        await handleClose();
    }

    return (
        <div>
            <Modal
                show={open}
                onHide={handleClose}
                backdrop="true"
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Consulting Fees for {item?.dataName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='DetailPage-container'>
                        <div className="row">
                            <div className="col-md-3 d-flex align-items-center mt-4">
                                {errors.consultingCharge ?
                                    <label htmlFor="consultingCharge" className="error-text">{errors.consultingCharge}</label> :
                                    <label htmlFor="consultingCharge" className="text-black">Consulting Fees<span className="required">*</span> </label>
                                }
                            </div>
                            <div className="col-md-6 mt-4">
                                <input
                                    className="form-control form-input-holder"
                                    id="consultingCharge"
                                    name='consultingCharge'
                                    value={bodyData?.consultingCharge}
                                    onChange={e => onChange(e)}
                                    placeholder="Consulting Fees"
                                    onFocus={() => handleError(null, 'consultingCharge')}
                                />
                            </div>
                            <div className="col-md-2 mt-4">
                                <Button
                                    variant="contained"
                                    size="large"
                                    style={{ textTransform: 'none', color: "white", backgroundColor: '#28d7a0', marginRight: 10 }}
                                    onClick={validate}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
