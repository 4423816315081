import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiFillMinusCircle } from 'react-icons/ai';
import { BsBuildingsFill, BsFillHouseDoorFill } from 'react-icons/bs';
import { IoMdAddCircle } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import { getDistrictData, getBindMemberSingleData, getBindAreaUnitData } from '../../../../store/action/BindAction';
import { layoutRegistrationData } from '../../../../store/action/LayoutAction';
import AddOwner from '../../Admin/LandOwners/AddOwner';
import PickLocation from '../Others/PickLocation';
import UploadImage from '../Others/UploadImage';
import Collapsible from 'react-collapsible';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Button from '@mui/material/Button';
import Intl from 'intl';
import 'intl/locale-data/jsonp/en-IN';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';

function CreateHouse({ data }) {

    // console.log(`create house - ${JSON.stringify(data)}`)

    const dispatch = useDispatch();
    const history = useHistory();

    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState({});
    const [memberData, setMemberData] = useState([]);
    const selector = useSelector(state => state?.BindReducer);
    const [districtData, setDistrictData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selection, setSelection] = useState(1);
    const [inputList, setInputList] = useState(data != "" ? JSON.parse(data?.partner) : [{ name: "", partner: "" }]);
    const [mediaDataUpdate, setMediaDataUpdate] = useState(data != "" ? JSON.parse(data?.media) : "")
    const [file, setFile] = useState(data?.layoutMap || null);
    const [areaUnitData, setAreaUnitData] = useState([]);
    const [showMapModel, setShowMapModel] = useState(false);
    const [valueInL, setValueInL] = useState("");
    const [showAddLandOwnerModel, setShowAddLandOwnerModel] = useState(false);
    const [moreOption, setMoreOption] = useState(false);
    const [bodyData, setBodyData] = useState({
        rfId: data?.rfId || "",
        userRfId: data?.userRfId || "",
        layoutType: data?.layoutType || 2,
        layoutName: data?.layoutName || "",
        contactNumber: data?.contactNumber || "",
        whatsAppNumber: data?.whatsAppNumber || "",
        landType: data?.landType || 1,
        landAreaType: data?.landAreaType || 1,
        totalLandArea: data?.totalLandArea || 0,
        totalBuildingArea: data?.totalBuildingArea || "",
        numberOfFloor: data?.numberOfFloor || '',
        numberOfBhk: data?.numberOfBhk || "",
        stateId: data?.stateId || 1,
        districtId: data?.districtId || "",
        blockId: data?.blockId || 1,
        address: data?.address || "",
        pincode: data?.pincode || "",
        noOfPlots: 1,
        postedBy: data?.postedBy || 1,
        dtcpApprovedStatus: data?.dtcpApprovedStatus?.data || 1,
        layoutMap: data?.layoutMap || "",
        siteMap: data?.siteMap == "null" || data?.siteMap == "" || data?.siteMap == null ? [] : JSON.parse(data?.siteMap),
        documentUploadStatus: data?.documentUploadStatus || 1,
        documentVerificationStatus: data?.documentVerificationStatus || 1,
        documentUploadCount: data?.documentUploadCount || 1,
        documentVerificationCount: data?.documentVerificationCount || 1,
        latitude: data?.latitude || "",
        longitude: data?.longitude || "",
        location: data?.location || "",
        approximateLength: data?.approximateLength || 0,
        approximateWidth: data?.approximateWidth || 0,
        approximateRate: data?.approximateRate || "",
        dtdcNumber: data?.dtdcNumber || "",
        reraNumber: data?.reraNumber || "",
        mailId: data?.mailId || "",
        media: data?.media || "",
        partner: data?.partner || "",
        loginUserId: data?.loginUserId || ""
    });

    const [mediaData, setMediaData] = useState({
        youtubeLink: mediaDataUpdate?.youtubeLink || "",
        instaLink: mediaDataUpdate?.instaLink || "",
        facebookLink: mediaDataUpdate?.facebookLink || "",
        websiteLink: mediaDataUpdate?.websiteLink || "",
        linkedLink: mediaDataUpdate?.linkedLink || "",
        others: mediaDataUpdate?.others || ""

    })

    const { rfId, layoutName, contactNumber, layoutType, whatsAppNumber, userRfId, landType, landAreaType, totalLandArea, totalBuildingArea,
        numberOfFloor, numberOfBhk, stateId, districtId, blockId, address, pincode, noOfPlots, postedBy, dtcpApprovedStatus, layoutMap,
        documentUploadStatus, documentVerificationStatus, documentUploadCount, documentVerificationCount, latitude, longitude, location,
        approximateLength, approximateWidth, approximateRate, dtdcNumber, reraNumber, mailId, youtubeLink, instaLink, mobileNumber } = bodyData

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getDistrictData(1));
            await dispatch(getBindMemberSingleData());
            await dispatch(getBindAreaUnitData())
        }
        fetchData()
    }, []);

    useEffect(() => {
        if (selector?.districtData?.data?.alert == "District Details") {
            setDistrictData(selector?.districtData?.data?.data)
        }
        if (selector?.memberData?.data?.alert === "Real Estate Member Details") {
            let a = {}
            // setMemberData(selector?.memberData?.data?.data)
            setMemberData(selector?.memberData?.data?.data?.map((res, index) => {
                if (res.rfId == userRfId) {
                    a.label = res.combines;
                    a.value = res.rfId;
                    a.mobileNo = res.mobileNo;
                    a.rfId = res.rfId;
                }
                return {
                    label: res.combines,
                    value: res.rfId,
                    mobileNo: res.mobileNo,
                    rfId: res.rfId
                }
            }))
            data != "" && setSelectedOption(a)
        }
        if (selector?.areaUnitData?.data?.alert === "Land Area Type") {
            setAreaUnitData(selector?.areaUnitData?.data?.data)
        }
    }, [selector?.payload?.data, selector?.memberData?.data, selector?.areaUnitData?.data])

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { name: "", partner: "" }]);
    };

    const onChangeSelect = (option) => {
        setBodyData({ ...bodyData, userRfId: option });
    };

    const handleAddFormSubmit = async (e) => {
        let formData = new FormData();
        formData.append('layoutMap', file);
        formData.append('rfId', bodyData?.rfId);
        formData.append('userRfId', bodyData?.rfId != "" ? bodyData?.userRfId : bodyData?.userRfId?.value);
        formData.append('layoutType', 2);
        formData.append('landType', selection);
        formData.append('layoutName', bodyData?.layoutName);
        formData.append('contactNumber', bodyData?.contactNumber);
        formData.append('whatsAppNumber', bodyData?.whatsAppNumber);
        formData.append('landAreaType', bodyData?.landAreaType);
        formData.append('totalLandArea', parseInt(bodyData?.totalLandArea));
        formData.append('totalBuildingArea', bodyData?.totalBuildingArea);
        formData.append('numberOfFloor', bodyData?.numberOfFloor);
        formData.append('numberOfBhk', bodyData?.numberOfBhk);
        formData.append('stateId', 1);
        formData.append('districtId', bodyData?.districtId);
        formData.append('blockId', 1);
        formData.append('address', bodyData?.address);
        formData.append('pincode', bodyData?.pincode);
        //formData.append('noOfPlots', parseInt(bodyData?.noOfPlots));    
        formData.append('noOfPlots', 1);
        formData.append('layoutOwnerType', bodyData?.postedBy);
        formData.append('dtcpApprovedStatus', bodyData?.dtcpApprovedStatus);
        formData.append('documentUploadStatus', 1);
        formData.append('documentVerificationStatus', 1);
        formData.append('documentUploadCount', 1);
        formData.append('documentVerificationCount', 1);
        formData.append('approximateLength', bodyData?.approximateLength);
        formData.append('approximateWidth', bodyData?.approximateWidth);
        formData.append('approximateRate', bodyData?.approximateRate);
        bodyData?.siteMap?.length > 0 && formData.append('siteMap', JSON.stringify(bodyData?.siteMap))
        formData.append('longitude', bodyData?.longitude != "" ? bodyData?.longitude : "0.00");
        formData.append('latitude', bodyData?.latitude != "" ? bodyData?.latitude : "0.00");
        formData.append('location', bodyData?.location);
        formData.append('dtdcNumber', bodyData?.dtdcNumber);
        formData.append('reraNumber', bodyData?.reraNumber);
        formData.append('mailId', bodyData?.mailId);
        formData.append('media', JSON.stringify(mediaData));
        formData.append('partner', JSON.stringify(inputList));
        formData.append('inputSource', 'Android');
        formData.append('loginUserId', bodyData?.loginUserId);
        formData.append('remarks', '');
        formData.append("action", "Insert");

        await dispatch(layoutRegistrationData(formData, history, setSending))
    }

    const onChangeMobileNumber = option => {
        //console.log(`options - ${JSON.stringify(option)}`)
        let mobileData = memberData.length > 0 && memberData.filter((data) => data.rfId == option.value)
        //console.log(`mobileData - ${JSON.stringify(mobileData)}`)
        if (mobileData.length > 0) {
            setBodyData({ ...bodyData, userRfId: option, contactNumber: mobileData[0]?.mobileNo, whatsAppNumber: mobileData[0]?.mobileNo.toString() });
        }
    }

    const onChangeLocation = (villageFinderData, address, location, pincode, districtId) => {
        // console.log(`villageFinderData = ${JSON.stringify(villageFinderData)}`)
        setBodyData({
            ...bodyData,
            latitude: villageFinderData?.latitude,
            longitude: villageFinderData?.longitude,
            address: address,
            location: location,
            pincode: pincode,
            districtName: villageFinderData?.district_name,
            districtId: districtId,
        });
    }

    const onChangeMedia = e => {
        setMediaData({ ...mediaData, [e.target.name]: e.target.value });
    }

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData.userRfId) {
            handleError('Select Landowner Name', 'userRfId');
            isValid = false;
        }

        if (!bodyData.layoutName) {
            handleError('Enter House Name', 'layoutName');
            isValid = false;
        }

        if (
            !bodyData.whatsAppNumber ||
            isNaN(bodyData.whatsAppNumber) ||
            (bodyData.whatsAppNumber.toString().length !== 10 ||
                bodyData.whatsAppNumber.toString().includes('.'))
        ) {
            handleError('Enter valid number', 'whatsAppNumber');
            isValid = false;
        }

        if (!bodyData.noOfPlots) {
            handleError('Enter no of Plots', 'noOfPlots');
            isValid = false;
        }


        if (!bodyData.totalLandArea && selection === 1) {
            handleError('Enter Land Area', 'totalLandArea');
            isValid = false;
        }

        if (!bodyData.landAreaType) {
            handleError('Select Area Type', 'landAreaType');
            isValid = false;
        }

        if (!bodyData.numberOfFloor && selection === 1) {
            handleError('Enter No of Floor', 'numberOfFloor');
            isValid = false;
        }

        if (!bodyData.totalBuildingArea) {
            handleError(selection === 1 ? "Enter Building Area" : "Enter Appartment Area", 'totalBuildingArea');
            isValid = false;
        }

        if (!bodyData.numberOfBhk) {
            handleError('Enter No of BHK', 'numberOfBhk');
            isValid = false;
        }

        if (!bodyData.approximateRate) {
            handleError('Enter Appx rate', 'approximateRate');
            isValid = false;
        }

        if (!bodyData.address) {
            handleError('Enter address', 'address');
            isValid = false;
        }

        if (!bodyData.location) {
            handleError('Enter location', 'location');
            isValid = false;
        }

        if (!bodyData.districtId) {
            setErrors(prevState => ({ ...prevState, districtId: 'Select district' }));
            isValid = false;
        } else {
            setErrors(prevState => ({ ...prevState, districtId: '' }));
        }

        if (
            !bodyData.pincode ||
            isNaN(bodyData.pincode) ||
            (bodyData.pincode.toString().length !== 6 ||
                bodyData.pincode.toString().includes('.'))
        ) {
            handleError('Enter Pincode', 'pincode');
            isValid = false;
        }

        if (isValid) {
            handleAddFormSubmit(e);
        }
    }

    const formatINR = (value) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format(value);
    };

    const onChangeCurrency = (name, value) => {

        setBodyData({ ...bodyData, [name]: value });
        const cleanNumber = value.replace(/[^0-9]/g, "");
        const formattedValue = cleanNumber !== "" ? formatINR(cleanNumber.split(",").join("")) : "";
        setValueInL(formattedValue)

    };

    const onChangeDistrict = async (districtId) => {
        setBodyData({ ...bodyData, districtId: districtId });
    };
    const toggleCollapsible = () => {
        setMoreOption(!moreOption);
    };

    const fileOnSet = async (name, result) => {
        // console.log(`siteMap result = ${result}`)
        await setBodyData((prevState) => ({ ...prevState, [name]: result }))
        // value.length > 0 && handleError("", name);
    }

    return (
        <>
            <div className="btnGroup-Container">
                <div className="btnGroup">
                    <button className={`btn ${selection === 1 ? 'active' : ''}`} onClick={() => setSelection(1)}>
                        <BsFillHouseDoorFill className={`icon ${selection === 1 ? 'active' : ''}`} size={18} />
                        <span className={`btnText ${selection === 1 ? 'active' : ''} ml-2`}>Houses</span>
                    </button>
                    <button className={`btn ${selection === 2 ? 'active' : ''}`} onClick={() => setSelection(2)}>
                        <BsBuildingsFill className={`icon ${selection === 2 ? 'active' : ''}`} size={18} />
                        <span className={`btnText ${selection === 2 ? 'active' : ''}`}>Appartments</span>
                    </button>
                </div>
            </div>
            <form className="row g-3 property-form pl-5 mt-3"
                noValidate
            >
                <div className="col-md-5">
                    {errors.userRfId ?
                        <label htmlFor="userRfId" className="error-text required">{errors.userRfId}</label> :
                        <label htmlFor="userRfId" className="text-black">Land Owner Name<span className="required">*</span> </label>
                    }
                    <Select
                        onChange={onChangeMobileNumber}
                        options={memberData}
                        value={data != "" ? selectedOption : data?.userRfId}
                        onFocus={() => handleError(null, 'userRfId')}
                        className='Landowner-select-container'
                        isDisabled={data != "" ? true : false}
                        defaultValue={data?.userRfId || 'Select'}
                    />
                </div>
                <div className="col-md-1 btn-box" >
                    <Button className='btn btn-add' onClick={() => setShowAddLandOwnerModel(!showAddLandOwnerModel)}>
                        <IoMdAddCircle size={23} color='white' />
                    </Button>
                </div>

                <div className="col-md-6">
                    {errors.layoutName ?
                        <label htmlFor="layoutName" className="error-text required">{errors.layoutName}</label> :
                        <label htmlFor="layoutName" className="text-black">House Name<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="layoutName" name='layoutName'
                        value={layoutName}
                        onChange={e => onChange(e)}
                        placeholder="Enter House Name"
                        noValidate
                        onFocus={() => handleError(null, 'layoutName')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.whatsAppNumber ?
                        <label htmlFor="whatsAppNumber" className="error-text">{errors.whatsAppNumber}</label> :
                        <label htmlFor="whatsAppNumber" className="text-black">Whatsapp Number<span className="required">*</span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="whatsAppNumber" name='whatsAppNumber'
                        value={whatsAppNumber}
                        onChange={e => onChange(e)}
                        placeholder="Enter Whats App Number"
                        noValidate
                        onFocus={() => handleError(null, 'whatsAppNumber')}
                    />
                </div>

                {selection == 1 && (
                    <>
                        <div className="col-md-4">
                            {errors.totalLandArea ?
                                <label htmlFor="totalLandArea" className="error-text">{errors.totalLandArea}</label> :
                                <label htmlFor="totalLandArea" className="text-black">Total Land area<span className="required">*</span> </label>
                            }
                            <input
                                type="number"
                                className="form-control form-input-holder"
                                id="totalLandArea" name='totalLandArea'
                                value={totalLandArea}
                                onChange={e => onChange(e)}
                                placeholder="Enter Total Land Area"
                                noValidate
                                onFocus={() => handleError(null, 'totalLandArea')}
                            />
                        </div>
                        <div className="col-md-2">
                            {errors.landAreaType ?
                                <label htmlFor="landAreaType" className="error-text required">{errors.landAreaType}</label> :
                                <label htmlFor="landAreaType" className="text-black">Unit<span className="required">*</span> </label>
                            }
                            <select className="form-select" name="landAreaType"
                                value={bodyData?.landAreaType}
                                onChange={e => onChange(e)}
                                onFocus={() => handleError(null, 'landAreaType')}>
                                <option value=""> Select Unit</option>
                                {
                                    areaUnitData.length > 0 && areaUnitData?.map((data, index) => {
                                        return <option key={index} value={data.rfId}> {data.dataName} </option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-6">
                            {errors.numberOfFloor ?
                                <label htmlFor="numberOfFloor" className="error-text">{errors.numberOfFloor}</label> :
                                <label htmlFor="numberOfFloor" className="text-black">No of Floor<span className="required">*</span> </label>
                            }
                            <input
                                type="number"
                                className="form-control form-input-holder"
                                id="numberOfFloor" name='numberOfFloor'
                                value={numberOfFloor}
                                onChange={e => onChange(e)}
                                placeholder="Enter No of Floors"
                                noValidate
                                onFocus={() => handleError(null, 'numberOfFloor')}
                            />
                        </div>

                    </>
                )}

                <div className="col-md-6">
                    {errors.totalBuildingArea ?
                        <label htmlFor="totalBuildingArea" className="error-text">{errors.totalBuildingArea}</label> :
                        <label htmlFor="totalBuildingArea" className="text-black">{selection === 1 ? "Total Building Area" : "Total Appartment Area"}<span className="required">*</span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="totalBuildingArea" name='totalBuildingArea'
                        value={totalBuildingArea}
                        onChange={e => onChange(e)}
                        placeholder={selection === 1 ? "Enter Total Building Area" : "Enter Total Appartment Area"}
                        noValidate
                        onFocus={() => handleError(null, 'totalBuildingArea')}
                    />
                </div>

                <div className="col-md-6">
                    {errors.numberOfBhk ?
                        <label htmlFor="numberOfBhk" className="error-text">{errors.numberOfBhk}</label> :
                        <label htmlFor="numberOfBhk" className="text-black">No of BHK<span className="required">*</span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="numberOfBhk" name='numberOfBhk'
                        value={numberOfBhk}
                        onChange={e => onChange(e)}
                        placeholder="Enter No of BHK"
                        noValidate
                        onFocus={() => handleError(null, 'numberOfBhk')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.approximateRate ?
                        <label htmlFor="approximateRate" className="error-text">{errors.approximateRate}</label> :
                        <label htmlFor="approximateRate" className="text-black">Enter Expected Rate<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="approximateRate"
                        name="approximateRate"
                        value={bodyData?.approximateRate}
                        onChange={(e) => { onChangeCurrency("approximateRate", e.target.value); }}
                        placeholder="Enter Expected Rate"
                        noValidate
                        onFocus={() => handleError(null, 'approximateRate')}
                    />
                </div>
                <div className="col-md-2">
                    <label htmlFor="valueinL" className="form-label">Value in Lakhs</label>
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        placeholder="In L"
                        id="valueinL"
                        value={`₹ ${valueInL === "" ? "0.00" :
                            (parseInt(valueInL.replace(/[^0-9]/g, "")) / 100000).toFixed(2)} Lakhs`
                        }
                    />
                </div>
                <div className="col-md-4">
                    {errors.address ?
                        <label htmlFor="address" className="error-text">{errors.address}</label> :
                        <label htmlFor="address" className="text-black">Address<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="address" name='address'
                        value={bodyData?.address}
                        onChange={e => onChange(e)}
                        placeholder="Enter Address"
                        noValidate
                        onFocus={() => handleError(null, 'address')}
                    />
                </div>
                <div className="col-md-2 btn-box">
                    <button type="button" className="btn btn-info btn-pickLocation" onClick={() => setShowMapModel(!showMapModel)}>
                        Pick Location
                    </button>
                </div>

                <div className="col-md-6">
                    {errors.location ?
                        <label htmlFor="location" className="error-text">{errors.location}</label> :
                        <label htmlFor="location" className="text-black">Location<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="location" name='location'
                        value={bodyData?.location}
                        onChange={e => onChange(e)}
                        placeholder="Location"
                        noValidate
                        onFocus={() => handleError(null, 'location')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.districtId ?
                        <label htmlFor="districtId" className="error-text required">{errors.districtId}</label> :
                        <label htmlFor="districtId" className="text-black">District<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="districtId" style={{ fontSize: '14px' }}
                        value={bodyData?.districtId}
                        onChange={e => onChangeDistrict(e.target.value)}
                        onFocus={() => handleError(null, 'districtId')}>
                        <option value=""> Select District </option>
                        {
                            districtData.length > 0 && districtData?.map((data, index) => {
                                return <option key={index} value={data.rfId}> {data.districtName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-2">
                    {errors.pincode ?
                        <label htmlFor="pincode" className="error-text">{errors.pincode}</label> :
                        <label htmlFor="pincode" className="text-black">Pincode<span className="required">*</span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="pincode" name='pincode'
                        value={bodyData?.pincode}
                        onInput={(e) => {
                            if (e.target.value.length > 6) {
                                e.target.value = e.target.value.slice(0, 6);
                            }
                        }}
                        onChange={e => onChange(e)}
                        placeholder="Enter Pincode"
                        noValidate
                        onFocus={() => handleError(null, 'pincode')}
                    />
                </div>
                <fieldset className="mb-3 mt-4 d-flex align-items-center">
                    <label className="form-label mr-5">Posted by</label>
                    <div className="d-flex align-items-center">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input mt-1" type="radio" name="postedBy" id="postedBy" value="1" onChange={e => onChange(e)} checked={postedBy == 1 && 1} />
                            <label className="form-check-label" htmlFor="gridRadios1">
                                Owner
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input mt-1" type="radio" name="postedBy" id="postedBy" value="0" onChange={e => onChange(e)} checked={postedBy == 0 && 1} />
                            <label className="form-check-label" htmlFor="gridRadios2">
                                Agent
                            </label>
                        </div>
                    </div>
                </fieldset>
                <div>
                    <Button
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', backgroundColor: '#fdb447', color: 'white' }}
                        onClick={toggleCollapsible}
                    >
                        More Option
                        {moreOption ? <FaAngleUp style={{ marginLeft: '5px' }} /> : <FaAngleDown style={{ marginLeft: '5px' }} />}
                    </Button>
                </div>
                <Collapsible trigger="" open={moreOption}>
                    <div className='row property-form'>
                        <div className="col-md-6 mb-4">
                            <label for="dtdcNumber" className="form-label">DTCP Number</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="dtdcNumber" name='dtdcNumber'
                                value={dtdcNumber} onChange={e => onChange(e)}
                                placeholder="Enter DTCP Number"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="reraNumber" className="form-label">RERA Number</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="reraNumber" name='reraNumber'
                                value={reraNumber} onChange={e => onChange(e)}
                                placeholder="Enter RERA Number"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="mailId" className="form-label">Mail ID</label>
                            <input type="email"
                                className="form-control form-input-holder"
                                id="mailId" name='mailId'
                                value={mailId} onChange={e => onChange(e)}
                                placeholder="Enter Email ID"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="youtubeLink" className="form-label">Youtube Link</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="youtubeLink" name='youtubeLink'
                                value={mediaData?.youtubeLink} onChange={e => onChangeMedia(e)}
                                placeholder="Enter Youtube Link"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="instaLink" className="form-label">Instagram Link</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="instaLink" name='instaLink'
                                value={mediaData?.instaLink} onChange={e => onChangeMedia(e)}
                                placeholder="Enter Instagram Link"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="facebookLink" className="form-label">Facebook Link</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="facebookLink" name='facebookLink'
                                value={mediaData?.facebookLink} onChange={e => onChangeMedia(e)}
                                placeholder="Enter Youtube Link"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="facebookLink" className="form-label">Website Link</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="websiteLink" name='websiteLink'
                                value={mediaData?.websiteLink} onChange={e => onChangeMedia(e)}
                                placeholder="Enter Website Link"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="linkedLink" className="form-label">Linked Link</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="linkedLink" name='linkedLink'
                                value={mediaData?.linkedLink} onChange={e => onChangeMedia(e)}
                                placeholder="Enter Instagram Link"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="others" className="form-label">Others</label>
                            <input type="text"
                                className="form-control form-input-holder"
                                id="others" name='others'
                                value={mediaData?.others} onChange={e => onChangeMedia(e)}
                                placeholder="Enter Other Links if any.."
                            />
                        </div>
                        <h5 className='mt-2'>More Contact Persons</h5>
                        {inputList.map((x, i) => {
                            return (
                                <>
                                    <div className="box col-md-2">
                                        <label htmlFor="partner" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-input-holder"
                                            id="name" name='name'
                                            placeholder="Enter Name"
                                            value={x.name}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </div>
                                    <div className="box col-md-2">
                                        <label htmlFor="partner" className="form-label">Mobile No</label>
                                        <input
                                            type="text"
                                            className="form-control form-input-holder"
                                            id="partner" name='partner'
                                            placeholder="Enter Mobile Number"
                                            value={x.partner}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </div>
                                    <div className="btn-box col-md-2">
                                        {inputList.length !== 1 && (
                                            <div className='d-flex align-self-end'>
                                                <Button
                                                    className='btn btn-remove'
                                                    onClick={() => handleRemoveClick(i)}
                                                    style={{ backgroundColor: '#D97D7A', marginRight: 10, padding: 10 }}
                                                >
                                                    <AiFillMinusCircle size={23} color='white' />
                                                </Button>
                                            </div>

                                        )}
                                        {inputList.length - 1 === i && (
                                            <div className='d-flex align-self-end'>
                                                <Button
                                                    className='btn btn-add'
                                                    onClick={handleAddClick}
                                                    style={{ backgroundColor: '#3786fb', padding: 10 }}
                                                >
                                                    <IoMdAddCircle size={23} color='white' />
                                                </Button>
                                            </div>
                                        )}
                                    </div>

                                </>
                            );
                        })}
                    </div>
                </Collapsible>
                <div className='upload-conatiner-postProperty'>
                    <UploadImage
                        fileOnSet={fileOnSet}
                        name="siteMap"
                        error={""}
                        editData={bodyData?.siteMap == "null" || bodyData?.siteMap == "" || bodyData?.siteMap == null ? "null" : bodyData?.siteMap}
                    >
                        Upload Site Image
                    </UploadImage>
                </div>
                <div className="col-md-12 text-center my-5">
                    <LoadingButton
                        loading={sending}
                        onClick={validate}
                        variant="contained"
                        sx={{ px: 6 }}
                        style={{ backgroundColor: '#3786FB', height: '45px', textTransform: 'none' }}
                        loadingIndicator={<CircularProgress size={15} sx={{ color: 'white' }} />}
                    >
                        {bodyData?.rfId !== "" ? "Update Property" : "Create Property"}
                    </LoadingButton>
                </div>
                <Modal
                    show={showMapModel}
                    onHide={() => setShowMapModel(!showMapModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Google Map</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PickLocation onChangeLocation={onChangeLocation} districtData={districtData} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowMapModel(!showMapModel)}>
                            Add Location
                        </Button>
                        <Button variant="secondary" onClick={() => setShowMapModel(!showMapModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showAddLandOwnerModel}
                    onHide={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}
                    backdrop="true"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Add Landowner</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddOwner />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}>
                            Add
                        </Button>
                        <Button variant="secondary" onClick={() => setShowAddLandOwnerModel(!setShowAddLandOwnerModel)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </form>

        </>
    )
}

export default CreateHouse