import React from 'react';
import arrowright from '../../../../images/Dashboard/arrowright.png';
import PaymentDataTable from './PaymentDataTable';

export default function Payments({ paymentdetails }) {

    return (
        <>
            <p className='listings-heading'>Payments</p>
            <PaymentDataTable />
            <div className='d-flex mt-lg-2 flex-row-reverse next-btn-container'>
                <button className='btn next-cus-btn' onClick={() => paymentdetails(true, "Add List")}> Next <img src={arrowright} alt="" /></button>
            </div>
        </>

    )
}
