import axios from 'axios';
import { DASHBOARD_VIEW, DASHBOARD_FAIL, DASHBOARD_SUCCESS, DASHBOARD_DELETE } from  "../type/types";
import { Url, getTokenLocalStorage } from '../../utils/Common';
import swal from 'sweetalert';

// Otp Verification
export const getDashboardData = () => async dispatch =>{
    const token  = getTokenLocalStorage();
    const formData = {
        "action" : "Dashboard",
        "userRfId" : "",
        "limit" : 1,
        "order" : "desc",
        "type" : "Admin"
    }
    const headers = {"x-auth-header" : `${token}` }         
    axios.post(`${Url}member`,formData , { headers } ).then((response)=>{     
        dispatch({
            payload : response,
            type : DASHBOARD_SUCCESS
        })      
    }).catch((err)=>{                  
        dispatch({
            payload : err.response,
            type : DASHBOARD_FAIL
        })
    })   
}
