import { GLOBAL_SEARCH_FAIL, GLOBAL_SEARCH_SUCCESS, SET_LOADING, SET_GLOBAL_LOADING } from "../type/types";

const initialState = {
    loading: true,
    userRfId: "",
    userMobileNo: "",
    viewSearchData: [],
    error: {}
}


export const GlobalSearchReducer = (state = initialState, action) => {
    const { type, payload, userRfId, userMobileNo } = action;
    switch (type) {
        case SET_GLOBAL_LOADING:
            return {
                ...state,
                loading: payload
            };
        case GLOBAL_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                viewSearchData: payload,
                userRfId: userRfId,
                userMobileNo: userMobileNo
            }
        case GLOBAL_SEARCH_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
                userRfId: userRfId,
                userMobileNo: userMobileNo
            }
        default:
            return state;

    }

}