import React from 'react';

const Privacy = () => {

    return (<>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="jumbotron">
                        <h1> Privacy Policy </h1>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Property QR app is a commercial app owned and managed by Managing Partner
                            Mr.Ragupathi Thangaraj. This privacy policy (the “Privacy Policy”) explains our policies
                            regarding collection, use, and disclosure of your Personal Information (as defined in the SPDI
                            Rules) and other data collected from you when you avail the Services offered by My Property
                            QR.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For this Privacy Policy (“Policy”), wherever the context so requires,<br />
                            i) The term 'You' & 'User' shall mean any legal person or entity accessing or using the
                            services provided on our platform.<br />
                            ii) The terms 'We', 'Us' & 'Our' shall mean our platform and/or the Company, as the context
                            so requires. <br />
                            iii) The terms 'Party' & 'Parties' shall respectively be used to refer to the User and the
                            Company individually and collectively, as the context so requires.
                        </p>
                        <h2>INFORMATION THAT MAY BE COLLECTED BY US</h2>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We collect the following general types of information about you when you use our app:
                            Personal Identification Information <br />
                            Personal identification Information (PII) means any information that may be used to
                            identify an individual, including: <br />
                            Name, email address, postal address, phone number and location, Gender, age, job profile,
                            educational qualification, salary details, skills, languages known and resume including job
                            experience Personal information that is available on the internet, such as from Facebook,
                            LinkedIn, Twitter and Google, or publicly available information that we acquire from service
                            providers. <br />
                            Non-personal Identification Information <br />
                            We may collect non-personal identification information about users whenever they
                            interact with our site. Non-personal identification information may include:
                            The type of mobile phone Technical information about users, such as the operating system
                            The Internet service providers utilised IP addresses and other similar information.
                        </p>

                        <h3>Usage and Technical Information</h3>

                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We collect information about how you interact with our App Service. This
                            information may include your IP address, geographical location, browser type, referral
                            source, length of visit, pages viewed and items clicked. Other information includes: <br />
                            <b>Location Information:</b> When you give location permission, we access information that is
                            derived from your GPS. We may use third-party cookies and similar technologies to collect
                            some of this information.<br />
                            <b>Storage:</b> When you opt-in for storage permission, we access your device storage like gallery
                            photos.<br />
                            <b>Camera:</b> Granting camera permission allows us to access the photo that you click to be
                            displayed on your profile.<br />
                            <b>Microphone:</b> Voice and audio information when you use audio features.
                            Apart from the information listed above, we do not collect any sensitive information such as
                            political opinions, health-related information or any information related to race, caste or
                            religion (collectively, “Sensitive Personal Information”), unless provided by you voluntarily
                            or required by provisions of any applicable law.</p>

                        <h2>HOW DO WE USE THE INFORMATION ?</h2>

                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We use your PII and other information collected from you for the following purposes:<br />
                            ➤ To provide you with the functionalities of our Services in a proper manner.<br />
                            ➤ To track your usage of our services and customize them accordingly to best suit your
                            requirements.<br />
                            ➤ To send you communications, as per your notification settings, on any updates or
                            developments pertaining to our Services or your engagement with us over the Application.<br />
                            ➤ To provide you with location-based services like finding jobs near you.<br />
                            ➤ To provide you with support services, whether through email, phone, push notifications or
                            messages through the application.<br />
                            ➤ To provide you with technical support, including updating application.<br />
                            ➤ For the purposes of auditing, research and analysis to ensure the stability of our services.<br />
                            ➤ To provide information to third-parties performing services in support of our services<br />
                            ➤ To prevent and detect any fraud or other illegal activity carried on or through our services,
                            and to prevent violations of our Terms of Use.<br />
                            ➤ To respond to legal requirements, exercise our legal rights and defend against any legal
                            claims, to protect our interests or those of any third parties.</p>

                        <h2>HOW WE PROTECT YOUR INFORMATION?</h2>
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We adopt appropriate data collection, storage and processing practices and security
                            measures to protect against unauthorised access, alteration, disclosure or destruction of your
                            information, username, password, transaction information and data stored on our application.
                            We have physical, electronic, and procedural safeguards that comply with the laws prevalent
                            in India to protect personal information about you.</p>
                        <h2>SHARING YOUR PERSONAL INFORMATION</h2>
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We do not sell, trade, or rent user’s personal identification information to any third
                            party. However, the app does use third-party services that may collect information used to
                            identify you. <br />
                            We do not disclose, transfer or share your personal information with others except with:
                            Our affiliates and group companies to the extent required for our internal business and/or
                            administrative purposes and/or general corporate operations and for provision of services
                            aimed at helping you in your career enhancement; <br />
                            Plots, Lands and Houses posted on My Property QR by respective owners/brokers for
                            sale/Purchase. <br />
                            Clients/Customers who have posted their respective Plots, Lands and Houses on My Property
                            QR shall propagate their Contact details alone (mobile/phone number given by the
                            Clients/Customers) to the viewers only through our App (My Property QR)
                            Potential Customers who are willing to purchase Plots, Lands and Houses of their wish shall
                            contact to the respective Plots, Lands and Houses owners. <br /> <br />
                            Third parties including enforcement, regulatory and judicial authorities, if we determine that
                            disclosure of your personal information is required to <br />
                            a) respond to court orders, or legal process, or to establish or exercise our legal rights or
                            defend against legal claims; or <br />
                            b) investigate, prevent, or take action regarding illegal activities, suspected fraud, situations
                            involving potential threats to the physical safety of any person, violations of our Terms of
                            Service or as otherwise required by law <br />
                            We will take reasonable steps to ensure that these third-party service providers are obligated
                            to protect your information and are also subject to appropriate confidentiality / non-disclosure
                            obligations. <br />
                            By registering on the Platform and consenting to the terms of this Privacy Policy, you agree
                            that we may contact you or share your contact details with the candidates/employers for the
                            App Services.
                        </p>

                        <h2>COOKIE POLICY</h2>
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We use cookies for a variety of purposes, including remembering you and your
                            preferences, tracking your use of our Platform, and making it easier for you to use it. Most
                            browsers accept cookies by default, but you can usually change your browser settings to
                            decline cookies if you prefer.</p>
                        <h2>CHILDREN'S PRIVACY</h2>
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The My Property QR App Services do not address anyone under the age of 18. We do
                            not knowingly collect personally identifiable information from children under 18. In the case
                            we discover that a child under 18 has provided us with personal information, we immediately
                            delete this from our App.</p>

                        <h2>  Return & Refund Policy: </h2>
                        <p>We would like to inform our users that our website does not have a return and refund policy. Given the nature of our services, which primarily involve the provision of information and online interactions, we do not engage in the sale or exchange of physical products. Therefore, there are no applicable return or refund procedures.</p>

                        <h2> Cancellation Policy:</h2>
                        <p>Kindly note that our website does not implement a cancellation policy. As our services are not subscription-based and users have open access to our platform without any contractual commitments, there is no need for a specific cancellation procedure. Users are free to engage with our platform as they deem fit without concerns about cancellation or termination of services.</p>
                        <h2>Shipping Policy</h2>
                        <p>1. Invoice shall be generated for Building Approval, once we verify your Documents. Unless, no payment shall be made.</p>

                        <p>2. Any delay or hindrance in uploading the document in Government Website for 10 working days, payment recieved from the client shall be refunded on 11th day.</p>

                        <p>3. Once documents are uploaded, Incubation period of 10 working days shall be taken in account. No refund shall be encouraged during Incubation period.</p>

                        <p>4. Any additional documents requested by the Department during queries, such documents should be submitted by the client to us immediately with 24 hours from intimation time.</p>
                        <h2>YOUR RIGHTS ABOUT USE OF YOUR INFORMATION</h2>
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You can: <br /> <br />
                            Review, modify, add, correct or update your Information on a regular basis. However, we are
                            not responsible for the authenticity of the Personal Information provided. <br />
                            Agree and acknowledge that certain data or information cannot be corrected or deleted or is
                            prohibited to be deleted as required under any applicable law, law enforcement requests or
                            under any judicial proceedings. Opt-out by writing to us at email mypropertyqr@gmail.com, if previously
                            you opted to receive newsletters, commercial e-mails or other communications from My
                            Property QR, but subsequently change your mind. <br />
                            We may: <br /> <br />
                            Retain your personal data even after you have requested deletion of your account or personal
                            information if reasonably necessary to comply with our legal obligations (including law
                            enforcement requests). No longer be able to provide our services after your account is
                            deactivated or you ask to close your account. If you would like us to delete your account from
                            our database, you can do so through your account 'user settings'.</p>
                        <h2>CHANGES TO OUR PRIVACY POLICY</h2>
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We reserve the unrestricted right to update, modify, add, or remove any part of this
                            Privacy Policy at any time and without prior notice. Any updates or modifications will take
                            effect immediately. You should check our Privacy Policy for updates on a regular basis. If we
                            make any significant changes, we will make every effort to provide you with reasonable
                            notice of such changes, such as through prominent notice on the platform or to your email
                            address or registered contact number etc and we will obtain your consent where required by
                            applicable law</p>

                    </div>

                </div>
            </div>
        </div>
    </>)

}

export default Privacy;