import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { TiArrowBack } from 'react-icons/ti';
import { useHistory, useLocation } from 'react-router-dom';
import { getDistrictData } from '../../../../store/action/BindAction';
import { getPermissionData } from '../../../../store/action/PermissionAction';
import Select from "react-select";
import UploadFile from '../Others/UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { saveMarketingStaff } from '../../../../store/action/StaffAction';
import Collapsible from 'react-collapsible';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import PickLocation from '../Others/PickLocation';

function AddStaff(props) {

    const data = props?.location?.state?.item
    // console.log(`AddStaff data = ${JSON.stringify(data)}`)

    let history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(state => state?.BindReducer);
    const permissionSelector = useSelector(state => state?.PermissionReducer);

    const [showMapModel, setShowMapModel] = useState(false);
    const [moreOption, setMoreOption] = useState(false);
    const [errors, setErrors] = useState({});
    const [aadharCard, setAadharCard] = useState(null);
    const [panCard, setPanCard] = useState(null);
    const [passBook, setPassBook] = useState(null);
    const [districtData, setDistrictData] = useState([]);
    const [permissionData, setPermissionData] = useState([]);
    const [selectPermissionData, setSelectPermissionData] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [bodyData, setBodyData] = useState({
        rfId: data?.rfId || "",
        userName: data?.userName || "",
        mobileNo: data?.mobileNo?.toString() || "",
        whatsAppNumber: data?.whatsAppNumber != null ? data?.whatsAppNumber.toString() : "",
        stateId: data?.stateId || 1,
        districtId: data?.districtId || "",
        blockId: data?.blockId || 1,
        address: data?.address || "",
        pincode: data?.pincode || "",
        registerType: data?.registerType || 4,
        aadharNumber: data?.aadharNumber != null ? data?.aadharNumber?.toString() : "",
        panNumber: data?.panNumber != null ? data?.panNumber?.toString() : "",
        accountNumber: data?.accountNumber != null ? data?.accountNumber?.toString() : "",
        ifscCode: data?.ifscCode || "",
        gender: data?.gender || 1,
        permission: data?.permission != "" && data?.permission != null ? data?.permission?.split(",").map(a => parseInt(a)) : [],
        loginUserId: data?.loginUserId || "",
        remarks: "",
        action: "Insert",
        subMemberType: 1,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getDistrictData(1));
            await dispatch(getPermissionData())
        }
        fetchData()
    }, []);

    // console.log(`permissionData = ${JSON.stringify(permissionData)}`)

    const { rfId, userName, mobileNo, whatsAppNumber, stateId, districtId, blockId, address, pincode, location, registerType, aadharNumber, panNumber, accountNumber, ifscCode, gender, permission, } = bodyData

    useEffect(() => {
        if (selector?.districtData?.data?.alert == "District Details") {
            setDistrictData(selector?.districtData?.data?.data)
        }
        if (permissionSelector?.payload?.data?.alert === "Permission") {
            setPermissionData(permissionSelector?.payload?.data?.data?.map((res) => ({
                label: res.dataName,
                value: res.dataId
            })));

            if (permission?.length > 0) {
                const selected = permissionSelector?.payload?.data?.data?.filter((data1) =>
                    permission.includes(data1.dataId)
                ).map((data1) => ({
                    label: data1.dataName,
                    value: data1.dataId
                }));

                setSelectedPermissions(selected.map((item) => item.value));
            }
        }
    }, [selector?.payload?.data, permissionSelector?.payload?.data])

    const handleCheckboxChange = (value) => {
        let updatedPermissions;
        if (selectedPermissions.includes(value)) {
            updatedPermissions = selectedPermissions.filter((permission) => permission !== value);
        } else {
            updatedPermissions = [...selectedPermissions, value];
        }
        setSelectedPermissions(updatedPermissions);
        setBodyData((prevBodyData) => ({
            ...prevBodyData,
            permission: updatedPermissions
        }));
    };

    const handleAddFormSubmit = async () => {
        await dispatch(saveMarketingStaff(bodyData, history))
    }

    const onChange = e => {
        setBodyData({ ...bodyData, [e.target.name]: e.target.value });
    }

    const handleError = (error, input) => {
        setErrors(prevState => ({ ...prevState, [input]: error }));
    };

    const validate = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!bodyData.userName || bodyData.userName.length < 3) {
            handleError('Name must be min 3 character long', 'userName');
            isValid = false;
        }

        if (
            !bodyData.mobileNo ||
            isNaN(bodyData.mobileNo) ||
            (bodyData.mobileNo.toString().length !== 10 ||
                bodyData.mobileNo.toString().includes('.'))
        ) {
            handleError('Enter valid number', 'mobileNo');
            isValid = false;
        }
        if (
            !bodyData.whatsAppNumber ||
            isNaN(bodyData.whatsAppNumber) ||
            (bodyData.whatsAppNumber.toString().length !== 10 ||
                bodyData.whatsAppNumber.toString().includes('.'))
        ) {
            handleError('Enter valid number', 'whatsAppNumber');
            isValid = false;
        }

        if (!bodyData.aadharNumber || bodyData.aadharNumber.length !== 12) {
            handleError('Aadhar Number must be 12 digits long', 'aadharNumber');
            isValid = false;
        }

        if (!bodyData.panNumber || bodyData.panNumber.length !== 10) {
            handleError('Pan Number must be 10 digits long', 'panNumber');
            isValid = false;
        }

        if (!bodyData.accountNumber || bodyData.accountNumber.length < 8) {
            handleError('A/C No must be min 8 character long', 'accountNumber');
            isValid = false;
        }

        if (!bodyData.ifscCode || bodyData.ifscCode.length !== 11) {
            handleError('IFSC code must be 11 character long', 'ifscCode');
            isValid = false;
        }

        if (!bodyData.address) {
            handleError('Enter address', 'address');
            isValid = false;
        }

        // if (!bodyData.location) {
        //     handleError('Enter location', 'location');
        //     isValid = false;
        // }

        if (!bodyData.districtId) {
            handleError('Select District', 'districtId');
            isValid = false;
        }

        // if (
        //     !bodyData.pincode ||
        //     isNaN(bodyData.pincode) ||
        //     (bodyData.pincode.toString().length !== 6 ||
        //         bodyData.pincode.toString().includes('.'))
        // ) {
        //     handleError('Enter Pincode', 'pincode');
        //     isValid = false;
        // }

        if (isValid) {
            handleAddFormSubmit(e);
            // console.log(`validate submit activated .... = ${JSON.stringify(bodyData)}`)
        }
    }


    const onChangeLocation = (villageFinderData, address, location, pincode, districtId) => {
        // console.log(`villageFinderData = ${JSON.stringify(villageFinderData)}`)
        setBodyData({
            ...bodyData,
            latitude: villageFinderData?.latitude,
            longitude: villageFinderData?.longitude,
            address: address,
            location: location,
            pincode: pincode,
            districtName: villageFinderData?.district_name,
            districtId: districtId,
        });
    }

    const toggleCollapsible = () => {
        setMoreOption(!moreOption);
    };

    return (
        <>
            <div className='d-flex mt-2'>
                <Button className='p-0'><TiArrowBack onClick={() => history.goBack()} size={35} /></Button>
                <p className='listings-headingWithBack'>{bodyData?.rfId !== "" ? "Update Staff" : "Add Staff"}</p>
            </div>

            <form className="row g-3 property-form ml-2 mr-2 mt-3"
                onSubmit={validate}
                noValidate
            >
                <div className="col-md-6">
                    {errors.userName ?
                        <label htmlFor="userName" className="error-text required">{errors.userName}</label> :
                        <label htmlFor="userName" className="text-black">Staff Name<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="userName" name='userName'
                        value={userName}
                        onChange={e => onChange(e)}
                        placeholder="Enter Staff Name"
                        noValidate
                        onFocus={() => handleError(null, 'userName')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.mobileNo ?
                        <label htmlFor="mobileNo" className="error-text">{errors.mobileNo}</label> :
                        <label htmlFor="mobileNo" className="text-black">Mobile Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="mobileNo" name='mobileNo'
                        value={mobileNo}
                        maxLength={10}
                        onChange={e => onChange(e)}
                        placeholder="Enter Mobile Number"
                        noValidate
                        onFocus={() => handleError(null, 'mobileNo')}
                        disabled={data?.mobileNo}
                    />
                </div>
                <div className="col-md-6">
                    {errors.whatsAppNumber ?
                        <label htmlFor="whatsAppNumber" className="error-text">{errors.whatsAppNumber}</label> :
                        <label htmlFor="whatsAppNumber" className="text-black">Whatsapp Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="whatsAppNumber" name='whatsAppNumber'
                        value={whatsAppNumber}
                        onChange={e => onChange(e)}
                        maxLength={10}
                        placeholder="Enter WhatsApp Number"
                        noValidate
                        onFocus={() => handleError(null, 'whatsAppNumber')}
                        disabled={data?.whatsAppNumber}
                    />
                </div>
                <div className="col-md-6">
                    <fieldset className="mt-5 d-flex align-content-end">
                        <label className="form-label mr-5">Gender</label>
                        <div className="d-flex align-items-center">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input mt-1" type="radio" name="gender" id="gender" value="1" onChange={e => onChange(e)} checked={gender == 1 && 1} />
                                <label className="form-check-label" htmlFor="gridRadios1">
                                    Male
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input mt-1" type="radio" name="gender" id="gender" value="0" onChange={e => onChange(e)} checked={gender == 0 && 1} />
                                <label className="form-check-label" htmlFor="gridRadios2">
                                    Female
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    {errors.address ?
                        <label htmlFor="address" className="error-text">{errors.address}</label> :
                        <label htmlFor="address" className="text-black">Address<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="address" name='address'
                        value={bodyData?.address}
                        onChange={e => onChange(e)}
                        placeholder="Enter Address"
                        noValidate
                        onFocus={() => handleError(null, 'address')}
                    />
                </div>
                <div className="col-md-2 btn-box">
                    <button type="button" className="btn btn-info btn-pickLocation" onClick={() => setShowMapModel(!showMapModel)}>
                        Pick Location
                    </button>
                </div>

                <div className="col-md-6">
                    {errors.location ?
                        <label htmlFor="location" className="error-text">{errors.location}</label> :
                        <label htmlFor="location" className="text-black">Location<span className="required"></span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="location" name='location'
                        value={bodyData?.location}
                        onChange={e => onChange(e)}
                        placeholder="Location"
                        noValidate
                        onFocus={() => handleError(null, 'location')}
                    />
                </div>
                <div className="col-md-4">
                    {errors.districtId ?
                        <label htmlFor="districtId" className="error-text required">{errors.districtId}</label> :
                        <label htmlFor="districtId" className="text-black">District Name<span className="required">*</span> </label>
                    }
                    <select className="form-select" name="districtId"
                        value={bodyData?.districtId}
                        onChange={e => onChange(e)}
                        onFocus={() => handleError(null, 'districtId')}
                        style={{ fontSize: '14px' }}>
                        <option value="" > Select District </option>
                        {
                            districtData.length > 0 && districtData?.map((data, index) => {
                                return <option key={index} value={data.rfId} > {data.districtName} </option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-2">
                    {errors.pincode ?
                        <label htmlFor="pincode" className="error-text">{errors.pincode}</label> :
                        <label htmlFor="pincode" className="text-black">Pincode<span className="required"></span> </label>
                    }
                    <input
                        type="number"
                        className="form-control form-input-holder"
                        id="pincode" name='pincode'
                        value={bodyData?.pincode}
                        onInput={(e) => {
                            if (e.target.value.length > 6) {
                                e.target.value = e.target.value.slice(0, 6);
                            }
                        }}
                        onChange={e => onChange(e)}
                        placeholder="Enter Pincode"
                        noValidate
                        onFocus={() => handleError(null, 'pincode')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.aadharNumber ?
                        <label htmlFor="aadharNumber" className="error-text">{errors.aadharNumber}</label> :
                        <label htmlFor="aadharNumber" className="text-black">Aadhar Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="aadharNumber" name='aadharNumber'
                        value={aadharNumber}
                        maxLength={12}
                        onChange={e => onChange(e)}
                        placeholder="Enter Aadhar Number"
                        noValidate
                        onFocus={() => handleError(null, 'aadharNumber')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.panNumber ?
                        <label htmlFor="panNumber" className="error-text">{errors.panNumber}</label> :
                        <label htmlFor="panNumber" className="text-black">Pan Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="panNumber" name='panNumber'
                        value={panNumber}
                        maxLength={10}
                        onChange={e => onChange(e)}
                        placeholder="Enter Pan Number"
                        noValidate
                        onFocus={() => handleError(null, 'panNumber')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.accountNumber ?
                        <label htmlFor="accountNumber" className="error-text">{errors.accountNumber}</label> :
                        <label htmlFor="accountNumber" className="text-black">Account Number<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="accountNumber" name='accountNumber'
                        value={accountNumber}
                        onChange={e => onChange(e)}
                        maxLength={20}
                        placeholder="Enter Account Number"
                        noValidate
                        onFocus={() => handleError(null, 'accountNumber')}
                    />
                </div>
                <div className="col-md-6">
                    {errors.ifscCode ?
                        <label htmlFor="ifscCode" className="error-text">{errors.ifscCode}</label> :
                        <label htmlFor="ifscCode" className="text-black">IFSC Code<span className="required">*</span> </label>
                    }
                    <input
                        type="text"
                        className="form-control form-input-holder"
                        id="ifscCode" name='ifscCode'
                        value={ifscCode}
                        onChange={e => onChange(e)}
                        maxLength={11}
                        placeholder="Enter IFSC Code"
                        noValidate
                        onFocus={() => handleError(null, 'ifscCode')}
                    />
                </div>
                <div className="col-md-12">
                    {/* <label htmlFor="permission" className="text-black">Permission</label>
                    {permissionData.length > 0 &&
                        <Select isMulti
                            defaultValue={bodyData?.rfId != "" ? selectPermissionData : 'Select'}
                            options={permissionData}
                            closeMenuOnSelect={false}
                            onChange={handleChange}
                        />
                    } */}
                    <label htmlFor="permission" className="text-black">Permission</label>
                    <div className="grid-container">
                        {permissionData.length > 0 &&
                            permissionData.map((permission, index) => (
                                <div className="grid-item" key={index}>
                                    <input
                                        type="checkbox"
                                        id={permission.value}
                                        value={permission.value}
                                        checked={selectedPermissions.includes(permission.value)}
                                        onChange={() => handleCheckboxChange(permission.value)}
                                    />
                                    {/* <label style={{ marginLeft: '8px', marginTop: '7px' }}>
                                        {permission.value}
                                    </label> */}
                                    <label htmlFor={permission.value} style={{ marginLeft: '8px', marginTop: '7px' }}>
                                        {permission.label}
                                    </label>
                                </div>
                            ))
                        }
                    </div>

                </div>
                <div>
                    <Button
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', backgroundColor: '#fdb447', color: 'white' }}
                        onClick={toggleCollapsible}
                    >
                        More Option
                        {moreOption ? <FaAngleUp style={{ marginLeft: '5px' }} /> : <FaAngleDown style={{ marginLeft: '5px' }} />}
                    </Button>
                    <Collapsible trigger="" open={moreOption}>
                        <div className='upload-conatiner-postProperty'>
                            <UploadFile setDocs={setAadharCard}>Upload Aadhar Card</UploadFile>
                            <UploadFile setDocs={setPanCard}>Upload Pan Card</UploadFile>
                            <UploadFile setDocs={setPassBook}>Upload Passbook</UploadFile>
                        </div>
                    </Collapsible>
                </div>
                <div className="col-md-12 text-center my-5">
                    <button className="btn btn-primary px-5" type="submit">{bodyData?.rfId !== "" ? "Update Staff" : "Add Staff"}</button>
                </div>
            </form>
            <Modal
                show={showMapModel}
                onHide={() => setShowMapModel(!showMapModel)}
                backdrop="true"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Google Map</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PickLocation onChangeLocation={onChangeLocation} districtData={districtData} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowMapModel(!showMapModel)}>
                        Confirm Location
                    </Button>
                    <Button variant="secondary" onClick={() => setShowMapModel(!showMapModel)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddStaff