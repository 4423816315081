import { useState } from 'react';
import React from 'react'
import { DtSentenceCase, GetLandUrl } from '../../../../utils/Common'
import Button from '@mui/material/Button';
import { FaFacebookSquare } from "react-icons/fa";
import { MdFeaturedPlayList } from "react-icons/md";
import { FaSquareYoutube } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaBlogger } from "react-icons/fa";
import swal from 'sweetalert';
import RateUnit from '../../../../utils/RateUnit';

function PropertyDetailPage({ item }) {

    // console.log(`PropertyDetailPage Data = ${JSON.stringify(item)}`)

    const [mediaLinks, setMediaLinks] = useState(item?.media != "" && item?.media != undefined ? JSON.parse(item?.media) : null);

    const openSocialMediaLink = (link) => {
        try {
            if (link && typeof link === 'string') {
                const formattedLink = link.startsWith('http') ? link : `https://${link}`;
                const isValidURL = (url) => {
                    try {
                        new URL(url);
                        return true;
                    } catch (e) {
                        return false;
                    }
                };

                if (isValidURL(formattedLink)) {
                    window.open(formattedLink, '_blank');
                } else {
                    swal({
                        title: "Error",
                        text: "Invalid Link",
                        icon: "error",
                        showCancelButton: true,
                        dangerMode: true,
                    })
                }
            } else {
                swal({
                    title: "Error",
                    text: "Invalid Link",
                    icon: "error",
                    showCancelButton: true,
                    dangerMode: true,
                })
            }
        } catch (error) {
            // console.error('Error opening social media link:', error);
            swal({
                title: "Error",
                text: "Invalid Link",
                icon: "error",
                showCancelButton: true,
                dangerMode: true,
            })
        }
    };


    return (
        <>
            <div className='DetailPage-container'>
                {item?.siteMap == "null" || item?.siteMap == "" || item?.siteMap == null ?
                    <img className='image mt-3'
                        src={GetLandUrl(item?.layoutType, item?.landType)}
                        alt="Logo"
                    /> :
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            {item?.siteMap && JSON.parse(item?.siteMap).map((url, index) => (
                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                    <img className='image mt-3' src={url} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {item?.dtcpApprovedLayout == "null" || item?.dtcpApprovedLayout == "" || item?.dtcpApprovedLayout == null ?
                    "" : <Button variant="contained" size="small" style={{ textTransform: 'none', color: "white", alignSelf: 'center', backgroundColor: '#28d7a0' }}
                        onClick={() => window.open(item?.dtcpApprovedLayout)}
                    >Download Layout Map</Button>
                }
                <div className="row">
                    <div className="col">
                        <div className='m-4'>
                            <div className='mb-1'>
                                <span className='PropertyName-heading'>Property Name:  </span><span className='PropertyName-details'>{item?.layoutName}</span>
                            </div>
                            <div className='mb-1'>
                                <span className='PropertyName-heading'>Property Type:  </span><span className='PropertyName-details'>{item?.landTypeName}</span>
                            </div>
                            <div className='mb-1'>
                                <span className='PropertyName-heading'>Posted By:  </span><span className='PropertyName-details'>{item?.layoutOwnerTypeName}</span>
                            </div>
                            <div className='mb-1'>
                                <span className='PropertyName-heading'>{item?.layoutOwnerTypeName} Number: </span><span className='PropertyName-details'>{item?.contactNumber}</span>
                            </div>
                            <div className='mb-1'>
                                <span className='PropertyName-heading'>Address:  </span><span className='PropertyName-details'>{item?.address}, {item?.location}, {DtSentenceCase(item?.districtName)} - {item?.pincode}</span>
                            </div>
                            <div className='mb-1'>
                                <span className='PropertyName-heading'>DTDC Approved:  </span><span className='PropertyName-details'>{item?.dtcpApprovedStatus.data == 1 ? 'Yes' : 'No'}</span>
                            </div>
                            <div className='mb-1'>
                                {
                                    item?.layoutTypeName === 'Site Wise Plot' &&
                                    <>
                                        <span className='PropertyName-heading'>No of Plots:  </span><span className='PropertyName-details'>{item?.noOfPlots}</span>
                                        <div>
                                            <span className='PropertyName-heading'>Available Plots:  </span><span className='PropertyName-details'>{item?.totalSold} / {item?.noOfPlots}</span>
                                        </div>

                                    </>
                                }
                                {
                                    item?.layoutTypeName === 'Land' &&
                                    <>
                                        <div>
                                            <span className='PropertyName-heading'>Total Land:  </span><span className='PropertyName-details'>{item?.totalLandArea} {item?.landAreaTypeName}</span>
                                        </div>
                                    </>
                                }
                                {
                                    item?.layoutTypeName === 'House' &&
                                    <>
                                        <div>
                                            <span className='PropertyName-heading'>Total Land:  </span><span className='PropertyName-details'>{item?.totalLandArea} {item?.landAreaTypeName}</span>
                                        </div>
                                        <div>
                                            <span className='PropertyName-heading'>Building Area: </span><span className='PropertyName-details'>{item?.totalBuildingArea} {item?.landAreaTypeName}</span>
                                        </div>
                                        {
                                            item?.landTypeName == 'Individual House' &&
                                            <div>
                                                <span className='PropertyName-heading'>No of Floor: </span><span className='PropertyName-details'>{item?.numberOfFloor}</span>
                                            </div>
                                        }
                                        <div>
                                            <span className='PropertyName-heading'>Room Details:  </span><span className='PropertyName-details'>{item?.numberOfBhk} BHK</span>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='mb-1'>
                                <span className='PropertyName-heading'>Rate:  </span><span className='PropertyName-details'>{RateUnit(item)}</span>
                            </div>
                            <div className='d-flex justify-content-around mt-3'>
                                <MdFeaturedPlayList
                                    size={34}
                                    color={mediaLinks?.websiteLink ? '#0010ef' : 'grey'}
                                    disabled={!mediaLinks?.websiteLink}
                                    onClick={() => mediaLinks?.websiteLink && openSocialMediaLink(mediaLinks?.websiteLink)}
                                    className='mediaIcons'
                                />
                                <FaSquareYoutube
                                    size={32}
                                    color={mediaLinks?.youtubeLink ? '#FF0000' : 'grey'}
                                    disabled={!mediaLinks?.youtubeLink}
                                    onClick={() => mediaLinks?.youtubeLink && openSocialMediaLink(mediaLinks?.youtubeLink)}
                                    className='mediaIcons'
                                />
                                <FaInstagramSquare
                                    size={32}
                                    color={mediaLinks?.instaLink ? '#E1306C' : 'grey'}
                                    disabled={!mediaLinks?.instaLink}
                                    onClick={() => mediaLinks?.instaLink && openSocialMediaLink(mediaLinks?.instaLink)}
                                    className='mediaIcons'
                                />
                                <FaFacebookSquare
                                    size={32}
                                    color={mediaLinks?.facebookLink ? '#3786FB' : 'grey'}
                                    disabled={!mediaLinks?.facebookLink}
                                    onClick={() => mediaLinks?.facebookLink && openSocialMediaLink(mediaLinks?.facebookLink)}
                                    className='mediaIcons'
                                />
                                <FaBlogger
                                    size={32}
                                    color={mediaLinks?.others ? '#3786FB' : 'grey'}
                                    disabled={!mediaLinks?.others}
                                    onClick={() => mediaLinks?.others && openSocialMediaLink(mediaLinks?.others)}
                                    className='mediaIcons'
                                />
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}

export default PropertyDetailPage