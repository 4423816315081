import React from 'react'

function FmbPattaDetailPage({ item }) {
    return (
        <>
            <div className='mb-1'>
                <span className='PropertyName-heading'>User Name:  </span><span className='PropertyName-details'>{item?.userName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Mobile No:  </span><span className='PropertyName-details'>{item?.mobileNo}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Address:  </span><span className='PropertyName-details'>{item?.address}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Location:  </span><span className='PropertyName-details'>{item?.location}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Village Name:  </span><span className='PropertyName-details'>{item?.villageName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Taluk Name:  </span><span className='PropertyName-details'>{item?.talukName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>District Name:  </span><span className='PropertyName-details'>{item?.districtName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Current Status:  </span><span className='PropertyName-details'>{item?.surveyStatus}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Posted Date: </span><span className='PropertyName-details'>{item.insertedDateTime}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Visible / Active Status:  </span><span className='PropertyName-details'>{item?.visibleStatus == 1 ? 'Visible' : 'Hidden'}</span>
            </div>
        </>
    )
}

export default FmbPattaDetailPage