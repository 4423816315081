
//export const Url = "https://www.sreeragu.com/real-develop/api/";
export const Url = "https://www.sreeragu.com/realestate/api/";


export const ImageUrl = "https://www.sreeragu.com/";
export const GOOGLE_API_KEY = "AIzaSyCSypOznNZ_lY7djECM7QQkJhvxyAQkA04";

export const DtSentenceCase = (item) => {

     if (item && item != "undefined") {
          return item[0].toUpperCase() + item.slice(1).toLowerCase();
     } else {
          return ""
     }
}

export const layOutUrl = "https://firebasestorage.googleapis.com/v0/b/myproperty-src.appspot.com/o/Images%2Fplot1.png?alt=media&token=04c1ac1d-fcf5-4026-8410-6eb64339063d";
export const agriUrl = "https://firebasestorage.googleapis.com/v0/b/myproperty-src.appspot.com/o/Images%2FAgri%20Land%202.webp?alt=media&token=7139664a-adf7-46b8-9170-fd7355db1b17";
export const emptyUrl = "https://firebasestorage.googleapis.com/v0/b/myproperty-src.appspot.com/o/Images%2FEmpty%20Land.jpg?alt=media&token=97c64053-0192-4ee9-8828-595b7411d053";
export const houseUrl = "https://firebasestorage.googleapis.com/v0/b/myproperty-src.appspot.com/o/Images%2FIndiviual%20House.jpg?alt=media&token=353b0401-4223-4903-93af-cbf10c42744f";
export const AprtmentUrl = "https://firebasestorage.googleapis.com/v0/b/myproperty-src.appspot.com/o/Images%2FAppartment.jpg?alt=media&token=7524573a-d998-46e8-a3b6-c11897c943e1";

export const GetLandUrl = (landType, type) => {
     if (landType == 1) {
          return layOutUrl;
     } else if (landType == 2 && type == 1) {
          return houseUrl;
     } else if (landType == 2 && type == 2) {
          return AprtmentUrl;
     } else if (landType == 3 && type == 1) {
          return agriUrl;
     } else if (landType == 3 && type == 2) {
          return emptyUrl;
     } else {
          return layOutUrl;
     }
}

//Filter Data
export const commonFilter = (filterData, row) => {

     let dataFlter = 0;

     if (filterData.length > 0) {
          filterData.map((data, index) => {
               if (data.siteNumber == row) {
                    //console.log(`data status  - ${data?.saleStatus}`)
                    dataFlter = data?.saleStatus
               }
          });

          return dataFlter;

     } else {
          return "null"
     }


}
//Local Storage  - Token
export function saveTokenInLocalStorage(tokenDetails) {
     localStorage.setItem('token', tokenDetails);
}
//Local Storage - User
export const userLoginData = (memberData) => {
     localStorage.setItem("user", JSON.stringify(memberData))
}

export const getTokenLocalUserStorage = (key, tableName = "user") => {
     let data = JSON.parse(localStorage.getItem(tableName));
     if (data == null) {
          return "null";
     }
     if (key === "") {
          return data;
     }
     return data[0][key];
};
// Local Storage Get
export const getTokenLocalStorage = () => {
     return localStorage.getItem('token');
}
//export Local Storage Remove
export const localStorageRemove = () => {
     localStorage.removeItem("token")
     localStorage.removeItem("user")
     return "Success"
}
export const formatDate = (value) => {
     let date = new Date(value);
     const day = date.toLocaleString('default', { day: '2-digit' });
     const month = date.toLocaleString('default', { month: 'short' });
     const year = date.toLocaleString('default', { year: 'numeric' });
     return day + '-' + month + '-' + year;
}
export const setStatus = (memberData) => {

     let data = localStorage.getItem('SelectStatus');
     if (data !== null) {
          if (data?.length > 0) {
               localStorage.removeItem("SelectStatus")
               let arrays = [];
               arrays.push(memberData)
               arrays.push(...JSON.parse(data))
               localStorage.setItem("SelectStatus", JSON.stringify(arrays))
          }

     } else {
          let arr = [memberData]
          localStorage.setItem("SelectStatus", JSON.stringify(arr))
     }
}

export const getStatusLocalData = () => {
     let data = localStorage.getItem('SelectStatus');
     return data;

}

export const permissionCheck = async (data, permissionData) => {
     let pushData = [];
     if (data) {
          // pushData.push(data);
          await data.split(",").map((a) => {
               pushData.push(a)
          })
          let getData = pushData.filter(a => a == permissionData);
          return getData;

     } else {
          return false
     }
}
