import axios from 'axios';
import { VILLAGE_FINDER_SUCCESS, VILLAGE_FINDER_FAIL, VILLAGE_FINDER_VIEW } from "../type/types";
import { Url, getTokenLocalStorage } from '../../utils/Common';
import swal from 'sweetalert';

export const getVillageFinder = (latitude, longitude) => async dispatch => {
    // console.log(`latitude = ${latitude}`)
    // console.log(`longitude = ${longitude}`)

    const token = getTokenLocalStorage();
    let formData = {
        "action": "Village",
        "latitude": latitude,
        "longitude": longitude,
    }
    const headers = { "x-auth-header": `${token}` }
    //console.log(`getVillageFinder formData = ${JSON.stringify(formData)}`)
    await axios.post(`${Url}whatsApp`, formData, { headers }).then((response) => {
        // console.log(`getVillageFinder Data = ${JSON.stringify(response?.data?.data)}`)
        if (response?.data?.message == 'Success') {
            dispatch({
                payload: response,
                type: VILLAGE_FINDER_VIEW
            })
        }
    }).catch((err) => {
        dispatch({
            payload: err.response,
            type: VILLAGE_FINDER_FAIL
        })
    })
}
