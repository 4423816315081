import React from 'react'

function NeedPropertyDetailPage({ item, currentStatus }) {

    // console.log(`currentStatus = ${currentStatus}`)

    return (
        <div className='DetailPage-container'>
            <div className='mb-1'>
                <span className='PropertyName-heading'>User Name:  </span><span className='PropertyName-details'>{item.userName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Mobile No:  </span><span className='PropertyName-details'>{item.mobileNo}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Address: </span><span className='PropertyName-details'>{item.address}, {item?.location}, {item?.villageName}, {item?.talukName}, {item?.districtName}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Looking for: </span><span className='PropertyName-details'>{item.propertyTypeName} with in {item?.propertyRadiusTypeName} Radius</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Amount Looking for: </span><span className='PropertyName-details'>{item?.minimumAmount} to {item?.maximumAmount} Amount</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Posted Date: </span><span className='PropertyName-details'>{item.insertedDateTime}</span>
            </div>
            <div className='mb-1'>
                <span className='PropertyName-heading'>Office Verification Status: </span><span className='PropertyName-details'>{currentStatus == 1 ? 'Pending' : currentStatus == 2 ? 'In Progress' : 'Completed'}</span>
            </div>

        </div>
    )
}

export default NeedPropertyDetailPage