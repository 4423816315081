import React from 'react';
import AddAgent from './AddAgent';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';

function ExpertiseEditAgent(props) {

    const data = props?.location?.state?.item

    return (
        <>
            <div className="page-wraper bg-white">
                <Header />
                <div className='mx-5'>
                    <AddAgent data={data} />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ExpertiseEditAgent