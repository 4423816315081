import React from 'react'
import SurveyorListTable from './SurveyorListTable';

function SurveyorHome() {
    return (
        <>
            <p className='listings-heading'>Surveyor</p>
            <SurveyorListTable />
        </>
    )
}

export default SurveyorHome
