import React, { useState } from 'react';
import { getTokenLocalUserStorage } from '../../../../utils/Common';

export default function Settings() {

    const loginUserData = getTokenLocalUserStorage("");
    const [editProfile, setEditPrfile] = useState(false)

    function editProfileHandler() {
        setEditPrfile(true);
    };

    function Profileinfoheading() {
        return (
            <span className='profileinfo-heading'>Personal Information</span>
        );
    }

    function Editinfoheading() {
        return (
            <span className='profileinfo-heading'>Edit Information</span>
        );
    }

    return (
        <>
            <div className='m-4'>
                {editProfile === true ? <Editinfoheading /> : <Profileinfoheading />} <br />
                <span>Update your account information</span>
            </div>

            <form className='profile-form-container'>
                <div className="form-row">
                    <div className="form-group form-group-addlistings col-md-6 pr-lg-5">
                        <label for="profile1name">Name</label>
                        <input type="text" className="form-control border rounded-3" id="streetname" value={loginUserData[0].userName} />
                    </div>
                    <div className="form-group col-md-6 pr-lg-5">
                        <label for="dob">Date of Birth</label>
                        <input type="date" className="form-control border rounded-3" id="dob" value="27-09-1998" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="dob">Mobile Number</label>
                        <input type="text" className="form-control border rounded-3 " id="mobile" value={loginUserData[0].mobileNo} />
                    </div>
                    <div className="form-group has-search-email col-md-6 pr-lg-5">
                        <label for="email">Email</label>
                        <i className="fa fa-envelope-o form-control-email" aria-hidden="true"></i>
                        <input type="text" className="form-control" value="" />
                    </div>
                </div>

            </form>
            {/* <div className='d-flex justify-content-end mr-lg-5 pr-lg-5'>
                <button className="btn addlistings-cus-btn font-weight-bold px-3" onClick={() => editProfileHandler()}>
                    {editProfile === true ? 'Save' : 'Edit'}</button>
            </div> */}

        </>
    )
}
