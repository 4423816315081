import React from 'react';
import LandOwnersListTable from './LandOwnersListTable';

const LandOwners = () => {

    return (
        <>
            <p className='listings-heading'>List of LandOwners</p>
            <LandOwnersListTable />

        </>
    )
}

export default LandOwners;

